<template>
  <div class="form-component">
    <div v-if="isLoading">
      <div class="loading">
              {{$t('Loading')}}...
      </div>
    </div>
    <div v-else ref="form">
      <component
        v-for="formPart in formParts"
        :is="formPart.Type"
        :key="formPart.Name"
        :name="formPart.Name"
        :ref="formPart.Name"
        :title="formPart.Title"
        :heading-text="formPart.TitleKey"
        :numRows="formPart.Rows"
        :numCols="formPart.Columns"
        :visible="formPart.IsVisible"
        :isEditing="isEditing"
        :rowDataName="formPart.RowDataName"
        :allowRowOpen="formPart.AllowRowOpen"
        :allowRowDelete="formPart.AllowRowDelete"
        v-model="input[formPart.Name]"
        @change="onFormPartChange"
      ></component>
      <div v-if="isEditing">
      <div :class="{ 'align-right': submitButtonInRightSide }" class="button-container">
        <div v-if="showCancelBtn" class="cancel-btn">
          <Button id="cancelBtn"
            :label="$t('Cancel')"
            color="primary" type="outlined"
            :disabled="isSaving"
            @click="$emit('onCancel')"
          />
        </div>
        <div class="submit-btn submit" :class="{ 'full-width': !showCancelBtn && submitButtonInRightSide }">
          <Button
            v-if="showClearBtn"
            id="clearBtn"
            :label="$t('Clear')"
            :disabled="isSaving"
            @click="formLoad"
            color="primary" type="outlined"
          />
          <Button :id="buttonId"
            :label="$t(submitLabel)"
            :disabled="isSaving || hasFormError"
            @click="onSave"
          />
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/app/shared/event-bus.js'
import GridFormPart from './GridFormPart.vue'
import TableFormPart from './TableFormPart.vue'
import CheckboxGridFormPart from './CheckboxGridFormPart.vue'
import VerticalFormPart from './VerticalFormPart.vue'
import TextAreaFormPart from './TextAreaFormPart.vue'
import { mapState, mapActions } from 'vuex'
import { isNotNullNorEmpty, isNullOrUndefined } from '../utils/Objects'
import { defaultToast, ToastType } from '../../../utils/toastUtils'
import { getFormattedDate, getFormattedDateForRegistry, getFormattedDateForReports } from '../../../utils/utils'
import Button from './Button'
import { trackEvent } from '../../../utils/matomoTracking'
import { formsWithDifferentDateFormats } from '../../../constants/constants'
export default {
  async mounted () {
    EventBus.$on('CENTRAL_REMOTE_DATA_UPDATE', this.onRemoteDataUpdate)
    EventBus.$on('TABLE_ROW_ADDED', this.onFormPartChange)
    EventBus.$on('LANGUAGE_CHANGE', this.reloadPage)
    this.setIsEditing(this.isEditing)
    await this.formLoad()
  },
  data: self => ({
    input: { ...self.existingVModel }
  }),
  props: {
    name: {
      type: String,
      default: null
    },
    isEditing: {
      type: Boolean,
      default: true
    },
    loadFormData: {
      type: Boolean,
      default: true
    },
    getFormUrlParams: {
      type: String,
      default: ''
    },
    saveFormUrlParams: {
      type: String,
      default: ''
    },
    sendPayloadInsteadOfSubmit: {
      type: Boolean,
      default: false
    },
    submitButtonInRightSide: {
      type: Boolean,
      default: false
    },
    showCancelBtn: {
      type: Boolean,
      default: false
    },
    showClearBtn: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Button,
    GridFormPart,
    TableFormPart,
    VerticalFormPart,
    CheckboxGridFormPart,
    TextAreaFormPart
  },
  methods: {
    reloadPage () {
      if (this.title === '_add_patient') {
        this.$router.go()
      }
    },
    async formLoad () {
      await this.loadForm(
        {
          name: this.name,
          loadFormData: this.loadFormData,
          self: this,
          getFormUrlParams: this.getFormUrlParams
        }
      )
      this.onFormPartChange()
    },
    getSubmitBtnAlignment () {
      if (this.submitButtonInRightSide) {
        return 'submit-btn-right-align'
      }
    },
    async onRemoteDataUpdate (config, index) {
      try {
        if (isNullOrUndefined(this.fieldsMappedByFormPartName)) {
          return
        }
        const url = config.Url
        const UrlVariables = config.UrlVariables
        var indexList = []
        if (config.ConditionalCall) {
          let moveForward = true
          for (const vd of config.ValueDependency) {
            if (vd.CheckNotNull) {
              if (this.formPartsMappedByName != null && this.formPartsMappedByName.get(vd.FormPart) !== undefined && ['table-form-part'].includes(this.formPartsMappedByName.get(vd.FormPart).Type)) {
                if (index !== null && index !== undefined) {
                  indexList.push(index)
                }
                moveForward = moveForward &&
                this.input[vd.FormPart][vd.FormPart][index] &&
                this.input[vd.FormPart][vd.FormPart][index][vd.Field] !== null &&
                this.input[vd.FormPart][vd.FormPart][index][vd.Field] !== undefined &&
                this.input[vd.FormPart][vd.FormPart][index][vd.Field] !== ''
              } else {
                moveForward = moveForward &&
                this.input[vd.FormPart] &&
                this.input[vd.FormPart][vd.Field] !== null &&
                this.input[vd.FormPart][vd.Field] !== undefined &&
                this.input[vd.FormPart][vd.Field] !== ''
              }
            } else {
              moveForward = moveForward && this.input[vd.FormPart] && vd.ExpectedValue === this.input[vd.FormPart][vd.Field]
            }
          }
          if (!moveForward) {
            this.updateField({
              key: config.FormPart + config.Field,
              attribute: 'OptionsWithKeyValue',
              data: null
            })
            return false
          }
        }
        if (UrlVariables !== null && UrlVariables !== undefined && Object.keys(UrlVariables).length > 0) {
          var paramsList = []
          var params = {}
          if (!indexList.length) {
            const formattedIndex = isNullOrUndefined(index) ? 0 : index
            indexList.push(formattedIndex)
          }
          for (var indexL in indexList) {
            for (var uv in UrlVariables) {
              let value = this.input
              for (var ix of UrlVariables[uv]) {
                // if (isNullOrUndefined(value)) {
                //   break
                // }
                if (this.formPartsMappedByName.get(ix) !== undefined && ['table-form-part'].includes(this.formPartsMappedByName.get(ix).Type) && !isNullOrUndefined(value[ix])) {
                  value = value[ix][ix][indexList[indexL]]
                } else {
                  if (!isNullOrUndefined(value[ix])) {
                    value = value[ix]
                  } else {
                    value = undefined
                    break
                  }
                }
              }
              if (value !== undefined && value.Key !== undefined) {
                params[uv] = value.Key
                paramsList.push(params)
              } else if (value !== undefined && Array.isArray(value)) {
                const idList = []
                for (var iy in value) {
                  var item = value[iy]
                  if (item.Key !== undefined) {
                    idList.push(item.Key)
                  }
                  if (item.key !== undefined) {
                    idList.push(item.key)
                  }
                }
                params[uv] = idList
                paramsList.push(params)
              } else {
                params[uv] = value
                paramsList.push(params)
              }
            }
            if (paramsList.length > 0) {
              let resp = await this.getRemoteData({ url: url, params: params })
              for (const p of config.ResponsePath) {
                if (resp[p]) { resp = resp[p] } else if (resp[p.toLowerCase()]) { resp = resp[p.toLowerCase()] }
              }
              if (this.formPartsMappedByName.get(config.FormPart) !== undefined && ['table-form-part'].includes(this.formPartsMappedByName.get(config.FormPart).Type)) {
                this.updateField({
                  key: config.FormPart + config.Field + indexList[indexL],
                  attribute: 'OptionsWithKeyValue',
                  data: resp
                })
              } else {
                this.updateField({
                  key: config.FormPart + config.Field,
                  attribute: 'OptionsWithKeyValue',
                  data: resp
                })
              }
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
    handleFieldComponentValueOnSave (field, value) {
      if (!value || !field) return value // Early return for missing values

      switch (field.Component) {
        case 'app-select':
          return value.Key || value.Value || value // Prioritize Key, then Value
        case 'app-search-select':
          return value.Data || value // Return Data, then fallback
        case 'app-datepicker': {
          const formatter = this.title && formsWithDifferentDateFormats.includes(this.title)
            ? getFormattedDateForRegistry
            : this.name === 'ReportsFilters'
              ? getFormattedDateForReports
              : getFormattedDate
          return formatter(value) // Use appropriate date formatter
        }
        case 'app-checkbox-group':
          return value.length === 1 ? value[0] : value // Single selection or array
        case 'app-hierarchy-selection-field':
          return value.id // Return the id
        case 'app-multiselect': {
          const valueList = []
          value.forEach(function (individualValue) {
            valueList.push({ Key: individualValue.Key, Value: individualValue.Value })
          })
          return valueList
        }
        default:
          return value // Default behavior for unknown components
      }
    },
    async onSave () {
      trackEvent(this.name, 'FORM_SAVE_CLICK', this.saveText)
      if (!this.formError) {
        const data = {}
        for (const partName in this.input) {
          var formPartName = this.formPartsMappedByName.get(partName)
          if (formPartName.Type === 'table-form-part' || formPartName.Type === 'checkbox-grid-form-part') {
            var tableFormPartValues = this.input[partName]
            var subDataList = []
            for (const [index, val] of tableFormPartValues[partName].entries()) {
              const subData = {}
              for (const [fieldName, fieldValue] of Object.entries(val)) {
                const field = this.fieldsMappedByName.get(partName + fieldName + index)
                subData[fieldName] = this.handleFieldComponentValueOnSave(field, fieldValue)
              }
              subDataList.push(subData)
            }
            data[partName] = subDataList
          } else {
            for (const [fieldName, value] of Object.entries(this.input[partName])) {
              const field = this.fieldsMappedByName.get(partName + fieldName)
              const finalValue = this.handleFieldComponentValueOnSave(field, value)
              if (finalValue) {
                data[fieldName] = finalValue
              }
            }
          }
        }
        this.startSaving()
        this.formPartsMappedByName
          .keySeq()
          .toArray()
          .forEach(fp => {
            var formPart = this.formPartsMappedByName.get(fp)
            if (formPart.Type === 'checkbox-grid-form-part') {
              if (data[formPart.RowDataName]) {
                var newRows = []
                data[formPart.RowDataName].forEach(row => {
                  let add = true
                  this.allFields
                    .filter(f => f.PartName === fp)
                    .forEach(field => {
                      if (field.Component === 'app-checkbox') {
                        add = typeof (row[field.Name]) === 'string' ? row[field.Name] === 'true' : row[field.Name]
                      }
                    })
                  if (add) { newRows.push(row) }
                })
                data[formPart.RowDataName] = newRows
              }
            }
          })
        if (this.sendPayloadInsteadOfSubmit) {
          this.$emit('submitData', data)
        } else {
          const resp = await this.save({
            payload: data,
            saveFormUrlParams: this.saveFormUrlParams
          })
          if (resp && (resp.Success || resp.success)) {
            trackEvent(this.name, 'FORM_SAVE_SUCCESS', this.saveText)
            if (resp.data && typeof resp.data === 'string') {
              defaultToast(ToastType.Success, resp.data)
            } else {
              defaultToast(ToastType.Success, 'Saved successfully!')
            }
            EventBus.$emit('FORM_SAVE_SUCCESS', {
              name: this.name,
              response: resp
            })
          } else if (resp && resp.Error && resp.Error.Message) {
            defaultToast(ToastType.Error, 'error ' + resp.Error.Message)
          } else if (resp && !resp.Success && resp.ErrorMessage) {
            defaultToast(ToastType.Error, 'error ' + resp.ErrorMessage)
          }
        }
        this.endSaving()
      }
    },
    onFormPartChange () {
      this.applyVisibilityDependencies()
      this.applyValueDependencies()
      this.applyRequiredDependencies()
      this.applyDateConstraintDependencies()
      this.applyFilterDependencies()
      this.applyValuePropertyDependencies()
      this.applyCompoundValueDependencies()
    },
    valueComparisonOperation (v1, v2, op) {
      const res = v1 === v2
      if (op === 'NOT_EQ') {
        return !res
      }
      return res
    },
    applyValuePropertyDependencies () {
      /**
       * Value property dependencies are expected to modify the props of the linked [FormPart].[Field]
       * If expectedValue List of string conditionally evaluates as per [Dependency.FormPart][Dependency.Field]
       * If expectedValue has '%current%', conditionally evaluates as per the input or existingData original value of [Dependency.FormPart][Dependency.Field]
       * If expectedValue has '%field%', conditionally evaluates as per the input or existingData original value of [FormPart].[Field]
       * */
      const self = this
      self.valuePropertyDependencies.forEach(config => {
        config.PropertyAndValues.forEach(l => {
          let res =
            self.input[l.Dependency.FormPart][l.Dependency.Field]
          const originalField = self.fieldsMappedByName.get(l.Dependency.FormPart + l.Dependency.Field)
          if (!originalField) {
            return
          }
          if (originalField.Component === 'app-select' || originalField.Component === 'app-search-select') {
            res = res.Key ? res.Key : res
          }
          if (originalField.Component === 'app-checkbox-group' || originalField.Component === 'app-multiselect') {
            res = Array.isArray(res) ? res[0] : res
          }

          let expectedValue = l.Dependency.ExpectedValues[0]
          if (l.Dependency.ExpectedValues.includes('%current%')) {
            if (self.existingData !== null && self.existingData !== undefined) {
              expectedValue = self.existingData[l.Dependency.Field]
            } else {
              expectedValue =
                self.input[l.Dependency.FormPart][
                  l.Dependency.Field
                ]
            }
          } else if (l.Dependency.ExpectedValues.includes('%field%')) {
            expectedValue = self.input[config.FormPart][config.Field]
          }
          if (
            this.valueComparisonOperation(
              res,
              expectedValue,
              l.Dependency.ComparisonOperator
            )
          ) {
            l.PropertyValueList.forEach(pv => {
              let value = pv.Value
              if (value === '%current%') {
                if (
                  self.exitingData !== null &&
                  self.existingData !== undefined
                ) {
                  value = self.existingData[config.Field]
                } else {
                  value = self.input[config.FormPart][config.Field]
                }
              }
              if (pv.Property === 'Value') {
                self.input[config.FormPart][config.Field] = value
              }
              self.updateField({
                key: config.FormPart + config.Field,
                attribute: pv.Property,
                data: value
              })
            })
          }
        })
      })
    },
    applyVisibilityDependencies () {
      const self = this
      this.visibilityDependencies.forEach(config => {
        let turnVisible = true
        if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(config.FormPart).Type)) {
          for (let index = 0; index < self.input[config.FormPart][config.FormPart].length; index++) {
            turnVisible = true
            config.Lookups.forEach(l => {
              var res
              if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(l.FormPart).Type)) {
                res = self.input[l.FormPart][l.FormPart][index][l.Field]
              } else {
                res = self.input[l.FormPart][l.Field]
              }
              let value = res
              if (!l.IsObject) {
                let formattedValue
                if (!isNullOrUndefined(value)) {
                  formattedValue = value.Key ? value.Key : value
                }
                if (!isNotNullNorEmpty(formattedValue) && isNullOrUndefined(config.Field)) {
                  value = false
                } else if (isNotNullNorEmpty(l.ExpectedValues)) {
                  value = l.ExpectedValues.includes(formattedValue)
                }
              }
              turnVisible = turnVisible && value
            })

            if (config.IsForm || isNullOrUndefined(config.Field)) {
              self.updateFormPart({
                name: config.FormPart,
                attribute: 'IsVisible',
                data: turnVisible
              })
            } else {
              self.updateField({
                key: config.FormPart + config.Field + index,
                attribute: 'IsVisible',
                data: turnVisible
              })
              if (!turnVisible) {
                self.updateField({
                  key: config.FormPart + config.Field + index,
                  attribute: 'hasError',
                  data: turnVisible
                })
              }
            }
          }
        } else {
          config.Lookups.forEach(l => {
            const lField = self.fieldsMappedByName.get(l.FormPart + l.Field)
            let res = self.input[l.FormPart][l.Field]
            let value = res
            if (!lField) {
              value = false
            } else if ((lField.Component === 'app-select' || lField.Component === 'app-search-select') && res) {
              res = res.Key ? res.Key : res
              value = res
            }
            if (!isNotNullNorEmpty(res) && isNullOrUndefined(config.Field)) {
              value = false
            } else if (isNotNullNorEmpty(l.ExpectedValues)) {
              if (lField && lField.Component === 'app-checkbox-group') {
                value = (Array.isArray(value) && value.filter(i => l.ExpectedValues.indexOf(i) !== -1).length > 0)
              } else {
                value = l.ExpectedValues.includes(value)
              }
            }
            turnVisible = turnVisible && value

            if (config.IsForm || isNullOrUndefined(config.Field)) {
              self.updateFormPart({
                name: config.FormPart,
                attribute: 'IsVisible',
                data: turnVisible
              })
            } else {
              self.updateField({
                key: config.FormPart + config.Field,
                attribute: 'IsVisible',
                data: turnVisible
              })
              if (!turnVisible) {
                self.updateField({
                  key: config.FormPart + config.Field,
                  attribute: 'hasError',
                  data: turnVisible
                })
              }
            }
          })
        }
      })
    },
    updateValueInValueDependency (objectKeyAndValue, value) {
      if (!isNullOrUndefined(value) && !isNullOrUndefined(value.Key) && !isNullOrUndefined(objectKeyAndValue[value.Key])) {
        return objectKeyAndValue[value.Key]
      } else {
        return ''
      }
    },
    updateAttributeValueInValueDependency (fieldValue, fieldName, attributeName) {
      let value
      const self = this
      if (isNotNullNorEmpty(fieldValue)) {
        var lField = self.fieldsMappedByName.get(fieldName)
        var lCurrentValue = fieldValue
        if (lField.OptionsWithKeyValue != null) {
          var lOption = lField.OptionsWithKeyValue.filter(o => (o.Key + '') === (lCurrentValue.Key + ''))[0]
          if (lOption != null && lOption !== undefined) {
            value = lOption[attributeName]
          }
        } else if (lField.AllOptionsWithLabel != null) {
          const lOption = lField.AllOptionsWithLabel.filter(o => (o.Key + '') === (lCurrentValue.Key + ''))[0]
          if (lOption !== null && lOption !== undefined) {
            value = lOption[attributeName]
          }
        }
      }
      return value
    },
    applyValueDependencies () {
      const self = this
      this.valueDependencies.forEach(config => {
        let value = self.input[config.FormPart][config.Field]
        if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(config.FormPart).Type)) {
          self.input[config.FormPart][config.FormPart].forEach((row, index) => {
            config.Lookups.forEach(l => {
              if (l.IsObject) {
                if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(l.FormPart).Type)) {
                  value = this.updateValueInValueDependency(l.ObjectKeyAndValue, self.input[l.FormPart][l.FormPart][index][l.Field])
                } else {
                  value = this.updateValueInValueDependency(l.ObjectKeyAndValue, self.input[config.FormPart][config.FormPart][index][config.Field],
                    l.ObjectKeyAndValue[self.input[l.FormPart][l.Field]])
                }
              } else if (isNotNullNorEmpty(l.AttributeName)) {
                if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(l.FormPart).Type)) {
                  value = this.updateAttributeValueInValueDependency(self.input[l.FormPart][l.FormPart][index][l.Field], l.FormPart + l.Field + index, l.AttributeName)
                } else {
                  value = this.updateAttributeValueInValueDependency(self.input[l.FormPart][l.Field], l.FormPart + l.Field, l.AttributeName)
                }
              }
            })
            self.updateField({
              key: config.FormPart + config.Field + index,
              attribute: 'Value',
              data: value
            })
            if (self.input[config.FormPart][config.Field] !== value) {
              self.updateExistingVModel({
                formPart: config.FormPart,
                field: config.Field,
                value
              })
            }
          })
        } else {
          config.Lookups.forEach(l => {
            // Applies the last dependent logic in Lookups
            if (l.IsObject) {
              if ((!isNotNullNorEmpty(self.input[config.FormPart][config.Field]) ||
                Object.values(l.ObjectKeyAndValue).includes(self.input[config.FormPart][config.Field]))) {
                const lField = self.fieldsMappedByName.get(l.FormPart + l.Field)
                let lCurrentValue = self.input[l.FormPart][l.Field]

                if ((lField.Component === 'app-select' || lField.Component === 'app-search-select') && lCurrentValue) {
                  lCurrentValue = lCurrentValue.Key
                }

                if (!isNullOrUndefined(l.ObjectKeyAndValue[lCurrentValue])) {
                  value = l.ObjectKeyAndValue[lCurrentValue]
                }
              }
            } else if (isNotNullNorEmpty(l.AttributeName)) {
              value = this.updateAttributeValueInValueDependency(self.input[l.FormPart][l.Field], l.FormPart + l.Field, l.AttributeName)
            }
          })
          self.updateField({
            key: config.FormPart + config.Field,
            attribute: 'Value',
            data: value
          })
          if (self.input[config.FormPart][config.Field] !== value) {
            self.updateExistingVModel({
              formPart: config.FormPart,
              field: config.Field,
              value
            })
          }
        }
      })
    },
    applyDateConstraintDependencies () {
      const self = this

      this.dateConstraintDependency.forEach(config => {
        const lookup = config.Lookups[0]
        let value
        if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(config.FormPart).Type)) {

        } else {
          value = self.input[lookup.FormPart][lookup.Field]
          const exisitngField = this.fieldsMappedByName.get(config.FormPart + config.Field)
          const exisitingDateConfig = { ...exisitngField.DisabledDateConfig }
          if (!isNullOrUndefined(value)) {
            exisitingDateConfig[lookup.ConstraintName] = value
            self.updateField({
              key: config.FormPart + config.Field,
              attribute: 'DisabledDateConfig',
              data: exisitingDateConfig
            })
          }
        }
      })
    },
    applyFilterDependencies () {
      const self = this
      this.filterDependencies.forEach(config => {
        if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(config.FormPart).Type)) {
          self.input[config.FormPart][config.FormPart].forEach((row, index) => {
            var field = this.fieldsMappedByName.get(config.FormPart + config.Field + index)
            var options = field.AllOptionsWithLabel
            var optionsWithKeyValue = null
            config.Lookups.forEach(l => {
              var filterValue
              if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(l.FormPart).Type)) {
                filterValue = self.input[l.FormPart][l.FormPart][index][l.Field]
                if (isNotNullNorEmpty(filterValue) && filterValue.Value) {
                  filterValue = filterValue.Value
                }
                if (isNotNullNorEmpty(filterValue) && isNotNullNorEmpty(options)) {
                  if (isNotNullNorEmpty(l.AttributeName) && isNotNullNorEmpty(filterValue[l.AttributeName])) {
                    options = options.filter(o => Array.isArray(o[l.AttributeName]) ? o[l.AttributeName].includes(filterValue[l.AttributeName]) : o[l.AttributeName] === filterValue[l.AttributeName])
                  } else {
                    options = options.filter(o => o[l.Field] === filterValue)
                  }
                }
              } else {
                filterValue = self.input[l.FormPart][l.Field]
                if (isNotNullNorEmpty(filterValue) && isNotNullNorEmpty(options)) {
                  if (isNotNullNorEmpty(l.AttributeName) && isNotNullNorEmpty(filterValue[l.AttributeName])) {
                    options = options.filter(o => Array.isArray(o[l.AttributeName]) ? o[l.AttributeName].includes(filterValue[l.AttributeName]) : o[l.AttributeName] === filterValue[l.AttributeName])
                  } else {
                    options = options.filter(o => o[l.Field] === filterValue)
                  }
                }
                if (isNotNullNorEmpty(filterValue) &&
                    (isNotNullNorEmpty(field.OptionsWithKeyValue) ||
                      isNotNullNorEmpty(field.AllOptionsWithLabel)) &&
                    isNotNullNorEmpty(l.ExpectedValue)) {
                  if (filterValue.Key === l.ExpectedValue) {
                    optionsWithKeyValue = field.AllOptionsWithLabel
                      ? field.AllOptionsWithLabel
                      : field.OptionsWithKeyValue
                    optionsWithKeyValue = optionsWithKeyValue
                      .filter(o => l.FilteredKeys.includes(o.Key))
                  } else {
                    optionsWithKeyValue = field.AllOptionsWithLabel
                      ? field.AllOptionsWithLabel
                      : field.OptionsWithKeyValue
                  }
                }
              }
            })
            if (optionsWithKeyValue) {
              if (field.AllOptionsWithLabel === null || field.AllOptionsWithLabel === [] || field.AllOptionsWithLabel === undefined) {
                self.updateField({
                  key: config.FormPart + config.Field + index,
                  attribute: 'AllOptionsWithLabel',
                  data: field.OptionsWithKeyValue
                })
              }
              self.updateField({
                key: config.FormPart + config.Field + index,
                attribute: 'OptionsWithKeyValue',
                data: optionsWithKeyValue
              })
            } else if (options) {
              self.updateField({
                key: config.FormPart + config.Field + index,
                attribute: 'OptionsWithKeyValue',
                data: options
              })
            }
          })
        } else {
          var field = this.fieldsMappedByName.get(config.FormPart + config.Field)
          var options = field.AllOptionsWithLabel
          config.Lookups.forEach(l => {
            var filterValue = self.input[l.FormPart][l.Field]
            if (isNotNullNorEmpty(filterValue) && isNotNullNorEmpty(options)) {
              if (isNotNullNorEmpty(l.AttributeName) && isNotNullNorEmpty(filterValue[l.AttributeName])) {
                options = options.filter(o => Array.isArray(o[l.AttributeName]) ? o[l.AttributeName].includes(filterValue[l.AttributeName]) : Array.isArray(filterValue[l.AttributeName]) ? filterValue[l.AttributeName].includes(o[l.AttributeName]) : o[l.AttributeName] === filterValue[l.AttributeName])
              } else { options = options.filter(o => o[l.Field] === filterValue) }
            }
          })
          if (field.AllOptionsWithLabel === null || field.AllOptionsWithLabel === [] || field.AllOptionsWithLabel === undefined) {
            self.updateField({
              key: config.FormPart + config.Field,
              attribute: 'AllOptionsWithLabel',
              data: field.OptionsWithKeyValue
            })
          }
          self.updateField({
            key: config.FormPart + config.Field,
            attribute: 'OptionsWithKeyValue',
            data: options
          })
        }
      })
    },
    applyRequiredDependencies () {
      const self = this
      this.isRequiredDependencies.forEach(config => {
        var lookup = config.Lookups[0]
        var value
        var addRequired
        if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(config.FormPart).Type)) {
          for (var i = 0; i < self.input[lookup.FormPart][lookup.FormPart].length; i++) {
            if (['table-form-part', 'checkbox-grid-form-part'].includes(self.formPartsMappedByName.get(lookup.FormPart).Type)) {
              value = self.input[lookup.FormPart][lookup.FormPart][i][lookup.Field]
            } else {
              value = self.input[lookup.FormPart][lookup.Field]
            }
            addRequired = true

            if (isNullOrUndefined(lookup.ExpectedValues) || lookup.ExpectedValues.length === 0) {
              if (isNullOrUndefined(value) || value === '') {
                addRequired = false
              }
            } else {
              if (!lookup.ExpectedValues.includes(value)) {
                addRequired = false
              }
            }
            self.addRemoveRequiredValidation({
              field: config.FormPart + config.Field + i,
              addRequired,
              self
            })
          }
        } else {
          value = self.input[lookup.FormPart][lookup.Field]
          addRequired = true
          if (isNullOrUndefined(lookup.ExpectedValues) || lookup.ExpectedValues.length === 0) {
            if (isNullOrUndefined(value) || value === '') {
              addRequired = false
            }
          } else {
            if (!lookup.ExpectedValues.includes(typeof value === 'object' && !Array.isArray(value) ? value.Key : value)) {
              addRequired = false
            }
          }
          self.addRemoveRequiredValidation({
            field: config.FormPart + config.Field,
            addRequired,
            self
          })
        }
      })
    },
    applyCompoundValueDependencies () {
      const self = this
      this.compoundValueDependencies.forEach(config => {
        let processConfig = false
        config.Lookups.forEach(lookup => {
          const value = self.input[lookup.FormPart][lookup.Field]
          if (lookup.ExpectedValues.includes(typeof value === 'object' ? value.Key : value)) {
            processConfig = true
          }
        })
        if (processConfig) {
          const newValArr = []
          let processSource = false
          config.CompoundValueSources.forEach(source => {
            let modelStringList = source.ModelStringList
            const sourceInputValue = self.input[source.FormPart][source.Field]
            const currentValue = sourceInputValue?.Key ?? sourceInputValue
            const mapValue = source.FieldLookupValueMap?.[currentValue] ?? sourceInputValue
            let value = mapValue
            if (!isNullOrUndefined(mapValue) && typeof mapValue === 'object') {
              value = mapValue.Value
              let canAdd = true
              if (!isNullOrUndefined(mapValue.Lookups)) {
                for (const l of mapValue.Lookups) {
                  const res = self.input[l.LookupFormPart][l.LookupField]
                  canAdd = !isNullOrUndefined(res) && l.ExpectedValues.includes(res)
                  if (!canAdd) {
                    // Remove the previously added compound value
                    modelStringList = []
                    break
                  }
                }
              }
              // Always process source when Lookups is present
              processSource = true
            }
            processSource = modelStringList[source.CompoundValueIndex] === value ? processSource : true
            if (processSource && modelStringList.length) {
              modelStringList[source.CompoundValueIndex] = value
            }
            if (sourceInputValue) {
              newValArr.push(modelStringList.join(''))
            }
          })
          if (processSource) {
            const newVal = newValArr.join('')
            const field = self.fieldsMappedByName.get(config.FormPart + config.Field)
            if (newVal !== self.input[config.FormPart][config.Field] || (!field.OptionsWithKeyValue || !field.OptionsWithKeyValue.some(f => f.Key === newVal))) {
              if (field.Component === 'app-input-field') {
                self.updateField({
                  key: config.FormPart + config.Field,
                  attribute: 'Value',
                  data: newVal
                })
              } else {
                // This will add the new compound value in options list
                self.updateField({
                  key: config.FormPart + config.Field,
                  attribute: 'OptionsWithKeyValue',
                  data: [{ Key: newVal, Value: newVal, Label: newVal, ValueKey: newVal }]
                })
              }
            }
          }
        }
      })
    },

    ...mapActions('Form', [
      'save',
      'loadForm',
      'endSaving',
      'startSaving',
      'updateField',
      'getRemoteData',
      'updateFormPart',
      'addRemoveRequiredValidation',
      'setIsEditing',
      'updateExistingVModel'
    ])
  },
  computed: {
    formParts () {
      if (isNotNullNorEmpty(this.formPartsMappedByName)) {
        return this.formPartsMappedByName
          .valueSeq()
          .sortBy(fp => fp.Order)
          .filter(fp => {
            return fp.IsVisible
          })
          .toArray()
      }
      return []
    },
    submitLabel () {
      let label = this.$t(this.saveText)

      if (this.isSaving) {
        label = this.$t('Saving') + '...'
      }

      return label
    },
    buttonId () {
      return (`${this.name}SubmitBtn`)
    },
    ...mapState('Form', [
      'isSaving',
      'isLoading',
      'isRequiredDependencies',
      'formPartsMappedByName',
      'fieldsMappedByFormPartName',
      'fieldsMappedByNameOriginal',
      'fieldsMappedByName',
      'existingVModel',
      'visibilityDependencies',
      'valueDependencies',
      'valuePropertyDependencies',
      'compoundValueDependencies',
      'hasFormError',
      'dateConstraintDependency',
      'saveText',
      'filterDependencies',
      'allFields',
      'existingData',
      'title'
    ])
  },
  watch: {
    existingVModel (value) {
      this.input = { ...value }
      this.onFormPartChange()
    }
  }
}
</script>
<style lang='scss' scoped>
.loading {
  color: var(--text-loading-color);
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.form-component {
  margin: 16px;
  box-sizing: unset;
}
.button-container {
  display: flex;
  align-items: center;
}

#cancelBtn, #buttonId, #clearBtn {
  margin-right: 24px;
}

.align-right {
  justify-content: space-between;
  width: 100%;
}

.full-width {
  margin-left: auto; /* Push the submit button to the right */
}
</style>
