import am from '../languages/strings/am.json'
import bn from '../languages/strings/bn.json'
import en from '../languages/strings/en.json'
import fr from '../languages/strings/fr.json'
import ky from '../languages/strings/ky.json'
import om from '../languages/strings/om.json'
import pt from '../languages/strings/pt.json'
import ru from '../languages/strings/ru.json'
import sw from '../languages/strings/sw.json'
import tl from '../languages/strings/tl.json'
import uk from '../languages/strings/uk.json'
import ml from '../languages/strings/ml.json'
import ta from '../languages/strings/ta.json'
import gu from '../languages/strings/gu.json'
import hi from '../languages/strings/hi.json'
import mr from '../languages/strings/mr.json'
import te from '../languages/strings/te.json'
import br from '../languages/strings/br.json'

export default {
  am,
  bn,
  en,
  fr,
  ky,
  om,
  pt,
  ru,
  sw,
  tl,
  uk,
  ml,
  ta,
  gu,
  hi,
  mr,
  te,
  br
}
export const SUPPORTED_LANGUAGES = ['am', 'bn', 'en', 'fr', 'ky', 'om', 'pt', 'ru', 'sw', 'tl', 'uk', 'ml', 'ta', 'gu', 'hi', 'mr', 'te', 'br']
