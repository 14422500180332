import { RegistryServerClient, registryErrorCallback } from '../store/RegistryApi.js'
import { defaultToast, ToastType } from '../../../utils/toastUtils'
import { parseConfigs } from '../../../utils/utils'
import { setUser, resetUser } from '../../../utils/matomoTracking'

export default {
  state: {
    test: true,
    userAuthenticated: false,
    userName: '',
    darkMode: false,
    accessibilityMode: false,
    deploymentCode: '',
    corruptedUserData: false
  },
  mutations: {
    UPDATE_TEST (state, data) {
      state.test = data
    },
    UPDATE_AUTH_STATUS (state, payload) {
      state.userAuthenticated = payload.userAuthenticated
      state.userName = payload.userName
      state.deploymentCode = payload.deploymentCode
      state.corruptedUserData = false
      setUser(state.userName)
    },
    updateDarkTheme (state, val) {
      state.darkMode = val
    },
    updateAccessibilityMode (state, val) {
      state.accessibilityMode = val
    },
    LOGOUT_USER (state) {
      state.userAuthenticated = false
      state.userName = ''
      state.deploymentCode = ''
      resetUser()
    },
    UPDATE_USER_DATA_MISMATCH (state, val) {
      state.corruptedUserData = val
    }
  },
  actions: {
    updateTest ({ commit }, data) {
      commit('UPDATE_TEST', data)
    },
    async login ({ commit }) {
      const url = '/v1/user/login'
      const response = await RegistryServerClient.post(url, { returnUrl: `${process.env.VUE_APP_SELF_URL}/home/login` })
      return response.data.redirectUrl
    },
    async logout ({ commit }) {
      const url = '/v1/user/logout'
      try {
        const response = await RegistryServerClient.post(url, { returnUrl: `${process.env.VUE_APP_SELF_URL}/home` })
        commit('LOGOUT_USER')
        return response.data.redirectUrl
      } catch (ex) {
        defaultToast(ToastType.Error, 'Something went wrong. Please try again later.')
      }
    },
    async getUserAuthStatus ({ dispatch, commit }) {
      const url = '/v1/user/auth-status'
      try {
        const response = await RegistryServerClient.get(url, null, (error) => {
          if (error.response.status === 404) {
            commit('UPDATE_USER_DATA_MISMATCH', true)
          }
        })
        if (response) {
          commit('UPDATE_AUTH_STATUS', response.data)
          commit('Header/SET_HEADER_USERNAME', response.data.userName, { root: true })
        }
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('getUserAuthStatus')
        }
        commit('UPDATE_USER_DATA_MISMATCH', true)
      }
    },
    toggleDarkMode ({ commit }, val) {
      commit('updateDarkTheme', val)
      commit('updateAccessibilityMode', false)
    },
    toggleAccessibilityMode ({ commit }, val) {
      commit('updateDarkTheme', false)
      commit('updateAccessibilityMode', val)
    },
    eraseCorruptedUserData ({ commit }) {
      commit('UPDATE_USER_DATA_MISMATCH', false)
    },
    async getLoggedInUserConfigValues ({ dispatch, commit }, params) {
      const url = 'v2/config/hierarchy/mappings'

      const configNames = params.configNames
      const throwError = params.throwError
      const payload = {
        configNames: configNames
      }

      const response = await RegistryServerClient.post(url, payload, throwError ? registryErrorCallback : () => {})
      const data = response.data
      const configs = data[0].configs
      return parseConfigs(configs)
    }
  },
  getters: {
    darkModeState (state) {
      return state.darkMode
    },
    accessibilityModeState (state) {
      return state.accessibilityMode
    },
    isUserAuthenticated (state) {
      return state.userAuthenticated
    }
  }
}
