import VueI18n from 'vue-i18n'
import messages from '@/utils/languages'
import { getCookie } from '@/utils/cookieUtils'
import Vue from 'vue'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: getCookie('languageCode'),
  fallbackLocale: 'en',
  messages: messages
})

export default i18n
