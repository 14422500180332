import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
export default {
  namespaced: true,
  actions: {
    async registerUser ({ commit }, { params }) {
      const url = '/v1/users'
      try {
        return await RegistryServerClient.post(url, params, registryErrorCallback)
      } catch (e) {
        registryErrorCallback(e)
      }
    },
    async loadDefaultHierarchy () {
      const url = 'v1/client/default-hierarchy'
      try {
        const response = await RegistryServerClient.get(url, null, registryErrorCallback)
        if (response.success) {
          return response.data
        }
      } catch (e) {
        registryErrorCallback(e)
      }
    },
    async loadHierarchyChildren ({ commit }, { params }) {
      const url = 'v1/hierarchy/lite/descendants/' + params.hierarchyId
      try {
        const response = await RegistryServerClient.get(url, null, registryErrorCallback)
        if (response.success) {
          return response.data
        }
      } catch (e) {
        registryErrorCallback(e)
      }
    }
  }
}
