import { ApiServerClient } from '../../Api'
import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { defaultToast, ToastType } from '../../../../../utils/toastUtils'
export default {
  namespaced: true,
  state: {
    patientId: null,
    patientData: null,
    stage: null,
    endDate: null,
    treatmentOutcome: null,
    availableMerms: [],
    monitoringMethod: null
  },
  mutations: {
    SET_PATIENT_ID (state, payload) {
      state.patientId = payload
    },
    SET_PATIENT_DATA (state, payload) {
      state.patientData = payload
    },
    SET_PATIENT_ATTRIBUTE (state, payload) {
      state[payload.attributeName] = payload.attributeValue
    }
  },
  actions: {
    setPatientId ({ commit }, patientId) {
      commit('SET_PATIENT_ID', patientId)
    },
    setPatientAttribute ({ commit }, payload) {
      commit('SET_PATIENT_ATTRIBUTE', payload)
    },
    async getPatient ({ commit }, patientId) {
      const url = 'v1/user/episode'
      try {
        const response = await RegistryServerClient.get(url, { episodeId: patientId })
        if (!response.success) {
          defaultToast(ToastType.Error, response.Error.Message)
        } else {
          commit('SET_PATIENT_DATA', response.data)
          return response.data
        }
      } catch (ex) {
        defaultToast(ToastType.Error, 'Something went wrong. Please try after some time.')
      }
    },
    async videoCall ({ commit }, patientId) {
      var dt = new Date()
      var timestamp = dt.getTime()
      const url = '/Api/Patients/SendInvitation/' + patientId + '?time=' + timestamp
      try {
        const response = await ApiServerClient.get(url)
        if (!response.Success) {
          defaultToast(ToastType.Error, response.Error.Message)
        } else {
          window.open(process.env.VUE_APP_BASE + '/video/consultation?i=' + patientId + '&t=' + timestamp, '_blank')
        }
      } catch (ex) {
        defaultToast(ToastType.Error, 'Something went wrong. Please try after some time.')
      }
    },
    async alterAttentionUpdate ({ commit }, { params }) {
      const url = '/api/patients/AlterAttentionRequired'
      try {
        const response = await ApiServerClient.get(url, params)
        if (!response.Success) {
          defaultToast(ToastType.Error, response.Error.Message)
        }
      } catch (ex) {
        defaultToast(ToastType.Error, 'Something went wrong. Please try after some time.')
      }
    },

    async reOpenCase ({ commit }, payload) {
      const url = '/v1/user/episode/re-open'

      this.submittingReOpenCase = true

      return RegistryServerClient.post(url, payload, registryErrorCallback)
    },

    async getAvailableMerms ({ commit }) {
      const url = '/v1/merm/imei'

      return await RegistryServerClient.get(url, registryErrorCallback)
    }
  }
}
