import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { defaultToast, ToastType } from '../../../../../utils/toastUtils'

export default {
  namespaced: true,
  state: {
    isViewOnly: false,
    addStaffObj: {},
    formloading: true,
    editMode: false,
    previousHierarchyId: 0
  },
  mutations: {
    SET_PREVIOUS_HIERARCHY_ID (state, id) {
      state.previousHierarchyId = id
    },
    SET_IS_VIEW_ONLY (state, breadcrumbs) {
      state.isViewOnly = breadcrumbs
    },
    SET_ADD_STAFF_OBJ (state, obj) {
      state.addStaffObj = obj
    },
    SET_FORM_LOADING (state, status) {
      state.formloading = status
    },
    SET_EDIT_MODE (state, status) {
      state.editMode = status
    }
  },
  actions: {
    async deleteFieldStaff ({ commit }, { params }) {
      const url = '/v1/staff/' + params.fieldStaffId
      try {
        const deleted = await RegistryServerClient.delete(url, {}, registryErrorCallback)
        if (!deleted.success) {
          defaultToast(ToastType.Error, 'Something went wrong. Please try again later.')
        }
      } catch (e) {
        registryErrorCallback(e)
      }
    },
    async fieldStaffDetails ({ commit }, { params }) {
      const url = '/v1/staff/unified'
      commit('SET_FORM_LOADING', true)
      try {
        const staffObj = await RegistryServerClient.get(url, params, registryErrorCallback)
        commit('SET_ADD_STAFF_OBJ', staffObj.data)
        if (!staffObj) {
          defaultToast(ToastType.Error, 'Something went wrong. Please try again later.')
        }
      } catch (e) {
        registryErrorCallback(e)
      }
      commit('SET_FORM_LOADING', false)
    },
    async updateFieldStaff ({ commit }, { params }) {
      const url = '/v1/staff'
      try {
        const updated = await RegistryServerClient.put(url, params, registryErrorCallback)
        if (!updated.success) {
          defaultToast(ToastType.Error, 'Something went wrong. Please try again later.')
        }
      } catch (e) {
        registryErrorCallback(e)
      }
    },
    async addFieldStaff ({ commit }, { params }) {
      const url = '/v1/staff'
      try {
        const added = await RegistryServerClient.post(url, params, registryErrorCallback)
        if (!added.success) {
          defaultToast(ToastType.Error, 'Something went wrong. Please try again later.')
        }
      } catch (e) {
        registryErrorCallback(e)
      }
    },
    setPreviousHierarchyId ({ commit }, previousHierarchyId) {
      commit('SET_PREVIOUS_HIERARCHY_ID', previousHierarchyId)
    },
    setViewOnly ({ commit }, isViewOnly) {
      commit('SET_IS_VIEW_ONLY', isViewOnly)
    }
  }
}
