<template>
  <v-card id="vertical-form-part" v-if="fields.length > 0">
  <div class="vertical-part">
         <v-card-title v-if="headingText"><h5 id="vertical-heading" class="title text-ibm-plex-sans dark-text">{{$t(headingText)}}</h5></v-card-title>
         <hr class="ew-hr mlr" v-if="headingText" />
         <v-card-text class="card-container">
        <div class="panel">
            <component
                v-for="field in fields"
                :ref="field.Name"
                :id="field.Name"
                :type="field.Type"
                :addOn="field.AddOn"
                :key="field.Key"
                :is="field.Component"
                :name="field.Name"
                :allOptions="field.OptionsWithKeyValue"
                :optionsWithKeyValue="field.OptionsWithKeyValue"
                :value="field.Value"
                :label="field.Label"
                :labelKey="field.LabelKey"
                :isDisabled="field.IsDisabled || !isEditing"
                :isRequired="field.IsRequired"
                :hasError="field.hasError"
                :errorMessage="field.errorMessage"
                :andValidations="getValidations(field.Key, 'And')"
                :orValidations="getValidations(field.Key, 'Or')"
                :remoteUrl="field.RemoteUrl"
                :optionsWithLabel="field.OptionsWithLabel"
                :optionDisplayKeys="field.OptionDisplayKeys"
                :optionValueKeys="field.OptionValueKeys"
                :responseDataPath="field.ResponseDataPath"
                :placeholderText="field.Placeholder"
                :hierarchyConfigs="hierarchyConfigs.get(field.Name)"
                :loadImmediately="field.LoadImmediately"
                :to="getDateConfigKey(field,'To')"
                :from="getDateConfigKey(field,'From')"
                :days="getDateConfigKey(field,'Days')"
                :daysOfMonth="getDateConfigKey(field,'DaysOfMonth')"
                :dates="getDateConfigKey(field,'Dates')"
                :ranges="getDateConfigKey(field,'Ranges')"
                :remoteUpdateConfig="getRemoteUpdateConfig(field.Name)"
                :isMultiSelect= "field.Component === 'app-multiselect'"
                :labelAlign="'left'"
                :fieldWidth="'col-sm-5'"
                :isEditing="isEditing"
                :formPart="field.PartName"
                :errorClass="'col-sm-3'"
                class="vertical-item"
                @change="onDataChange"
                v-model="localValue[field.Name]"
                v-bind="field"
                :hasInfo="field.HasInfo"
                :infoMessage="field.InfoText"
            ></component>
    </div>
         </v-card-text>
  </div>
  </v-card>
</template>

<script>
import { isNotNullNorEmpty, isNullOrUndefined } from '@/utils/utils.js'
import { List } from 'immutable'
import { FormPartMixin, FieldComponentMap } from '@/app/shared/mixins/FormPartMixin'

export default {
  name: 'VerticalFormPart',
  mixins: [FormPartMixin, FieldComponentMap],
  props: {
    headingText: {
      type: String
    }
  },
  computed: {
    fields () {
      let ordered = new List()
      if (this.fieldsMappedByFormPartName.has(this.name)) {
        ordered = List(this.fieldsMappedByFormPartName.get(this.name))
          .filter(f => f.IsVisible)
          .sortBy(f => f.ColumnNumber)
          .sortBy(f => f.RowNumber)
          .toArray()
        for (const f of this.fieldsMappedByFormPartName.get(this.name)) {
          if (!isNullOrUndefined(f.DefaultValue) && !isNotNullNorEmpty(this.localValue[f.Name])) {
            this.changeLocal(f.Name, f.DefaultValue)
          }
          if (!isNullOrUndefined(f.Value)) {
            this.changeLocal(f.Name, f.Value)
          }
        }
      }
      return ordered
    }
  },
  methods: {
    changeLocal (field, value) {
      this.localValue[field] = value
    }
  }
}

</script>
<style lang="scss" scoped>
.vertical-item {
  margin: 20px;
  width: 80%;
}
@media (max-width: 800px) {
  .vertical-item {
    margin: 24px 24px 24px 0px;
    width: 100%;
  }
}
.title {
  font-size: 20px;
  flex: auto;
}
.mlr {
  margin: 0px 24px 0px 20px;
}
.v-sheet.v-card {
  border-radius: 8px;
}
#vertical-form-part {
  position: inherit;
  background-color: var(--form-part-color);
  box-shadow: 1px 2px 2px var(--very-light-shadow);
  margin: 15px 0px 12px 0px;
}
</style>
