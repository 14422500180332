import { ApiServerClient, errorCallback } from '../../Api'
import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { defaultToast, ToastType } from '../../../../../utils/toastUtils'
import { List } from 'immutable'

export default {
  namespaced: true,
  state: {
    searchKeyEpisodeList: 'id',
    searchKeyDispensationList: 'id',
    searchValueEpisodeList: '',
    searchValueDispensationList: '',
    totalEpisodes: 0,
    numberPagesEpisodesList: 0,
    numberPagesDispensationList: 0,
    pageSize: 10,
    detailsLoading: true,
    existingDispensationView: false,
    episodeDataColumns: [],
    episodeDataRows: [],
    dispensationDataColumns: [],
    dispensationDataRows: [],
    dispensationColumnList: List(),
    testsRowsData: [],
    totalDispensations: 0,
    episodeDetailsCurrentPage: 1,
    dispensationDetailsCurrentPage: 1,
    episodeColumnList: List()
  },
  mutations: {
    SET_LOADING_STATE (state, status) {
      state.detailsLoading = status
    },
    SET_EPISODE_COLUMNS (state, value) {
      state.episodeDataColumns = value
    },
    SET_EPISODE_COLUMNS_DATA (state, value) {
      state.episodeDataRows = value
    },
    SET_DISPENSATION_DATA_ROWS (state, value) {
      state.dispensationDataRows = value
    },
    SET_DISPENSATION_DATA_COLUMNS (state, value) {
      state.dispensationDataColumns = value
    },
    SET_TOTAL_EPISODES (state, value) {
      state.totalEpisodes = value
    },
    SET_TOTAL_DISPENSATIONS (state, value) {
      state.totalDispensations = value
    },
    SET_CURRENT_PAGE_SEARCH_EPISODES (state, data) {
      state.episodeDetailsCurrentPage = data
    },
    SET_CURRENT_PAGE_SEARCH_DISPENSATIONS (state, data) {
      state.dispensationDetailsCurrentPage = data
    },
    SET_SEARCH_KEY_EPISODE_LIST (state, data) {
      state.searchKeyEpisodeList = data
    },
    SET_SEARCH_KEY_DISPENSATION_LIST (state, data) {
      state.searchKeyDispensationList = data
    },
    SET_SEARCH_VALUE_EPISODE_LIST (state, data) {
      state.searchValueEpisodeList = data
    },
    SET_SEARCH_VALUE_DISPENSATION_LIST (state, data) {
      state.searchValueDispensationList = data
    },
    SET_NUMBER_PAGES_EPISODE_LIST (state, data) {
      state.numberPagesEpisodesList = data
    },
    SET_NUMBER_PAGES_DISPENSATION_LIST (state, data) {
      state.numberPagesDispensationList = data
    },
    SET_EPISODE_LIST_COLUMNS (state, data) {
      state.episodeColumnList = data
    },
    SET_DISPENSATION_LIST_COLUMNS (state, data) {
      state.dispensationColumnList = data
    },
    SET_SEARCH_EXISTING_DISPENSATION_VIEW (state, data) {
      state.existingDispensationView = data
    }
  },
  actions: {
    setCurrentPageSearchEpisodes ({ commit }, newPage) {
      commit('SET_CURRENT_PAGE_SEARCH_EPISODES', newPage)
    },
    setCurrentPageSearchDispensations ({ commit }, newPage) {
      commit('SET_CURRENT_PAGE_SEARCH_DISPENSATIONS', newPage)
    },
    setSearchKeyEpisodeList ({ commit }, key) {
      commit('SET_SEARCH_KEY_EPISODE_LIST', key)
    },
    setSearchKeyDispensationDetails ({ commit }, key) {
      commit('SET_SEARCH_KEY_DISPENSATION_LIST', key)
    },
    setSearchValueEpisodeList ({ commit }, value) {
      commit('SET_SEARCH_VALUE_EPISODE_LIST', value)
    },
    setSearchValueDispensationList ({ commit }, value) {
      commit('SET_SEARCH_VALUE_DISPENSATION_LIST', value)
    },
    setSearchExistingDispensationView ({ commit }, value) {
      commit('SET_SEARCH_EXISTING_DISPENSATION_VIEW', value)
    },
    async loadEpisodeList ({ commit, state }) {
      const url = '/v1/episodes/search'
      commit('SET_LOADING_STATE', true)
      try {
        const data = {
          searchKey: state.searchKeyEpisodeList,
          searchValue: state.searchValueEpisodeList,
          pageNo: state.episodeDetailsCurrentPage - 1,
          pageSize: state.pageSize,
          moduleName: 'dispensation'
        }
        const viewParams = await RegistryServerClient.post(url, data, registryErrorCallback)
        var headers = viewParams.data.episodeDisplayTable.headers
        if (headers) {
          var columnList = headers.map(function (header) {
            return { Key: header.key, Value: header.key, Sorting: true }
          })
          commit('SET_EPISODE_LIST_COLUMNS', columnList)
        }
        commit('SET_TOTAL_EPISODES', viewParams.data.totalHits)
        commit('SET_NUMBER_PAGES_EPISODE_LIST', Math.ceil(viewParams.data.totalHits / state.pageSize))
        if (viewParams.data.episodeDisplayTable) {
          commit('SET_EPISODE_COLUMNS', headers)
          commit('SET_EPISODE_COLUMNS_DATA', viewParams.data.episodeDisplayTable.dataRows)
        }
        commit('SET_LOADING_STATE', false)
      } catch (e) {
        commit('SET_TOTAL_EPISODES', 0)
        defaultToast(ToastType.Error, 'Something went wrong. Please try again later.')
      }
    },
    async loadDispensationList ({ commit, state }) {
      commit('SET_LOADING_STATE', true)
      try {
        const url = state.searchKeyDispensationList === 'id' ? `/v1/dispensation/entity/${state.searchValueDispensationList}` : `/v1/dispensation/${state.searchValueDispensationList}`
        const viewParams = await RegistryServerClient.get(url, registryErrorCallback)
        var headers = viewParams.data.dispensationsDisplayTable.headers
        if (headers) {
          var columnList = headers.map(function (header) {
            return { Key: header.key, Value: header.key, Sorting: true }
          })
          commit('SET_DISPENSATION_LIST_COLUMNS', columnList)
        }
        commit('SET_TOTAL_DISPENSATIONS', viewParams.data.totalHits)
        commit('SET_NUMBER_PAGES_DISPENSATION_LIST', Math.ceil(viewParams.data.totalHits / state.pageSize))
        if (viewParams.data.dispensationsDisplayTable) {
          commit('SET_DISPENSATION_DATA_COLUMNS', headers)
          commit('SET_DISPENSATION_DATA_ROWS', viewParams.data.dispensationsDisplayTable.dataRows)
        }
        commit('SET_LOADING_STATE', false)
      } catch (e) {
        commit('SET_TOTAL_DISPENSATIONS', 0)
        defaultToast(ToastType.Error, 'Episode or Dispensation does not exist')
      }
    },
    async removeDispensation ({ commit, state }, dispensationId) {
      const url = '/API/Dispensation/RemoveDispensation?dispensationId=' + dispensationId
      const response = await ApiServerClient.get(url, null, errorCallback)
      if (response.Success) {
        defaultToast(ToastType.Success, 'Deleted Successfully')
      } else {
        defaultToast(ToastType.Error, 'Unable to Delete')
      }
    },
    async patientPreviousData ({ commit, state }, requestData) {
      const url = '/api/Dispensation/PatientPreviousDispensationData?patientId=' + requestData.patientId
      const response = await ApiServerClient.post(url, requestData.data, errorCallback)
      if (response.Success) {
        return response
      } else {
        defaultToast(ToastType.Error, 'Unable to load  Previous Dispensation')
      }
    },
    async getDispensationConfigs ({ commit, state }) {
      const url = '/v1/dispensation-configs'
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.success) {
        return JSON.parse(response.data)
      } else {
        defaultToast(ToastType.Error, 'Unable to load Dispensation Configs')
      }
    }
  }
}
