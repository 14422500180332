<template>
  <div class="mb">
    <div :class="['ew-field-group', {'ew-field-group-vertical': orientation === 'v'}]">
      <label v-if="isShowLabel()" :class="[
        'ew-field-label', 'text-align-right', 'ew-label-weight', 'flex1', {'label-align': orientation === 'h'},
        {'text-align-left': orientation === 'v'}]">{{ $t(label) }}</label>
      <div v-if="isEditing" :class="['flex', 'flex1']">
      <input
        :name="name"
        class="input"
        :placeholder="placeholder"
        :label="label"
        readonly
        :disabled="isDisabled"
        :value="displayValue"
        :disabledDates="disabledDates"
        v-on:click="toggleCalendar"
      />
    <div class="calendar-container" v-on:click="handleClick($event)">
      <div :class="calendarClass">
        <div>
          <span
            class="month-year-selector uppercase"
            v-on:click="showYearSelector($event)"
          >
            <span>
              {{ this.months[this.displayData.month] }}
            </span>

            <span>
              {{ $t(this.displayData.year) }}
            </span>

            <img src="/select.svg" width="7" height="12" />
          </span>

          <span class="action-buttons" v-if="!monthSelectorOpen">
            <font-awesome-icon icon="chevron-left" class="datepicker-nav-icons" v-on:click="handlePreviousClick($event)" />
            <font-awesome-icon icon="chevron-right" class="datepicker-nav-icons" v-on:click="handleNextClick($event)"/>
          </span>
        </div>

        <div class="days-container">
          <span class="day"> S </span>
          <span class="day"> M </span>
          <span class="day"> T </span>
          <span class="day"> W </span>
          <span class="day"> T </span>
          <span class="day"> F </span>
          <span class="day"> S </span>
        </div>

        <div class="divider" />

        <div>
          <div
            class="date-selector"
            v-if="!yearSelectorOpen && !monthSelectorOpen"
          >
            <div class="selected-month uppercase">
              {{ this.months[this.displayData.month] }}
            </div>

            <div class="date-container">
              <span
                class="date"
                v-for="i in blankDateCount"
                :key="'blank-date-' + i"
              >
                &nbsp;
              </span>
              <span
                v-for="date in numberOfDays"
                :class="getDateClass(date)"
                :key="'date_' + date"
                v-on:click="handleDateSelect(date, $event)"
              >
                <span>
                  {{ date }}
                </span>
              </span>
            </div>
          </div>

          <div
            class="year-selector"
            v-if="yearSelectorOpen && !monthSelectorOpen"
          >
            <span
              v-for="year in yearsForYearSelector"
              :class="getYearClass(year)"
              :key="'year_' + year"
              v-on:click="handleYearSelect(year, $event)"
            >
              <span>
                {{ $t(year) }}
              </span>
            </span>
          </div>

          <div class="month-selector" v-if="monthSelectorOpen">
            <div class="selected-year">
              {{ $t(this.displayData.year) }}
            </div>

            <div class="month-container">
              <span
                v-for="month in months"
                :class="getMonthClass(month)"
                :key="'month_' + month"
                v-on:click="handleMonthSelect(month, $event)"
              >
                <span>
                  {{ month }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    <div v-else class="flex1">
      <label :class="['ew-field-label', 'text-align-right', 'label-weight', 'flex1', {'label-align': orientation === 'h'}, 'option-label-weight',
      {'text-align-left': orientation === 'v'}]">{{ displayValue }}</label>
     </div>
    </div>
    <div :class="['ew-field-group', {'ew-field-group-vertical': orientation === 'v'}]">
    <div class="flex1"></div>
    <div :class="[{'mr': orientation === 'h'}, 'flex1']">
    <div v-if="hasError" id="datePickerError" class="error-container"><p class="error-message">{{ $t(errorMessage) }}</p></div>
    <div v-if="hasInfo && !hasError" id="datePickerInfo" class="text-info">{{ $t(infoMessage) }}</div>
    </div>
    </div>
  </div>
</template>

<script>
import { DatePickerMixin } from './../mixins/DatePickerMixin'
import { ComponentMixin } from './../mixins/ComponentMixin'
import { isObjectValueDefined } from '../../../utils/utils.js'
export default {
  name: 'DatePicker',
  mounted () {
    const self = this
    document.addEventListener('click', function (event) {
      event.stopPropagation()
      if (self.calendarOpen && self.name !== event.target.name) {
        self.hideCalendar()
      }
    })
  },
  mixins: [DatePickerMixin, ComponentMixin],
  data (self) {
    let localValue = {}
    if (self.value) {
      const date = new Date(self.value)
      localValue = {
        date: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear()
      }
    }

    return {
      localValue,
      calendarOpen: false,
      yearSelectorOpen: false,
      monthSelectorOpen: false,
      startYearForYearSelector: 1,
      displayData: {
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      },
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    }
  },
  props: {
    name: {
      type: String
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Select Date'
    },
    value: {
      type: [Number, String]
    },
    orientation: {
      type: String,
      default: 'h'
    },
    isDisabled: {
      type: Boolean,
      default: false,
      isRequired: false
    }
  },
  computed: {
    calendarClass () {
      return {
        calendar: true,
        show: this.calendarOpen
      }
    },

    displayValue () {
      let value = ''
      if (isObjectValueDefined(this.localValue)) {
        value =
          this.localValue.date +
          ' ' +
          this.months[this.localValue.month] +
          ' ' +
          this.localValue.year
      }

      return value
    },

    numberOfDays () {
      const date = new Date(this.displayData.year, this.displayData.month, 1)
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
    },

    blankDateCount () {
      const date = new Date(this.displayData.year, this.displayData.month, 1)
      return date.getDay()
    },

    yearsForYearSelector () {
      const startYear = this.startYearForYearSelector
      const years = []

      for (let i = startYear; i < startYear + 15; i++) {
        years.push(i)
      }

      return years
    }
  },
  methods: {
    toggleCalendar () {
      if (this.calendarOpen) {
        this.hideCalendar()
      } else {
        this.showCalendar()
      }
    },
    showCalendar () {
      const today = new Date()
      if (isObjectValueDefined(this.localValue)) {
        this.displayData = {
          month: this.localValue.month,
          year: this.localValue.year
        }
      } else {
        this.displayData = {
          month: today.getMonth(),
          year: today.getFullYear()
        }
      }

      this.calendarOpen = true
    },

    hideCalendar () {
      this.calendarOpen = false
      this.yearSelectorOpen = false
      this.monthSelectorOpen = false
    },

    getDateClass (date) {
      return {
        date: true,
        active:
          date === this.localValue.date &&
          this.displayData.month === this.localValue.month &&
          this.displayData.year === this.localValue.year,
        disabled: this.getDateDisabled(date),
        filled: true
      }
    },

    getYearClass (year) {
      return {
        year: true,
        active: year === this.localValue.year
      }
    },

    getMonthClass (month) {
      return {
        month: true,
        uppercase: true,
        active:
          this.displayData.year === this.localValue.year &&
          month === this.months[this.localValue.month]
      }
    },

    showYearSelector (event) {
      event.stopPropagation()
      const today = new Date()
      let year = today.getFullYear()
      if (this.localValue.year) {
        year = this.localValue.year
      }

      this.startYearForYearSelector = Math.floor(year / 15) * 15 + 1

      this.yearSelectorOpen = true
    },

    handleYearSelect (year, event) {
      event.stopPropagation()
      this.displayData.year = year
      this.monthSelectorOpen = true
      this.yearSelectorOpen = false
    },

    handleMonthSelect (month, event) {
      event.stopPropagation()
      this.displayData.month = this.months.indexOf(month)
      this.monthSelectorOpen = false
    },

    handleDateSelect (date, event) {
      if (this.getDateDisabled(date)) {
        return
      }
      event.stopPropagation()
      this.localValue = {
        date,
        month: this.displayData.month,
        year: this.displayData.year
      }

      this.calendarOpen = false

      this.$emit(
        'input',
        new Date(this.displayData.year, this.displayData.month, date).getTime()
      )

      this.$emit(
        'change',
        new Date(this.displayData.year, this.displayData.month, date).getTime()
      )
    },

    getDateDisabled (date) {
      var dateFull = new Date(this.displayData.year, this.displayData.month, date)
      return dateFull < this.disabledDates.to || dateFull > this.disabledDates.from
    },

    handlePreviousClick (event) {
      event.stopPropagation()
      if (this.yearSelectorOpen) {
        this.showPreviousYears()
      } else {
        this.showPreviousMonth()
      }
    },

    handleNextClick (event) {
      event.stopPropagation()
      if (this.yearSelectorOpen) {
        this.showNextYears()
      } else {
        this.showNextMonth()
      }
    },

    showNextMonth () {
      this.displayData.month = (this.displayData.month + 1) % 12
      if (this.displayData.month === 0) {
        this.displayData.year = this.displayData.year + 1
      }
    },

    showPreviousMonth () {
      this.displayData.month = (this.displayData.month + 12 - 1) % 12
      if (this.displayData.month === 11) {
        this.displayData.year = this.displayData.year - 1
      }
    },

    showNextYears () {
      this.startYearForYearSelector = this.startYearForYearSelector + 15
    },

    showPreviousYears () {
      this.startYearForYearSelector = this.startYearForYearSelector - 15
    },

    handleClick (event) {
      event.stopPropagation()
    }
  },
  watch: {
    // value (val) {
    //   const date = new Date(val)
    //   this.localValue = {
    //     date: date.getDate(),
    //     month: date.getMonth(),
    //     year: date.getFullYear()
    //   }
    // }
  }
}
</script>

<style scoped>
.datepicker-nav-icons {
  color: var(--day-select-color);
  padding-bottom: 3px;
  cursor: pointer;
}
.datepicker-nav-icons:last-child {
  margin-left: 50px;
}
.label-align {
  align-self: center;
}
.ew-field-group {
  margin-bottom: 0px !important;
}

.mr {
  margin-left: 32px;
}

.mb {
  margin-bottom: 16px !important;
}

.flex {
  flex: 1;
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-direction: column;
}

.label span {
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #1e234c;
  transform-origin: top left;
  font-family: "IBM Plex Sans", sans-serif;
  height: 22px;
}

.label.error span {
  color: #ef485c;
}

.input {
  padding: 11px 16px;
  border: 1px solid var(--input-border);
  line-height: 22px;
  border-radius: 8px;
  color: var(--text-dark-blue);
  background-size: 16px 16px;
  background: var(--input-bg) url("../../../../public/icons/calendar.svg") no-repeat 95% 0.65rem;
  width: calc(100% - 32px);
  font-family: "IBM Plex Sans", sans-serif;
  cursor: pointer;
}

.input::placeholder {
  color: var(--place-holder-text);
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.input:disabled {
  background: var(--input-disabled-bg) url("../../../../public/icons/calendar.svg") no-repeat 95% 0.65rem;
  border: none;
  cursor: not-allowed;
}

.input:focus-visible {
  outline: none;
}

.error-container {
  margin-top: 2px;
}

.input.error {
  border: 1px solid #ef485c !important;
  color: #ef485c;
}

.calendar-container {
  position: relative;
}

.calendar {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 304px;
  background-color: var(--background-color-primary-light-dark);
  float: left;
  z-index: 1000;
  height: 360px;
  padding-inline: 0;
  margin: 2px 0 0;
  border-radius: 8px;
  padding: 24px 12px 16px;
  box-shadow: 0px 2px 2px var(--very-light-shadow);
}

.month-year-selector {
  width: 125px;
  height: 24px;
  color: var(--text-dark-blue);
  line-height: 22px;
  font-weight: 500;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 14px;
  padding-left: 12px;
  display: inline-block;
  cursor: pointer;
}

.month-year-selector img {
  margin-left: 10px;
}

.days-container {
  margin-top: 8px;
}

.day {
  width: 40px;
  height: 20px;
  margin-right: 2px;
  margin-left: 2px;
  display: inline-block;
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 20px;
  /*font-size: 12px;*/
  font-weight: 500;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 8px;
  color: #a0a3bd;
}

.day:first-child {
  margin-left: 0;
}

.day:last-child {
  margin-right: 0;
}

.divider {
  height: 0;
  margin-top: 8px;
  border: 1px solid #a0a3bd;
  position: relative;
  left: -12px;
  width: 327px;
}

.selected-month,
.selected-year {
  margin-left: 12px;
  margin-top: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  color: var(--text-primary-color);
}

.date {
  width: 36px;
  height: 36px;
  padding: 2px;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
  color: var(--text-table-data);
  font-family: "IBM Plex Sans", sans-serif;
  cursor: pointer;
}

.date span {
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-block;
  width: 36px;
}

.date:nth-child(7n + 1) {
  margin-left: 0;
}

.date:nth-child(7n) {
  margin-right: 0;
}

.date.active span,
.date:hover span {
  background-color: #ef485c;
  border-radius: 50%;
  color: #ffffff;
}

.date.disabled span {
  pointer-events: none;
  cursor: default;
  opacity: 0.3;
}

.date.disabled:hover span {
  background: none;
  cursor: default;
  color: #464a6b;
}

.uppercase {
  text-transform: uppercase;
}

.action-buttons {
  margin-left: 83px;
  position: relative;
  top: 6px;
}

.action-buttons img {
  cursor: pointer;
}

.action-buttons img:first-child {
  margin-right: 24px;
}

.year-selector {
  margin-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
}

.year-selector .year,
.month-selector .month {
  padding: 8px;
  text-align: center;
  height: 36px;
  width: 72px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  color: #6e7191;
  cursor: pointer;
}

.year-selector .year span,
.month-selector .month span {
  display: inline-block;
  padding-top: 9px;
  padding-bottom: 9px;
  width: 72px;
}

.year-selector .year:nth-child(3n + 1),
.month-selector .month:nth-child(3n + 1) {
  margin-left: 0;
}

.year-selector .year:nth-child(3n),
.month-selector .month:nth-child(3n) {
  margin-right: 0;
}

.year-selector .year.active span,
.year-selector .year:hover span,
.month-selector .month.active span,
.month-selector .month:hover span {
  background-color: #ef485c;
  border-radius: 25px;
  color: #ffffff;
}

.month-container {
  margin-top: 8px;
  margin-left: 12px;
  margin-right: 12px;
}

.show {
  display: block;
}

.error-message{
  margin: 0px;
  color: #EF485C;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
}

.text-info{
  margin-top: 2px;
  color: #0070D9;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
}
</style>
