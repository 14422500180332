<template>
  <b-form-select v-model="selected" :options="options" size="lg" v-on:change="handleChange" class="no-background"></b-form-select>
</template>

<script>
export default {
  data () {
    return {
      selected: 'last_month',
      options: [
        { value: 'six_months', text: this.$t('six_months') },
        { value: 'last_month', text: this.$t('last_month') },
        { value: 'last_week', text: this.$t('last_week') },
        { value: 'all_time', text: this.$t('all_time') }
      ],
      dateRanges: {}
    }
  },
  mounted () {
    this.$root.$on('recievedDateOptions', data => {
      /* istanbul ignore next */
      this.dateRanges = data
    })
  },
  methods: {
    handleChange: function (selectedValue) {
      const chart = this.$parent.$refs.enrollment[0].$children[0]
      /* istanbul ignore next */
      switch (selectedValue) {
        case 'last_month':
          chart.zoomX(
            new Date(this.dateRanges.lastMonth.fromTimestamp).getTime(),
            new Date(this.dateRanges.lastMonth.toTimestamp).getTime()
          )
          break
        case 'six_months':
          chart.zoomX(
            new Date(this.dateRanges.sixMonths.fromTimestamp).getTime(),
            new Date(this.dateRanges.sixMonths.toTimestamp).getTime()
          )
          break
        case 'one_year':
          chart.zoomX(
            new Date(this.dateRanges.oneYear.fromTimestamp).getTime(),
            new Date(this.dateRanges.oneYear.toTimestamp).getTime()
          )
          break
        case 'last_week':
          chart.zoomX(
            new Date(this.dateRanges.lastWeek.fromTimestamp).getTime(),
            new Date(this.dateRanges.lastWeek.toTimestamp).getTime()
          )
          break
        case 'all_time':
          chart.zoomX(
            new Date(this.dateRanges.allTime.fromTimestamp).getTime(),
            new Date(this.dateRanges.allTime.toTimestamp).getTime()
          )
          break
        default: break
      }
    }
  }
}
</script>
<style scoped>
.no-background{
  background: var(--background-color-primary-light-dark) !important;
  font-size: 1rem !important;
  color: var(--text-primary-color) !important;
}
</style>
