import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'

export default {
  namespaced: true,
  state: {
    searchKey: '',
    searchValue: '',
    totalEpisodes: 0,
    numberPages: 0,
    pageSize: 10,
    selectedPage: 1,
    episodeDetailsLoading: true,
    existingTestView: false,
    episodeColumnsData: [],
    episodeColumns: [],
    testDetailsLoading: true,
    testsColumnsData: [],
    testsRowsData: [],
    totalTests: 0,
    testSummary: null,
    testResult: null,
    testSamples: null,
    testFullDetailsLoading: true,
    testRequestId: ''
  },
  mutations: {
    SET_EPISODE_DETAILS_LOADING_STATE (state, status) {
      state.episodeDetailsLoading = status
    },
    SET_TEST_LIST_LOADING_STATE (state, status) {
      state.testDetailsLoading = status
    },
    SET_EPISODE_COLUMNS (state, value) {
      state.episodeColumns = value
    },
    SET_EPISODE_COLUMNS_DATA (state, value) {
      state.episodeColumnsData = value
    },
    SET_TEST_DATA_ROWS (state, value) {
      state.testsRowsData = value
    },
    SET_TOTAL_EPISODES (state, value) {
      state.totalEpisodes = value
    },
    SET_TOTAL_TESTS (state, value) {
      state.totalTests = value
    },
    SET_CURRENT_PAGE (state, data) {
      state.selectedPage = data
    },
    SET_SEARCH_KEY (state, data) {
      state.searchKey = data
    },
    SET_SEARCH_VALUE (state, data) {
      state.searchValue = data
    },
    SET_NUMBER_PAGES (state, data) {
      state.numberPages = data
    },
    SET_TEST_LIST_COLUMNS (state, data) {
      state.testsColumnsData = data
    },
    SET_SEARCH_EXISTING_TEST_VIEW (state, data) {
      state.existingTestView = data
    },
    SET_TEST_SUMMARY (state, data) {
      state.testSummary = data
    },
    SET_TEST_RESULT (state, data) {
      state.testResult = data
    },
    SET_TEST_SAMPLES (state, data) {
      state.testSamples = data
    },
    SET_TEST_DETAILS_LOADING_STATE (state, data) {
      state.testFullDetailsLoading = data
    },
    SET_TEST_REQUEST_ID (state, data) {
      state.testRequestId = data
    }
  },
  actions: {
    setCurrentPage ({ commit }, newPage) {
      commit('SET_CURRENT_PAGE', newPage)
    },
    setSearchKey ({ commit }, key) {
      commit('SET_SEARCH_KEY', key)
    },
    setSearchValue ({ commit }, value) {
      commit('SET_SEARCH_VALUE', value)
    },
    setTestRequestId ({ commit }, value) {
      commit('SET_TEST_REQUEST_ID', value)
    },
    setSearchExistingTestView ({ commit }, value) {
      commit('SET_SEARCH_EXISTING_TEST_VIEW', value)
      commit('SET_NUMBER_PAGES', 0)
      if (value) {
        commit('SET_EPISODE_DETAILS_LOADING_STATE', true)
        commit('SET_EPISODE_COLUMNS', [])
        commit('SET_TOTAL_EPISODES', 0)
        commit('SET_EPISODE_COLUMNS_DATA', [])
      } else {
        commit('SET_TEST_LIST_LOADING_STATE', true)
        commit('SET_TEST_LIST_COLUMNS', [])
        commit('SET_TOTAL_TESTS', 0)
        commit('SET_TEST_DATA_ROWS', [])
      }
    },
    async loadEpisodeList ({ commit, state }) {
      const url = '/v1/episodes/search'
      commit('SET_EPISODE_DETAILS_LOADING_STATE', true)
      try {
        const data = {
          searchKey: state.searchKey,
          searchValue: state.searchValue,
          pageNo: state.selectedPage - 1,
          pageSize: state.pageSize
        }
        const viewParams = await RegistryServerClient.post(url, data, registryErrorCallback)
        var columnList = viewParams.data.episodeDisplayTable.headers.map(item => ({
          Key: item.key,
          Value: item.name,
          Sortable: item.sortable,
          Clickable: item.clickable
        }))
        commit('SET_EPISODE_COLUMNS', columnList)
        commit('SET_TOTAL_EPISODES', viewParams.data.totalHits)
        commit('SET_NUMBER_PAGES', Math.ceil(viewParams.data.totalHits / state.pageSize))
        if (viewParams.data.episodeDisplayTable) {
          commit('SET_EPISODE_COLUMNS_DATA', viewParams.data.episodeDisplayTable.dataRows)
        }
        commit('SET_EPISODE_DETAILS_LOADING_STATE', false)
      } catch (e) {
        commit('SET_TOTAL_EPISODES', 0)
        commit('SET_EPISODE_DETAILS_LOADING_STATE', false)
      }
    },
    async fetchTestList ({ commit, state }, filterKeys) {
      try {
        commit('SET_TEST_LIST_LOADING_STATE', true)
        const url = '/v1/user/tests?searchBy=' + state.searchKey + '&id=' + state.searchValue + '&page=' + (state.selectedPage - 1) + '&size=' + state.pageSize
        const response = await RegistryServerClient.get(url, null, registryErrorCallback)
        if (response.success && response.data !== null) {
          var testListDetails = response.data
          var columnList = testListDetails.testRequestsDisplayTable.headers
            .filter(item => !filterKeys.includes(item.key))
            .map(item => ({
              Key: item.key,
              Value: item.name,
              Sortable: item.sortable,
              Clickable: item.clickable
            }))
          commit('SET_TEST_LIST_COLUMNS', columnList)
          commit('SET_TOTAL_TESTS', testListDetails.totalHits)
          commit('SET_NUMBER_PAGES', Math.ceil(testListDetails.totalHits / state.pageSize))
          commit('SET_TEST_DATA_ROWS', testListDetails.testRequestsDisplayTable.dataRows)
          commit('SET_TEST_LIST_LOADING_STATE', false)
        }
      } catch (e) {
        commit('SET_TOTAL_TESTS', 0)
        commit('SET_TEST_LIST_LOADING_STATE', false)
      }
    },
    async fetchTestDetails ({ commit }, item) {
      commit('SET_TEST_DETAILS_LOADING_STATE', true)
      // Reset details before fetching data
      commit('SET_TEST_SUMMARY', null)
      commit('SET_TEST_SAMPLES', null)
      commit('SET_TEST_RESULT', null)
      const url = item.url
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      const testSummary = response?.data?.testList?.[0] ?? null
      const testSamples = response?.data?.sampleDetails ?? null
      let testResult = response?.data?.testResults?.[0] ?? null
      if (testResult && testResult.extraData !== null) {
        const { extraData, ...flattenResult } = testResult
        testResult = { ...flattenResult, ...extraData }
      }
      if (testSummary !== null) {
        commit('SET_TEST_SUMMARY', Object.entries(testSummary).map(([key, value]) => ({ key, value })))
      }
      if (testSamples !== null) {
        commit('SET_TEST_SAMPLES', testSamples.map(sample => Object.entries(sample).map(([key, value]) => ({ key, value }))))
      }
      if (testResult !== null) {
        commit('SET_TEST_RESULT', Object.entries(testResult).map(([key, value]) => ({ key, value })))
      }
      commit('SET_TEST_DETAILS_LOADING_STATE', false)
    },
    async loadTestList ({ dispatch }) {
      await dispatch('fetchTestList', [])
    },
    async loadTestListForPatientPage ({ dispatch }) {
      const filterKeys = ['patientName', 'episodeId']
      await dispatch('fetchTestList', filterKeys)
    }
  }
}
