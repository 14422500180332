import { Map, List } from 'immutable'

export const isNotNullNorEmpty = object =>
  object !== null &&
  object !== undefined &&
  object !== {} &&
  object !== '' &&
  object !== []

export const isNullOrUndefined = object =>
  object === null ||
  object === undefined

export const createMapOf = (key, array) => {
  return array.reduce((acc, item) => {
    return acc.set(item[key], { ...item })
  }, new Map())
}
export const createMapListOf = (key, array) => {
  return List(array).groupBy(item => item[key])
}
