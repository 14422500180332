import { RegistryServerClient } from '../../RegistryApi'

export default {
  namespaced: true,
  state: {
    sidebarDetails: [],
    allowedLanguages: []
  },
  mutations: {
    GET_SIDEBAR_DETAILS (state, payload) {
      state.sidebarDetails = payload
    },
    ALLOWED_LANGUAGES_FOR_DEPLOYMENT (state, payload) {
      state.allowedLanguages = payload.data
    }
  },
  actions: {
    async getSidebarPermissions ({ dispatch, commit }) {
      const url = '/v1/user/sidebar-items?filterByHierarchyMonitoringMethod=true&filterByUserPermissions=true'
      try {
        const response = await RegistryServerClient.get(url)
        commit('GET_SIDEBAR_DETAILS', response.data.sidebarItemsList)
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('getSidebarPermissions')
        }
      }
    },
    async GetAllowedLanguagesForDeployment ({ dispatch, commit }) {
      const url = '/v1/user/languages'
      try {
        const response = await RegistryServerClient.get(url)
        commit('ALLOWED_LANGUAGES_FOR_DEPLOYMENT', response)
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('GetAllowedLanguagesForDeployment')
        }
      }
    }

  }

}
