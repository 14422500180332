import * as helper from './toastUtils.js' // doing test so i can test this file will be mocking the 'createToast'
import Toasted from 'vue-toasted'
import Vue from 'vue'
import { IsNullOrUndefinedOrEmptyString } from './utils.js'
Vue.use(Toasted)

export const ToastPosition = Object.freeze(
  {
    TopLeft: 'top-left',
    Topcenter: 'top-center',
    Topright: 'top-right',
    BottomLeft: 'bottom-left',
    Bottomcenter: 'bottom-center',
    Bottomright: 'bottom-right'
  })

export const ToastType = Object.freeze(
  {
    Success: 'Success',
    Error: 'Error',
    Warning: 'Warning',
    Neutal: 'Neutal'
  })
const ToastCancelCustomClass = Object.freeze(
  {
    Success: 'toastCancelIconWhite',
    Error: 'toastCancelIconWhite',
    Warning: 'toastCancelIconBlack',
    Neutal: 'toastCancelIconBlack'
  })
const ToastButtonCustomClass = Object.freeze(
  {
    Success: 'toastBtnWhite',
    Error: 'toastBtnWhite',
    Warning: 'toastBtnBlack',
    Neutal: 'toastBtnBlack'
  })

const ToastClass = Object.freeze(
  {
    Success: 'toastSuccess',
    Error: 'toastError',
    Warning: 'toastWarning',
    Neutal: 'toastNeutal'
  })

export const createToast = (message = '', action = null, options = null) => {
  if (action == null || action === undefined) {
    action = {
      class: 'toastCancelIconWhite',
      onClick: (e, toastObject) => {
        toastObject.goAway(0)
      }
    }
  }
  if (options == null || options === undefined) {
    options = {
      type: 'success',
      theme: 'toasted-primary',
      position: ToastPosition.BottomLeft,
      duration: 5000,
      action: action,
      className: ToastType.Success
    }
  }
  Vue.toasted.show(message, options)
  return true
}

export const defaultToast = (toastType, message, duration = 5000, position = ToastPosition.BottomLeft) => {
  if (!(toastType in ToastType)) return false

  const action = {
    class: ToastCancelCustomClass[toastType],
    onClick: (e, toastObject) => {
      toastObject.goAway(0)
    }
  }
  const options = {
    type: 'success',
    theme: 'toasted-primary',
    position: position,
    duration: duration,
    action: action,
    className: `${ToastClass[toastType]} text-ibm-plex-sans`
  }
  return helper.createToast(message, action, options)
}

export const toastWithActionButton = (toastType, message, actionButtonLabel, actionToPerform, duration = 5000, position = ToastPosition.BottomLeft) => {
  if (!(toastType in ToastType)) return false
  if (IsNullOrUndefinedOrEmptyString(actionButtonLabel)) return false
  if (!(actionToPerform instanceof Function)) return false

  const action = {
    class: ToastButtonCustomClass[toastType],
    text: actionButtonLabel,
    onClick: (e, toastObject) => {
      actionToPerform()
      toastObject.goAway(0)
    }
  }
  const options = {
    type: 'success',
    theme: 'toasted-primary',
    position: position,
    duration: duration,
    action: action,
    className: `${ToastClass[toastType]} text-ibm-plex-sans`
  }
  return helper.createToast(message, action, options)
}

export default {
  defaultToast,
  toastWithActionButton,
  ToastType,
  ToastPosition
}
