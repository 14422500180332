import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { defaultToast, ToastType } from '../../../../../utils/toastUtils'

export default {
  namespaced: true,
  state: {
    authorizedDataMap: {},
    invoiceIdToDetailsMap: {}
  },
  mutations: {
    UPDATE_AUTHORIZED_DATA_MAP (state, data) {
      state.authorizedDataMap = data
    },
    UPDATE_INVOICE_MAP (state, data) {
      state.invoiceIdToDetailsMap = data
    }
  },
  actions: {
    async fetchInvoicesList ({ commit, dispatch }, { reportingUnitId }) {
      const url = `/v1/contract/${reportingUnitId}/invoices`
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.success) {
        const authorizedData = {}
        const invoiceMap = {}
        const invoiceDtos = response.data.invoiceDtos
        if (invoiceDtos.length) {
          for (const invoice of invoiceDtos) {
            const value = await dispatch('getAuthorizedValue', invoice.authorized)
            authorizedData[invoice.id] = { Key: invoice.authorized, Value: value }
            invoiceMap[invoice.id] = invoice
          }
        } else {
          defaultToast(ToastType.Error, 'No Invoices found')
        }
        commit('UPDATE_INVOICE_MAP', invoiceMap)
        commit('UPDATE_AUTHORIZED_DATA_MAP', authorizedData)
        return response.data
      } else {
        defaultToast(ToastType.Error, response.message)
      }
    },

    async updateInvoices ({ commit }, { payload }) {
      const url = '/v1/contract/invoices'
      var response = await RegistryServerClient.put(url, payload, registryErrorCallback)
      if (response.success) {
        defaultToast(ToastType.Success, 'Invoices updated')
        return response.data
      } else {
        defaultToast(ToastType.Error, response.message)
      }
    },

    async updateInvoicePayments ({ commit }, { payload }) {
      const url = '/v1/contract/invoices/payments'
      var response = await RegistryServerClient.put(url, payload, registryErrorCallback)
      if (response.success) {
        defaultToast(ToastType.Success, 'Invoice updated successfully')
        return response.data
      } else {
        defaultToast(ToastType.Error, response.message)
      }
    },

    getAuthorizedValue ({ dispatch }, authorizedValue) {
      if (authorizedValue === true) {
        return 'Accept'
      } else if (authorizedValue === false) {
        return 'Reject'
      } else {
        return 'Approval Pending'
      }
    }
  }
}
