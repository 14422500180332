import { RegistryServerClient } from '../../RegistryApi'

export default {
  namespaced: true,
  state: {
    hierarchyConfigsResponse: {}
  },
  mutations: {
    GET_HIERARCHY_CONFIGS (state, payload) {
      state.hierarchyConfigsResponse = payload
    }
  },
  actions: {
    async getHierarchyConfigs ({ dispatch, commit }, { url, params }) {
      try {
        const response = await RegistryServerClient.get(url, params)
        // eslint-disable-next-line eqeqeq
        commit('GET_HIERARCHY_CONFIGS', response)
      } catch (ex) {
        // add toast here when ready
        console.log(ex.code)
        console.log(ex)
        if (ex.code === 429) {
          await dispatch('getHierarchyConfigs')
        }
      }
    }
  }
}
