import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { defaultToast, ToastType } from '../../../../../utils/toastUtils'
export default {
  namespaced: true,

  state: {
    supersetDashboards: null,
    isSupersetDashboardsLoading: true
  },

  mutations: {
    UPDATE_SUPERSET_DASHBOARDS_LOADING (state, data) {
      state.isSupersetDashboardsLoading = data
    },
    SET_SUPERSET_DASHBOARDS (state, data) {
      state.supersetDashboards = data
    }
  },

  actions: {
    async fetchSupersetDashbaords ({ commit }) {
      commit('UPDATE_SUPERSET_DASHBOARDS_LOADING', true)
      const url = '/v2/superset/dashboard-names'

      try {
        const response = await RegistryServerClient.get(url, null, registryErrorCallback)

        if (response?.success && response?.data) {
          commit('SET_SUPERSET_DASHBOARDS', response.data)
        } else {
          defaultToast(
            ToastType.Error,
            'Unable to load superset dashboards'
          )
        }
      } catch (err) {
        registryErrorCallback(err)
      }
      commit('UPDATE_SUPERSET_DASHBOARDS_LOADING', false)
    },

    async fetchSupersetGuestToken ({ commit }, embeddingUUID) {
      const url = `/v2/superset/guest-token/${embeddingUUID}`
      try {
        const response = await RegistryServerClient.get(url, null, registryErrorCallback)
        if (response.success) {
          return response.data.guestToken
        } else {
          defaultToast(ToastType.Error, 'Unable to fetch guest token')
        }
      } catch (err) {
        registryErrorCallback(err)
      }
    }
  }
}
