<template>
    <div class="grid-form-part">
      <div class = "v-card__title">
        <h4 v-if="checkIsNotNullOrEmpty(headingText)" id = "part-heading" class="title text-ibm-plex-sans dark-text">{{ $t(headingText) }}</h4>
      </div>
      <hr data-v-761ae283="" class="ew-hr mlr">
        <div class="grid-part">
            <component
                v-for="field in fields"
                :ref="field.Name"
                :id="field.Name"
                :type="field.Type"
                :addOn="field.AddOn"
                :key="field.Key"
                :is="field.Component"
                :name="field.Name"
                :allOptions="field.OptionsWithKeyValue"
                :optionsWithKeyValue="field.OptionsWithKeyValue"
                :value="field.Value"
                :label="field.Label"
                :labelKey="field.LabelKey"
                :isDisabled="field.IsDisabled"
                :isRequired="field.IsRequired"
                :hasError="field.hasError"
                :errorMessage="field.errorMessage"
                :andValidations="getValidations(field.Key, 'And')"
                :orValidations="getValidations(field.Key, 'Or')"
                :remoteUrl="field.RemoteUrl"
                :optionsWithLabel="field.OptionsWithLabel"
                :optionDisplayKeys="field.OptionDisplayKeys"
                :optionValueKeys="field.OptionValueKeys"
                :responseDataPath="field.ResponseDataPath"
                :placeholderText="field.Placeholder"
                :hierarchyConfigs="hierarchyConfigs.get(field.Name)"
                :loadImmediately="field.LoadImmediately"
                :to="getDateConfigKey(field,'To')"
                :from="getDateConfigKey(field,'From')"
                :days="getDateConfigKey(field,'Days')"
                :daysOfMonth="getDateConfigKey(field,'DaysOfMonth')"
                :dates="getDateConfigKey(field,'Dates')"
                :ranges="getDateConfigKey(field,'Ranges')"
                :remoteUpdateConfig= field.RemoteUpdateConfig
                :labelAlign="'left'"
                :fieldWidth="'col-sm-5'"
                :isEditing="isEditing"
                :formPart="field.PartName"
                :errorClass="'col-sm-3'"
                class="grid-item"
                @change="onDataChange"
                v-model="localValue[field.Name]"
                v-bind="field"
                :orientation="getOrientation(field)"
                :isMultiSelect= "field.Component === 'app-multiselect' || field.isMultiSelect"
                :style="'grid-area: auto / auto / span ' + formattedWidth(field.RowWidth) + ' / span ' + formattedWidth(field.ColumnWidth)"
            ></component>
        </div>
    </div>
</template>
<script>
import { FormPartMixin, FieldComponentMap } from '../mixins/FormPartMixin.js'
import { List } from 'immutable'
import { mapState } from 'vuex'
import { isNotNullNorEmpty, isNullOrUndefined } from '../../../utils/utils.js'

export default {
  name: 'GridFormPart',
  mixins: [FormPartMixin, FieldComponentMap],
  props: {
    numRows: {
      type: Number,
      default: 4
    },
    numCols: {
      type: Number,
      default: 3
    },
    headingText: {
      type: String
    }
  },
  mounted () {
    document.documentElement.style.setProperty('--rowNum', this.numRows)
    document.documentElement.style.setProperty('--colNum', this.numCols)
  },
  computed: {
    ...mapState('Form', [
      'fieldsMappedByFormPartName'
    ]),
    fields () {
      let ordered = new List()
      if (this.fieldsMappedByFormPartName.has(this.name)) {
        ordered = List(this.fieldsMappedByFormPartName.get(this.name))
          .filter(f => f.IsVisible)
          .sortBy(f => f.ColumnNumber)
          .sortBy(f => f.RowNumber)
          .toArray()
        for (const f of this.fieldsMappedByFormPartName.get(this.name)) {
          if (!isNullOrUndefined(f.DefaultValue) && !isNotNullNorEmpty(this.localValue[f.Name])) {
            this.changeLocal(f.Name, f.DefaultValue)
          }
          if (!isNullOrUndefined(f.Value)) {
            this.changeLocal(f.Name, f.Value)
          }
        }
      }
      return ordered
    }
  },
  methods: {
    changeLocal (field, value) {
      this.localValue[field] = value
    },
    checkIsNotNullOrEmpty (object) {
      return isNotNullNorEmpty(object)
    },
    formattedWidth (width) {
      let formattedWidth = 1
      if (width) {
        formattedWidth = width
      }
      return formattedWidth
    },
    getOrientation (field) {
      if (field.Component === 'app-radio' && (field.PartName === 'selectDateRange' || field.PartName === 'selectReportType')) {
        return 'h'
      }
      if (field.Component === 'app-checkbox-group' && field.PartName === 'selectPatientStatus') {
        return 'h'
      }
      return 'v'
    }
  }
}
</script>
<style lang='scss' scoped>
:root {
  --rowNum: 4;
  --colNum: 2;
}
.grid-part {
  display: grid;
  grid-template-rows: repeat(var(--rowNum), var(--gridHeight));
  grid-template-columns: repeat(var(--colNum), auto);
  grid-gap: 24px;
  margin: 24px 0;
  padding: 12px;
}
.grid-part:before {
   content: none;
}
.grid-part:after {
   content: none;
}
.grid-item {
  margin: 5px;
}
.grid-form-part {
  margin: 15px 0px 12px 0px;
  background-color: var(--background-color-primary-light-dark);
  border-radius: 12px;
  box-sizing: unset;
}
.grid-part-heading-container {
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 12px 12px 0px 0px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.005em;
}
</style>
