<template>
  <transition name="modal-fade">
    <div
      v-if="isVisible"
      class="modal-backdrop">
      <div class="modal text-ibm-plex-sans"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header"
          v-if="headingText"
        >
          <slot name="header">
            {{$t(headingText)}}
            <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
            >
            </button>
          </slot>
        </header>
        <section
          class="modal-body"
        >
          {{$t(bodyText)}}
          <slot name="body">
          </slot>
        </section>
        <footer class="modal-footer">
          <slot name="footer">
            {{$t(footerText)}}
            <Button id="modalCancel"
              v-if="!hideCancelBtn"
              class="ml-8 cancel-btn"
              type="outlined"
              :label="$t(cancelText)"
              @click="close"
            />
            <Button id="modalConfirm"
              v-if="!hideConfirmBtn"
              :label="$t(confirmText)"
              class="ml-8 confirm-btn"
              @click="click"
              :disabled = "disableConfirmBtn"
              aria-label="Close modal"
            />
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>
<script>
import Button from './Button.vue'

export default {
  components: { Button },
  name: 'Modal',
  props: {
    headingText: {
      type: String
    },
    bodyText: {
      type: String
    },
    footerText: {
      type: String
    },
    bodyHTML: {
      type: String
    },
    cancelText: {
      type: String,
      default: 'cancel'
    },
    confirmText: {
      type: String,
      default: '_confirm'
    },
    disableConfirmBtn: {
      type: Boolean,
      default: false
    },
    hideCancelBtn: {
      type: Boolean,
      default: false
    },
    hideConfirmBtn: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isVisible: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
      this.isVisible = false
    },
    click () {
      this.$emit('click')
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    /* box-shadow: 2px 2px 20px 1px; */
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    background: var(--notes-box-color);
    border-radius: 16px;
    padding: 32px;
    max-width: 519px;
    min-width: 500px;
    overflow-y: scroll;
    max-height: 88%;
    margin-top: 8%;
    margin-bottom: 4%;
  }

  .modal-header,
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }

  .modal-header {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    /* Dark/Title */
    color: var(--text-primary-color);
  }

  .modal-footer {
    margin-top: 28px;
    justify-content: flex-end;
  }

  .modal-body {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.0125em;
    text-align: start;
    /* Body Text */
    color: $body;
  }

  .btn-close {
    border: none;
    cursor: pointer;
    font-weight: bold;
    height: 18px;
    width: 18px;
    background-color: transparent;
    background-image: url("../../../../public/VectorCross.svg");
    background-repeat: no-repeat;
}

.cancel-btn, .confirm-btn, .seconday-btn {
  margin-right: 15px;
}

.confirm-btn {
  margin-right: 0;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
</style>
