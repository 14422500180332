import { tasklistChildren } from '../../../constants/index.js'
const ReviewVot = () => import('./ReviewVot/ReviewVot.vue')
const Overview = () => import('./Overview/Overview.vue')
const Dashboard = () => import('./Dashboard.vue')
const Demo = () => import('@/app/Pages/dashboard/patient/Demo.vue')
const Patient = () => import('./patient/Patient.vue')
const Adherence = () => import('./patient/Tabs/Adherence/App.vue')
const SupportActions = () => import('./patient/components/SupportActions.vue')
const UnifiedPatient = () => import('./UnifiedPatient/UnifiedPatient.vue')
const Registration = () => import('./Registration/Registration.vue')
const Notes = () => import('./patient/components/Notes.vue')
const CallLogs = () => import('./patient/components/CallLogs.vue')
const MERM = () => import('./patient/components/MERM.vue')
const Evrimed = () => import('./components/Evrimed.vue')
const Dispensation = () => import('./patient/components/Dispensation.vue')
const StaffMapping = () => import('./patient/components/StaffMapping.vue')
const CloseCase = () => import('./patient/components/CloseCase.vue')
const Comorbidity = () => import('./patient/components/Comorbidity.vue')
const TreatmentCenterTab = () => import('./patient/components/TreatmentCenterTab.vue')
const Engagement = () => import('./patient/components/Engagement.vue')
const DeletePatient = () => import('./patient/components/DeletePatient.vue')
const BasicDetails = () => import('./patient/components/BasicDetails.vue')
const Tasklists = () => import('./tasklist/Tasklists.vue')
const StaffDetails = () => import('./StaffDetails/StaffDetails.vue')
const AddStaffDetails = () => import('./StaffDetails/AddStaffDetails.vue')
const HierachyManagement = () => import('./HierarchyManagement/HierarchyManagement.vue')
const HierarchyDetails = () => import('@/app/Pages/dashboard/HierarchyManagement/components/HierarchyDetails.vue')
const LoginsManagement = () => import('@/app/Pages/dashboard/HierarchyManagement/components/LoginsManagement.vue')
const Communications = () => import('@/app/Pages/dashboard/HierarchyManagement/components/Communications.vue')
const Reports = () => import('./Reports/Reports.vue')
const RegimenTemplate = () => import('./RegimenTemplate/RegimenTemplate.vue')
const SupersetDashboard = () => import('./SupersetDashboard/SupersetDashboard.vue')
const Diagnostics = () => import('./diagnostics/DiagnosticsPage.vue')
const DiagnosticsTab = () => import('./patient/components/Diagnostics.vue')
const AddTest = () => import('./diagnostics/AddTest.vue')
const EditTest = () => import('./diagnostics/EditTest.vue')
const DispensationPage = () => import('./dispensation/Dispensation.vue')
const UserManagement = () => import('./userMgmt/UserManagement.vue')
const FacilityAdministration = () => import('./userMgmt/components/FacilityAdministration.vue')
const AddFacility = () => import('./userMgmt/components/AddFacility.vue')
const MergeHealthFacility = () => import('./userMgmt/components/MergeHealthFacility.vue')
const MyProfile = () => import('./userMgmt/components/MyProfile.vue')
const ResetPassword = () => import('./userMgmt/components/ResetPassword.vue')
const Contracts = () => import('./contractManagement/Contracts.vue')
const AddContract = () => import('./contractManagement/AddContract.vue')
const ContractDetails = () => import('./contractManagement/ContractDetails.vue')
const KpiTabBulk = () => import('./contractManagement/components/KpiTabBulk.vue')
const ContractTabBulk = () => import('./contractManagement/components/ContractTabBulk.vue')
const ContractTab = () => import('./contractManagement/components/ContractTab.vue')
const InvoiceTab = () => import('./contractManagement/components/InvoiceTab.vue')
const TasklistTabsSection = () => import('./tasklist/componenets/TasklistTabsSection.vue')
const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    children: [
      {
        path: 'reviewvot',
        name: 'Review VOT',
        component: ReviewVot
      },
      {
        path: 'demo',
        name: 'demo',
        component: Demo
      },
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'UnifiedPatient',
        name: 'UnifiedPatient',
        component: UnifiedPatient
      },
      {
        path: 'evrimed',
        name: 'Evrimed',
        component: Evrimed
      },
      {
        path: 'superset',
        name: 'SupersetDashboard',
        component: SupersetDashboard
      },
      {
        path: 'patient/:patientId',
        name: 'patient',
        component: Patient,
        props: true,
        children: [
          {
            path: 'ADHERENCE',
            name: 'adherence',
            component: Adherence
          },
          {
            path: 'SUPPORT_ACTIONS',
            name: 'support_actions',
            component: SupportActions
          },
          {
            path: 'STAFF_MAPPING',
            name: 'staff_mapping',
            component: StaffMapping
          },
          {
            path: 'TAGS_AND_NOTES',
            name: 'tags_and_notes',
            component: Notes,
            props: true
          },
          {
            path: 'NOTES',
            name: 'notes',
            component: Notes,
            props: true
          },
          {
            path: 'CALL_LOGS',
            name: 'call_logs',
            component: CallLogs,
            props: true
          },
          {
            path: 'MERM',
            name: 'merm',
            component: MERM,
            props: true
          },
          {
            path: 'CLOSE_CASE',
            name: 'close_case',
            component: CloseCase,
            props: true
          },
          {
            path: 'DISPENSATION',
            name: 'dispensation',
            component: Dispensation
          },
          {
            path: 'BASIC_DETAILS',
            name: 'basic_details',
            component: BasicDetails
          },
          {
            path: 'COMORBIDITY',
            name: 'comorbidity',
            component: Comorbidity
          },
          {
            path: 'TREATMENT_CENTERS',
            name: 'treatment_centers',
            component: TreatmentCenterTab
          },
          {
            path: 'ENGAGEMENT',
            name: 'engagement',
            component: Engagement,
            props: true
          },
          {
            path: 'DELETE_PATIENT',
            name: 'delete_patient',
            component: DeletePatient
          },
          {
            path: 'VOT_REVIEW',
            name: 'vot_review',
            component: ReviewVot,
            props: true
          },
          {
            path: 'Tests',
            name: 'tests',
            component: DiagnosticsTab,
            props: true
          }
        ]
      },
      {
        path: 'enrollment',
        name: 'enrollment',
        component: Registration
      },
      {
        path: 'tasklists',
        name: 'tasklists',
        component: Tasklists,
        children: tasklistChildren
      },
      {
        path: 'StaffDetails',
        name: 'StaffDetails',
        component: StaffDetails
      },
      {
        path: 'StaffDetails/:hierarchyId',
        name: 'StaffDetails',
        component: StaffDetails
      },
      {
        path: 'AddStaff/:hierarchyId',
        name: 'AddStaff',
        component: AddStaffDetails
      },
      {
        path: 'UpdateStaff/:staffId',
        name: 'UpdateStaff',
        component: AddStaffDetails
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports
      },
      {
        path: 'hierarchymanagement',
        name: 'hierarchymanagement',
        component: HierachyManagement,
        props: true,
        children: [
          {
            path: 'hierarchy_details/:hierarchyId',
            name: 'hierarchy_details',
            component: HierarchyDetails
          },
          {
            path: 'logins/:hierarchyId',
            name: 'logins',
            component: LoginsManagement
          },
          {
            path: 'communication/:hierarchyId',
            name: 'communication',
            component: Communications
          }
        ]
      },
      {
        path: 'dispensation/template',
        name: 'RegimenTemplate',
        component: RegimenTemplate
      },
      {
        path: 'diagnostics',
        name: 'Diagnostics',
        component: Diagnostics
      },
      {
        path: 'diagnostics/add-test/:episodeId',
        name: 'AddTest',
        component: AddTest
      },
      {
        path: 'diagnostics/edit-test/:testRequestId',
        name: 'EditTest',
        component: EditTest
      },
      {
        path: 'dispensation',
        name: 'Dispensation',
        component: DispensationPage
      },
      {
        path: 'UserManagement',
        name: 'UserManagement',
        component: UserManagement,
        props: true,
        children: [
          {
            path: 'FacilityAdministration',
            name: 'Facility Administration',
            component: FacilityAdministration
          },
          {
            path: 'AddFacility',
            name: 'Add Facility',
            component: AddFacility
          },
          {
            path: 'MergeHealthFacility',
            name: 'Merge Health Facility',
            component: MergeHealthFacility
          },
          {
            path: 'MyProfile',
            name: 'My Profile',
            component: MyProfile
          },
          {
            path: 'ResetPassword',
            name: '_reset_password',
            component: ResetPassword
          }
        ]
      },
      {
        path: 'Contracts',
        name: 'Contracts',
        component: Contracts
      },
      {
        path: 'AddContract',
        name: '_add_contract',
        component: AddContract
      },
      {
        path: 'contract/:id',
        name: 'contract',
        component: ContractDetails,
        props: true,
        children: [
          {
            path: 'invoice',
            name: 'Invoices',
            component: InvoiceTab
          },
          {
            path: 'kpis',
            name: 'KPI Details',
            component: KpiTabBulk
          },
          {
            path: 'details',
            name: 'Contract Details',
            component: ContractTabBulk
          },
          {
            path: 'targetedDetails',
            name: 'Targeted Details',
            component: ContractTab,
            props: true
          }
        ]
      },
      {
        path: 'ComplianceList',
        name: 'compliance_list',
        component: TasklistTabsSection,
        children: [
          {
            path: '*',
            name: 'compliance_list',
            component: TasklistTabsSection
          }
        ]
      }
    ]
  }
]

export default dashboardRoutes
