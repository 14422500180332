<template>
  <div  :class="['ew-field-group', 'ain', {'ew-field-group-vertical': orientation === 'v'}]">
    <label v-if="label !== '' && displayLabel" :class="[
      'ew-field-label', 'text-align-right', 'flex1', 'ew-label-weight',
      {'text-align-left': orientation === 'v'},
      {'error': hasError}]">{{ $t(label) }}</label>
     <div :class="['flex1', {'flex': orientation === 'v'}, {'options': orientation === 'v'}]">
      <div v-if ="isOptionWithKeyValuePresent()" :class="['flex1', {'flex': orientation === 'v'}, {'options': orientation === 'v'}]">
        <div
        v-for="option in optionsWithKeyValue"
        :key="option.Key"
        :class="['text-ibm-plex-sans-14', 'ufmr-xl', {'ufmt-md': orientation === 'v'}, 'ufmb-md']"
        >
          <input
              type="checkbox"
              class="c-checkbox"
              :id="'cCheckbox' + option.Key + name"
              :required="isRequired"
              :name="name"
              :disabled="isDisabled"
              :value="option.Key"
              v-model="localValue"
              @change="onDataChange(option.Key)"
          />
          <label class="text-ibm-plex-sans-14 option-label-weight " :for="'cCheckbox' + option.Key + name">
              {{ $t(option.Value) }}
          </label>
          <label v-if="displayHyperlink" class="text-ibm-plex-sans-14 hyperlink option-label-weight " :for="'Checkbox' + option.Key + name" @click="routeToLink(option.Link)">
              {{ $t(option.LinkText) }}
          </label>
          <br v-if="option.ExtraInfo">
          <label v-if="option.ExtraInfo" class="text-ibm-plex-sans-14 pd option-label-weight" >
          {{(option.ExtraInfo)}}
          </label>
        </div>
      </div>
      <div v-else-if ="!isOptionWithKeyValuePresent()">
          <label >
              <input
                  type="checkbox"
                  class="single-checkbox"
                  :required="isRequired"
                  :disabled="disabled"
                  :name="name"
                  v-model="localValue"
                  @change="onDataChange(0)"
              />
              {{label}}
          </label>
      </div>
      <div v-if="hasError" id="checkBoxError" class="error-container"><p class="error-message">{{ $t(errorMessage) }}</p></div>
      <div v-if="hasInfo && !hasError" id="checkBoxInfo" class="text-info">{{ $t(infoMessage) }}</div>
    </div>
  </div>
</template>

<script>
import { ComponentMixin } from '@/app/shared/mixins/ComponentMixin'
export default {
  data (self) {
    return {
      localValue: self.value
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    optionsWithKeyValue: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Label'
    },
    displayHyperlink: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: 'Name'
    },
    orientation: {
      type: String,
      default: 'h'
    },
    displayLabel: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    this.localValue = (this.value ? this.value : [])
  },
  watch: {
    // value (val) {
    //   this.localValue = val
    // }
  },
  methods: {
    isOptionWithKeyValuePresent () {
      if (this.optionsWithKeyValue && this.optionsWithKeyValue.length > 0) {
        return true
      } else {
        return false
      }
    },
    onDataChange (index) {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
    },
    routeToLink (link) {
      this.$router.push(link)
    }
  },
  mixins: [ComponentMixin]
}
</script>

<style lang="scss" scoped>

input[type="checkbox"] {
  display: none;
  width: 28px;
}

.single-checkbox {
   display: inline-flex !important;
   width: 28px;
}

.flex{
    display: flex;
    justify-content: left;
    width: 100%;
    align-items: inherit;
}

.options{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 5px;
}

.text-info{
  margin-top: 2px;
  color: #0070D9;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
}

.error-container{
  margin-top: 2px;
}

.error-message{
  margin: 0px;
  color: #EF485C;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
}

.text-ibm-plex-sans-14{
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

input[type="checkbox"] + label {
  position: relative;
  padding-left: 28px;
  display: inline-block;
  min-height: 1rem;
  vertical-align: top;
  text-transform: capitalize;
  cursor: pointer;
  color: var(--text-primary-color);
}

input[type="checkbox"] + label:before {
  content: '';
  display: block;
  position: absolute;
  height: 14px;
  width: 14px;
  border: 2.5px solid var(--border-color);
  background: none;
  border-radius: 2px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: background 100ms ease-in-out;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing:unset;
}

input[type="checkbox"]:not(:checked) + label:before {
  border: 2.5px solid $placeholderText;
  border-radius: 2px;
  background: transparent;
}

input[type="checkbox"]:checked + label:before {
  border: 2.5px solid var(--primary-theme);
  background-color: var(--primary-theme);
  background-image: url("../../../../public/tick.svg");
}

input[type="checkbox"]:hover + label:before{
  border: 2px solid $hover-border;
  filter: drop-shadow(0px 0px 6px var(--primary-theme));
}

input[type="checkbox"]:checked:hover + label:before {
  background-color: $hover-border;
}

[type="checkbox"]:checked:disabled + label:before {
  background-color: $line;
}

[type="checkbox"]:checked:disabled:hover + label:before {
  background-color: $line;
}

[type="checkbox"]:disabled + label:before
{
  border: 2.5px solid $line !important;
  filter: none !important;
}

input[type="checkbox"]:disabled + label {
  color: $placeholderText;
  cursor:default;
}

.hyperlink {
  color: var(--primary-theme) !important;
  font-weight: 500;
  transition: 0.3s;
}
.hyperlink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.pd {
  padding-left: 30px;
}
</style>
