<template>
  <div id="checkbox-grid-form-part" >
    <h2 id="checkbox-grid-part-heading">{{ $t(headingText) }}</h2>
      <div class="form-card" v-for="(row, index) in fieldRows" v-bind:key="index">
        <div id = "checkbox-group-component" class="main-checkbox" >
          <div>
            <div v-for="(field) in row" v-bind:key="field.Name">
              <component
                v-if="field.IsVisible && (field.Component == 'app-checkbox-group' || field.Component == 'app-checkbox')"
                :ref="field.Name"
                :id="field.Name"
                :type="field.Type"
                :addOn="field.AddOn"
                :key="field.Key"
                :is="field.Component"
                :name="field.Name"
                :allOptions="field.OptionsWithKeyValue"
                :optionsWithKeyValue="field.OptionsWithKeyValue"
                :value="field.Value"
                :label="checkboxLabels[index]"
                :labelKey="field.LabelKey"
                :isDisabled="field.IsDisabled"
                :required="field.IsRequired"
                :hasError="field.hasError"
                :errorMessage="field.errorMessage"
                :hasInfo="field.HasInfo"
                :infoMessage="field.InfoText"
                :andValidations="getValidations(field.Key, 'And')"
                :orValidations="getValidations(field.Key, 'Or')"
                :remoteUrl="field.RemoteUrl"
                :optionDisplayKeys="field.OptionDisplayKeys"
                :optionValueKeys="field.OptionValueKeys"
                :responseDataPath="field.ResponseDataPath"
                :placeholder="field.Placeholder"
                :loadImmediately="field.LoadImmediately"
                :to="getDateConfigKey(field,'To')"
                :from="getDateConfigKey(field,'From')"
                :days="getDateConfigKey(field,'Days')"
                :daysOfMonth="getDateConfigKey(field,'DaysOfMonth')"
                :dates="getDateConfigKey(field,'Dates')"
                :ranges="getDateConfigKey(field,'Ranges')"
                :remoteUpdateConfig= field.RemoteUpdateConfig
                :labelAlign="'left'"
                :fieldWidth="'col-sm-2'"
                :isEditing="isEditing"
                :formPart="field.PartName"
                :errorClass="'col-sm-12'"
                :orientation="'v'"
                :displayLabel="setDisplayLabel (field)"
                @change="onLocalDataChange(index)"
                v-model="localValue[rowDataName][index][field.Name]"
                v-bind="field"
              ></component>
            </div>
          </div>
        </div>
        <b-collapse :id='"accordion-" + index'>
          <div  id = "field-component" class = "field-group">
            <div v-for="(field) in row" v-bind:key="field.Name">
              <component
                v-if="field.IsVisible && (field.Component != 'app-checkbox-group' && field.Component != 'app-checkbox')"
                :ref="field.Name"
                :id="field.Name"
                :type="field.Type"
                :addOn="field.AddOn"
                :key="field.Key"
                :is="field.Component"
                :name="field.Name"
                :allOptions="field.OptionsWithKeyValue"
                :optionsWithKeyValue="field.OptionsWithKeyValue"
                :value="field.Value"
                :label="field.Label"
                :labelKey="field.LabelKey"
                :isDisabled="field.IsDisabled"
                :required="field.IsRequired"
                :hasError="field.hasError"
                :errorMessage="field.errorMessage"
                :remoteUrl="field.RemoteUrl"
                :optionDisplayKeys="field.OptionDisplayKeys"
                :optionValueKeys="field.OptionValueKeys"
                :responseDataPath="field.ResponseDataPath"
                :placeholder="field.Placeholder"
                :loadImmediately="field.LoadImmediately"
                :to="getDateConfigKey(field,'To')"
                :from="getDateConfigKey(field,'From')"
                :days="getDateConfigKey(field,'Days')"
                :daysOfMonth="getDateConfigKey(field,'DaysOfMonth')"
                :dates="getDateConfigKey(field,'Dates')"
                :ranges="getDateConfigKey(field,'Ranges')"
                :remoteUpdateConfig= field.RemoteUpdateConfig
                :labelAlign="'left'"
                :fieldWidth="'col-sm-4'"
                :isEditing="isEditing"
                :formPart="field.PartName"
                :errorClass="'col-sm-4'"
                :orientation="'v'"
                @change="onDataChange"
                v-model="localValue[rowDataName][index][field.Name]"
                v-bind="field"
              ></component>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
</template>
<script>

import { FormPartMixin, FieldComponentMap } from '../mixins/FormPartMixin.js'
import { List } from 'immutable'
import { isNotNullNorEmpty, isNullOrUndefined } from '../../../utils/utils.js'
import '../../../plugins/collapse'

export default {
  name: 'CheckboxGridFormPart',
  mixins: [FormPartMixin, FieldComponentMap],
  props: {
    headingText: {
      type: String
    }
  },
  data: self => ({
    checkboxLabels: [],
    valuesSet: false
  }),
  computed: {
    fields () {
      var visibleFields = []
      var configFields = this.allFields.filter(af => af.PartName === this.name)
      var partFields = this.fieldsMappedByFormPartName.get(this.name)
      configFields.forEach((f, index) => {
        var isVisible = false
        partFields.filter(pf => pf.Key.startsWith(f.PartName + f.Name)).forEach(pf => {
          if (pf.IsVisible) {
            isVisible = true
          }
        })
        if (isVisible) {
          visibleFields.push(f)
        }
      })
      visibleFields = List(visibleFields).sortBy(vf => vf.ColumnNumber).toArray()
      return visibleFields
    },
    fieldRows () {
      if (isNullOrUndefined(this.fieldsMappedByFormPartName.get(this.name))) {
        return []
      }
      var numRows = 0
      if (this.localValue[this.rowDataName]) {
        numRows = this.localValue[this.rowDataName].length
      }

      const allTableFields = this.fieldsMappedByFormPartName.get(this.name).toArray()
      var rows = []
      let ordered = new List()
      for (var i = 0; i < numRows; i++) {
        var fields = allTableFields.filter(atf => atf.Key.endsWith(i))
        fields = fields.filter(f => {
          var firstNumberRegEx = /\d+$/
          var firstNumberIndex = f.Key.search(firstNumberRegEx)
          var fieldKey = f.Key.substring(firstNumberIndex, f.Key.length)
          return i === parseInt(fieldKey)
        })
        ordered = List(fields)
          .filter(f => this.fields.map(k => k.Name).includes(f.Name))
          .sortBy(f => f.ColumnNumber)
          .toArray()
        rows.push(ordered)
        for (const f of fields) {
          if (!isNullOrUndefined(f.DefaultValue) && !isNotNullNorEmpty(this.localValue[this.rowDataName][i][f.Name])) {
            this.changeLocal(i, f.Name, f.DefaultValue)
          }
          if (!isNullOrUndefined(f.Value)) {
            this.changeLocal(i, f.Name, f.Value)
          }
          if ((f.Component === 'app-checkbox-group' || f.Component === 'app-checkbox') && isNullOrUndefined(this.checkboxLabels[i])) {
            this.updateCheckBoxLabels(i, f.Name)
          }
          if ((f.Component === 'app-checkbox-group' || f.Component === 'app-checkbox') && !this.valuesSet) {
            const value = this.convertDefaultValue(f)
            this.changeLocal(i, f.Name, value)
          }
        }
      }
      this.updateValueSet(true)
      return rows
    }
  },
  methods: {
    onLocalDataChange (index) {
      this.$root.$emit('bv::toggle::collapse', 'accordion-' + index)
      this.onDataChange()
    },
    changeLocal (index, field, value) {
      this.localValue[this.rowDataName][index][field] = value
    },
    updateCheckBoxLabels (index, field) {
      this.checkboxLabels[index] = this.localValue[this.rowDataName][index][field]
    },
    updateValueSet (value) {
      this.valuesSet = value
    },
    convertDefaultValue (field) {
      if (field.Component === 'app-checkbox') {
        return (field.DefaultValue === 'true')
      } else {
        return field.DefaultValue
      }
    },
    setDisplayLabel (field) {
      if (field.Component === 'app-checkbox') {
        return false
      } else {
        return true
      }
    }

  }
}

</script>
<style lang='scss' scoped>

#checkbox-grid-form-part {
  margin: 24px
}

#checkbox-grid-part-heading {
  margin: 16px 0;
}

.form-card {
  transition: 0.1s;
  width: 100%;
  background:  $gray;
  border: 1px solid $light-gray;
  border-radius: 4px;
  padding: 10px 0 10px 0;
  margin-bottom: 20px;
}

.form-card:hover {
  box-shadow: 0 8px 16px 0 $shadow;
}

.main-checkbox {
  margin: 20px;
  color: $body;
  font-weight: normal;
}

.field-group {
  margin: 20px;
  font-weight: 500;
}

.collapse {
  width: 100%;
  height: 100%;
  background: $gray;
  background-repeat: no-repeat;
}
</style>
