import axios from 'axios'
import queryString from 'query-string'
import { isNotNullNorEmpty } from '../utils/Objects'
import { defaultToast, ToastType } from '../../../utils/toastUtils'

export const errorCallback = e => {
  if (e !== undefined && e.response !== undefined && e.response.data !== undefined) {
    if (e.response.data.Error !== undefined) {
      defaultToast(ToastType.Error, e.response.data.Error.Message)
    } else if (e.response.data.exceptionMessage !== undefined) {
      defaultToast(ToastType.Error, e.response.data.exceptionMessage)
    } else if (e.response.data.message !== undefined) {
      defaultToast(ToastType.Error, e.response.data.message)
    }
  } else if (isNotNullNorEmpty(e !== undefined && e.message)) {
    defaultToast(ToastType.Error, e.message)
  } else if (e !== undefined) {
    defaultToast(ToastType.Error, e)
  } else {
    defaultToast(ToastType.Error, 'Something went wrong. Please try after some time.')
  }
}

const axiosServerInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE,
  config: {
    headers: {
      withCredentials: false
      // fromunifedfrontend: true,
    }
  }
})
axiosServerInstance.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)
axiosServerInstance.defaults.withCredentials = true

class ApiClient {
  axiosInstance = null
  defaultHeader = null
  constructor (axiosIns, defaultH) {
    this.axiosInstance = axiosIns
    this.defaultHeader = defaultH
  }

  getCancelToken () {
    return axios.CancelToken
  }

  async post (path, data, errCallback = () => {}, cancelToken = null) {
    // console.log(this.axiosInstance)
    const response = await this.axiosInstance
      .post(path, data, { cancelToken, headers: this.defaultHeader })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message)
        } else {
          errCallback(thrown)
        }
      })
    if (response && response !== undefined && response.data !== undefined) {
      return response.data
    } else if (response.data.Error !== undefined) {
      defaultToast(ToastType.Error, response.data.Error.Message)
    }
  }

  async patch (path, data, errCallback = () => {}, cancelToken = null) {
    const response = await this.axiosInstance
      .patch(path, data, { cancelToken, headers: this.defaultHeader })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message)
        } else {
          if (!errCallback) {
            errCallback = errorCallback
          }

          errCallback(thrown)
        }
      })
    if (response) return response.data
  }

  async put (path, data, errCallback = () => {}, cancelToken = null) {
    const response = await this.axiosInstance
      .put(path, data, { cancelToken, headers: this.defaultHeader })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message)
        } else {
          if (!errCallback) {
            errCallback = errorCallback
          }
          errCallback(thrown)
        }
      })
    if (response) return response.data ? response.data : (response.message ? response.message : response)
  }

  async delete (path, data, errCallback = () => {}, cancelToken = null) {
    const response = await this.axiosInstance
      .delete(path, data, { cancelToken, headers: this.defaultHeader })
      .catch(function (thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message)
        } else {
          if (!errCallback) {
            errCallback = errorCallback
          }
          errCallback(thrown)
        }
      })
    if (response) return response.data
  }

  async get (path, data, errCallback = () => {}, cancelToken = null) {
    if (isNotNullNorEmpty(data)) {
      const reqString = queryString.stringify(data, { arrayFormat: 'bracket' })
      path = `${path}?${reqString}`
    }
    const response = await this.axiosInstance({
      url: path,
      method: 'get',
      cancelToken,
      headers: this.defaultHeader
    }).catch(function (thrown) {
      console.log(thrown.response)
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message)
      } else if (thrown.response.status === 429) {
        const err = new Error()
        err.code = 429
        err.name = 'Retry'
        err.Retry = thrown.response.headers.retryafter
        throw err
      } else {
        errCallback(thrown)
      }
    })
    if (response) {
      return response.data
    }
  }
}

export const ApiServerClient = new ApiClient(axiosServerInstance, {})
