export const FontAwsoneV5IconName = Object.freeze(
  {
    'fa fa-clock-o': 'clock',
    'fa fa-phone': 'phone-alt',
    'fa fa-exclamation-circle': 'exclamation-circle',
    'fa fa-calendar-minus-o': 'calendar-minus',
    'fa fa-calendar': 'calendar',
    'fa fa-rupee': 'rupee-sign',
    'fa fa-battery-quarter': 'battery-quarter',
    'fa fa-exclamation-triangle': 'exclamation-triangle',
    'fa fa-bed': 'bed',
    'fa fa-home': 'home',
    'fa fa-flask': 'flask',
    'fa fa-group': 'user-slash'
  })
