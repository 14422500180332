<template>
  <div :class="['ew-field-group', 'ew-field-group-horizontal', {'ew-field-group-vertical': orientation === 'v'}]">
    <label  v-if="isShowLabel()" for="ew-input"
      :class="[
        'ew-field-label', 'text-align-right', 'flex1', 'ew-label-weight',
        {'text-align-left': orientation === 'v'}]">
      {{ $t(label) }}
    </label>
    <div class="flex1 position-relative">
      <div  v-if="isEditing" class="ew-field-group-field">
        <span v-if="addOn" class="input-group-addon">{{$t(addOn)}}</span>
        <input
          :name=name
          :class=getInputClass()
          :placeholder=getPlaceholderText
          :label=label
          :disabled="isDisabled"
          @change="onDataChange"
          @keyup="onDataChange"
          @click="onDataChange"
          v-model="localValue"
          :type=type>
      </div>
      <div v-else-if="localValue">
         <label  :class="[ 'ew-field-label', 'text-align-right', 'option-label-weight', 'flex1',{'text-align-left': orientation === 'v'}]">{{ localValue }}</label>
      </div>
      <div v-if="checkHasError" id="inputError" class="position-relative error-color error-message">{{ $t(showErrorMessage) }}</div>
      <div v-if="hasInfo && !checkHasError" id="inputInfo" class="text-info position-absolute error-message">{{ $t(infoMessage) }}</div>
    </div>
  </div>
</template>

<script>
import { ComponentMixin } from '@/app/shared/mixins/ComponentMixin'
export default {
  mixins: [ComponentMixin],
  data () {
    return {
      localValue: this.value,
      isRequiredErrorMsg: 'error_field_required',
      minLengthErrorMsg: 'Minimum length should be '
    }
  },
  name: 'Input',
  props: {
    value: {
      type: String
    },
    name: {
      type: String
    },
    label: {
      type: String,
      default: null
    },
    // isDisabled: {
    //   type: Boolean,
    //   default: false
    // },
    placeholderText: {
      type: String
    },
    // isRequired: {
    //   type: Boolean,
    //   default: false
    // },
    minLength: {
      type: Number
    },
    addOn: {
      type: String
    },
    orientation: {
      type: String,
      default: 'h'
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  mounted () {
    this.localValue = this.value
  },
  computed: {
    checkHasError: {
      get: function () {
        if (this.hasError || (this.isRequired && !this.localValue)) {
          return true
        }
        if (this.minLength && this.localValue && this.localValue.length < this.minLength) {
          return true
        }
        return false
      },
      set: function (value) {
        return value
      }
    },
    showErrorMessage: {
      get: function () {
        if (this.hasError) {
          return this.errorMessage
        }
        let errorMessage = ''
        if (this.isRequired && !this.localValue) {
          errorMessage = this.isRequiredErrorMsg
        }
        if (this.minLength && this.localValue && this.localValue.length < this.minLength) {
          errorMessage = this.minLengthErrorMsg + this.minLength
        }
        return errorMessage
      },
      set: function (value) {
        return value
      }
    },
    getPlaceholderText: {
      get: function () {
        return this.$t(this.placeholderText)
      },
      set: function (value) {
        return value
      }
    }
  },
  watch: {
    // localValue () {
    //   if (!this.localValue && this.isRequired) {
    //     this.hasError = true
    //     this.errorMessage = this.isRequiredErrorMsg
    //   } else if (this.minLength && this.localValue && this.localValue.length < this.minLength) {
    //     this.hasError = true
    //     this.errorMessage = this.minLengthErrorMsg + this.minLength
    //   } else {
    //     this.hasError = false
    //   }
    // },
    // value (val) {
    //   this.localValue = val
    // }
  },
  methods: {
    getInputClass () {
      return {
        'ew-input': true,
        'error-input': this.hasError,
        'addon-exists': this.addOn != null
      }
    },
    onDataChange () {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
      this.$emit('click', this.localValue)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 100%;
}

.ew-input.addon-exists {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.ew-input:disabled + .ew-field-label {
  color: $placeholderText;
}

.text-info{
  margin-top: 2px;
  color: #0070D9;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
}

.error-message{
  margin: 0px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  word-wrap: break-word;
  width: 100%;
}

.error-color {
color: $error;
}

.error-input{
  border: 1px solid $error !important;
  color: $error;
}

.input-group-addon {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  background-color: var(--input-disabled-bg);
  border: none;
  border-radius: 8px;
  padding: 11px 8px;
  line-height: 22px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  display: inline-block;
  font-family: 'IBM Plex Sans', sans-serif;
  color: var(--text-primary-color);
}

.length{
  width: 100%;
}
</style>
