import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { getCookie } from '../../../../../utils/cookieUtils'

export default {
  namespaced: true,
  actions: {
    async getMermDetails ({ commit, state }, imei) {
      const url = 'v1/merm/imeis/filter'
      const searchImeiData = {
        imei,
        searchBy: 'IMEI',
        languageCode: getCookie('languageCode'),
        getHierarchyFromLoggedUser: true
      }
      const response = await await RegistryServerClient.post(
        url,
        searchImeiData,
        registryErrorCallback
      )
      if (response?.success && response.data.length > 0) {
        const imeiData = response.data[0]
        return imeiData
      } else {
        return []
      }
    },

    async checkPermission () {
      const url = 'v1/user/sidebar-items'
      const response = await RegistryServerClient.get(
        url,
        null,
        registryErrorCallback
      )
      if (response.success) {
        const { sidebarItemsList } = response.data
        const evrimedMetrics = sidebarItemsList.find(
          item => item.name === 'evrimed_metrics'
        )
        if (!evrimedMetrics) {
          window.location.href = '/dashboard/overview'
        }
      }
    },

    async updateImeiStatus ({ commit, state }, { data }) {
      const url = 'v1/imei/status'
      const response = await RegistryServerClient.patch(url, data, registryErrorCallback)
      return response.success
    }
  }
}
