import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
export default {
  namespaced: true,
  actions: {
    async getPatientCallLogs ({ commit, state }, patientId) {
      const url = 'v1/user/episode/callLogs?episodeId=' + patientId
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.success) {
        return response.data
      } else {
        // toastError('Unable to load  Details')
      }
    }
  }
}
