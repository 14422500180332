<template>
  <div class="text-area-form-part-container" >
    <div class = "text-area-heading-container">
        <h2 id = "part-heading">{{ $t(headingText) }}</h2>
    </div>
    <div class="text-area-form-part">
      <component
        :ref="field.Name"
        :id="field.Name"
        :type="field.Type"
        :addOn="field.AddOn"
        :key="field.Key"
        :is="field.Component"
        :name="field.Name"
        :allOptions="field.OptionsWithKeyValue"
        :optionsWithKeyValue="field.OptionsWithKeyValue"
        :value="field.Value"
        :label="''"
        :labelKey="field.LabelKey"
        :isDisabled="field.IsDisabled"
        :isRequired="field.IsRequired"
        :hasError="field.hasError"
        :errorMessage="field.errorMessage"
        :andValidations="getValidations(field.Key, 'And')"
        :orValidations="getValidations(field.Key, 'Or')"
        :remoteUrl="field.RemoteUrl"
        :optionsWithLabel="field.OptionsWithLabel"
        :optionDisplayKeys="field.OptionDisplayKeys"
        :optionValueKeys="field.OptionValueKeys"
        :responseDataPath="field.ResponseDataPath"
        :placeholderText="field.Placeholder"
        :loadImmediately="field.LoadImmediately"
        :to="getDateConfigKey(field,'To')"
        :from="getDateConfigKey(field,'From')"
        :days="getDateConfigKey(field,'Days')"
        :daysOfMonth="getDateConfigKey(field,'DaysOfMonth')"
        :dates="getDateConfigKey(field,'Dates')"
        :ranges="getDateConfigKey(field,'Ranges')"
        :remoteUpdateConfig= field.RemoteUpdateConfig
        :labelAlign="'left'"
        :isEditing="isEditing"
        :formPart="field.PartName"
        :errorClass="'col-sm-3'"
        :placeholderWidth="'1000px'"
        :placeholderHeight="'100px'"
        @change="onDataChange"
        v-model="localValue[field.Name]"
        v-bind="field"
        ></component>
    </div>
  </div>
</template>

<script>
import { isNotNullNorEmpty, isNullOrUndefined } from '@/utils/utils.js'
import { FormPartMixin } from '@/app/shared/mixins/FormPartMixin'

export default {
  name: 'TextAreaFormPart',
  mixins: [FormPartMixin],
  props: {
    headingText: {
      type: String
    }
  },
  computed: {
    field () {
      let field
      if (this.fieldsMappedByFormPartName.has(this.name)) {
        field = this.fieldsMappedByFormPartName.get(this.name).toArray()[0]
        if (!isNullOrUndefined(field.DefaultValue) && !isNotNullNorEmpty(this.localValue[field.Name])) {
          this.changeLocal(field.Name, field.DefaultValue)
        }
        if (!isNullOrUndefined(field.Value)) {
          this.changeLocal(field.Name, field.Value)
        }
      }
      return field
    }
  }
}

</script>
<style lang="scss" scoped>
.text-area-form-part-container {
  margin: 24px;
  background-color: $white;
  border: 1px solid $mercury;
  border-radius: 12px;
  box-sizing: unset;
}
.text-area-heading-container {
  height: 100%;
  padding: 8px;
  background: $gray;
  border: 1px solid $light-gray;
  box-sizing: border-box;
  border-radius: 12px 12px 0px 0px;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.005em;
}
.text-area-form-part{
  padding: 20px;
  text-align: center;
}
</style>
