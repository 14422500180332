import { render, staticRenderFns } from "./VerticalFormPart.vue?vue&type=template&id=6bc89126&scoped=true"
import script from "./VerticalFormPart.vue?vue&type=script&lang=js"
export * from "./VerticalFormPart.vue?vue&type=script&lang=js"
import style0 from "./VerticalFormPart.vue?vue&type=style&index=0&id=6bc89126&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bc89126",
  null
  
)

export default component.exports