<template>
<div class="stats-container">
  <div v-if="this.loading">
    <Loading
    v-for="i in 3" :key="i"
      class="col-lg-6 col-md-6 stats-card-loading grid-container"
      type="area" />
  </div>
  <div class="stats-card-group grid-container">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 dark-text"  v-for="stat in data" :key="stat.Key">
        <StatsCard
          :statsValue="stat.Value"
          :statsText="stat.Key"
        />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import StatsCard from '../components/StatsCard'
import Loading from './Loading.vue'

export default {
  name: 'StateCardGroup',
  data () {
    return {
      data: [],
      loading: true
    }
  },
  props: {
    typeOfStats: String
  },
  components: {
    StatsCard,
    Loading
  },
  async mounted () {
    this.data = await this.loadOverviewStats(this.typeOfStats)
    this.loading = false
  },
  methods: {
    ...mapActions('Overview', ['loadOverviewStats'])
  }
}

</script>
<style module>
.stats-card-group {
  display: flex !important;
  flex-wrap: wrap !important;
}
.stats-container {
  min-width: 800px !important;
}
.stats-card-loading {
  height: 75px !important;
  width: 50px  !important;
}
.grid-container {
  margin-bottom: 24px !important;
}
</style>
