<template>
  <div class="pt-3 container">
    <Slider v-if="isSliderEnabled" />
      <div v-if="isStatsEnabled">
        <h2 class="title-text dark-text"> {{ $t("_overall_statistics") }} </h2>
        <div>
          <div v-for="typeOfStat in typesOfStats" :key="typeOfStat">
            <StatsCardGroup
            :typeOfStats=typeOfStat
            />
          </div>
        </div>
      </div>
      <div v-if="isChartsEnabled" class="row charts-container">
        <div :class="'col-md-'+ 6*chart.width +' col-lg-'+ 4*chart.width +' my-3'" v-for="chart in charts" :key="chart.name">
          <div class="card chart-container">
            <div :class="['card-header pie-chart-title-text', { 'text-center': chart.width === 1 }, { 'd-flex justify-content-between' : chart.width >= 2 }]">
              {{ $t(chart.name) }}
              <div>
                <DateRangeSelector v-if="chart.chartType=='area'"/>
              </div>
            </div>
            <div :class="chart.chartType !== 'area' ? 'card-body d-flex justify-content-center' : 'card-body'">
              <AsyncChart
                :ref="chart.name"
                :url="chart.url"
                :chartType="chart.chartType"
              />
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Slider from '../components/Slider'
import DateRangeSelector from '../components/Selector'
import StatsCardGroup from '../components/StatsCardGroup'

export default {
  name: 'overview',
  async mounted () {
    await this.loadSliderImages()
    await this.loadChartsToBeDisplayed()
  },
  components: {
    AsyncChart: () => import(/* webpackChunkName: "charts" */ '../components/ChartsUI'),
    DateRangeSelector,
    Slider,
    StatsCardGroup
  },
  methods: {
    ...mapActions('Overview', ['loadStats', 'loadChartsToBeDisplayed', 'loadSliderImages', 'loadOverviewStats'])
  },
  computed: {
    ...mapState('Overview', [
      'isSliderEnabled',
      'isStatsEnabled',
      'isChartsEnabled',
      'stats',
      'typesOfStats',
      'charts'
    ])
  }
}
</script>
<style scoped>
.card-header{
  background-color: var(--form-part-color) !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}
.card-body{
  padding: 0.65rem !important;
}
.charts-container {
  flex-wrap: wrap !important;
}
.chart-container {
  height: 500px !important;
  border: 1px solid var(--very-light-input-border) !important;
  border-radius: 8px !important;
  margin: 5px 0px !important;
  background-color: var(--background-color-primary-light-dark) !important;
  color: var(--text-primary-color);
}
.pie-chart-title-text {
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-weight: 700 !important;
  font-size: 18px !important;
}
.title-text{
  font-family: 'IBM Plex Sans', sans-serif !important;
  font-weight: 700!important;
  font-size: 27px!important;
  margin-bottom: 2rem !important;
}
</style>
