import { RegistryServerClient } from '../../RegistryApi'
import { defaultToast, ToastType } from '../../../../../utils/toastUtils'
import { openDocInNewTab } from '../../../../../utils/utils'

export default {
  namespaced: true,
  state: {
    contracts: [],
    totalCount: 0,
    reportingUnitId: null,
    manualVerificationCompleted: false,
    allowContractEdit: false,
    loggedUserHierarchyLevel: 1
  },
  mutations: {
    SET_CONTRACTS (state, data) {
      state.contracts = data
    },
    SET_COUNT (state, data) {
      state.totalCount = data
    },
    SET_REPORTING_UNIT_ID (state, data) {
      state.reportingUnitId = data
    },
    SET_MANUAL_VERIFICATION_DATA (state, data) {
      state.manualVerificationCompleted = data.percentOfManualVerificationDone >= data.manualVerificationPercentRequired
      const today = new Date()
      const contractCreatedDate = new Date(data.contractCreatedAt)
      const differenceInTime = today.getTime() - contractCreatedDate.getTime()
      const differenceInDays = differenceInTime / (1000 * 3600 * 24)
      state.allowContractEdit = Math.abs(differenceInDays) <= 15
    },
    SET_SUMMARY_DATA (state, data) {
      state.loggedUserHierarchyLevel = data.hierarchyResponseData.level
    }
  },
  actions: {
    async getContractList ({ commit, dispatch }, selectedPage) {
      const url = `v1/contract/list?page=${selectedPage}&pageSize=10`
      const response = await RegistryServerClient.post(url, {})
      if (response.success) {
        commit('SET_CONTRACTS', response.data.contracts)
        commit('SET_COUNT', response.data.totalCount)
      }
    },
    setReportingUnitId ({ commit }, reportingUnitId) {
      commit('SET_REPORTING_UNIT_ID', reportingUnitId)
    },
    async fetchManualVerificationData ({ commit }, { reportingUnitId }) {
      const url = `/v1/contract/manual-verification?reportingUnitId=${reportingUnitId}`
      const response = await RegistryServerClient.get(url, null)
      if (response.success) {
        commit('SET_MANUAL_VERIFICATION_DATA', response.data)
      }
    },
    async fetchUserSummary ({ commit }) {
      const url = '/v1/users/summary'
      const response = await RegistryServerClient.get(url, null)
      if (response.success) {
        commit('SET_SUMMARY_DATA', response.data)
      }
    },
    async fetchReportingUnitIdList ({ commit }, { reportingUnitId }) {
      const url = `/v1/contract-reporting-unit/${reportingUnitId}`
      const response = await RegistryServerClient.get(url, null)
      if (response.success) {
        return response.data
      }
    },
    async fetchInvoicePdf ({ commit }, { reportingUnitId, interimInvoice, generateInvoiceIfNotExists, downloadPdf }) {
      const url = `/v1/contract/invoice/${reportingUnitId}?interimInvoice=${interimInvoice}&generateInvoiceIfNotExists=${generateInvoiceIfNotExists}`
      const response = await RegistryServerClient.get(url, null)
      var blobUrl = response.data
      if (blobUrl != null) {
        if (downloadPdf) {
          openDocInNewTab(blobUrl)
        } else {
          defaultToast(ToastType.Success, 'Invoice generated successfully')
        }
      } else {
        defaultToast(ToastType.Error, response.message)
      }
    }
  }
}
