<template>
  <v-app class="ew-carousel">
    <v-carousel light hide-delimiter-background height="auto" fade progress delimiter-icon="mdi-minus-thick">
      <Loading v-if="this.loading" id="slider-loader" type="area"/>
      <v-carousel-item v-else :key="index" v-for="(image,index) in sliderImages"
        :src="image" @click="showImg(image, index)"
        cover class="ew-carousel-item"
      ></v-carousel-item>
    </v-carousel>
    <v-dialog v-model="this.dialogOpen" persistent  @keydown.esc="close" class="img-dialog-box">
      <v-card>
        <v-card-actions>
          <v-spacer />
            <v-btn icon @click="close" class="close-btn">
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-img :src="this.imageSrc" class="viewing-img"></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from '../components/Loading'

export default {
  name: 'Slider',
  components: {
    Loading
  },
  data () {
    return {
      dialogOpen: false,
      loading: true,
      index: 0,
      imageSrc: ''
    }
  },
  async mounted () {
    await this.loadSliderImages()
    this.loading = false
  },
  computed: {
    ...mapState('Overview', ['sliderImages'
    ])
  },
  methods: {
    ...mapActions('Overview', ['loadSliderImages']),
    showImg (image, index) {
      this.imageSrc = image
      this.dialogOpen = true
      // eslint-disable-next-line no-undef
      _paq.push(['trackEvent', 'Slider Clicked', 'Click', `Click-Img-${index + 1}`])
    },
    close () {
      this.dialogOpen = false
    }
  }
}
</script>
<style lang="scss" scoped>
.img-dialog-box{
  max-width: 80vw;
}
.ew-carousel{
  margin: 0;
  max-height: 45vh;
  padding-bottom: 20px;
}
.close-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}
.viewing-img{
  display: block !important;
  padding: 0 3vw 3vw 3vw;
}
.modal-backdrop {
  opacity: 0.5 !important;
}

</style>
