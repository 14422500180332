<template>
  <div class="header-bar ufpl-md ufpr-md">
    <div class="icon ufml-md">
      <img
        :src="headerIconImage"
        alt="LOGO"
      />
    </div>
    <div class="header-items ufpt-sm ufpb-sm ufml-xl ufpl-xl">
    <div class="search-bar-wrapper">
      <HeaderSearchBar v-if="userAuthenticatedVatient" />
    </div>
    <div class="rest">
      <div v-if="windowWidth >= minWidth" class="links-container header-font">
        <a
          class="ufpl-xs ufpr-xs"
          v-for="key in Object.keys(headerLinks)"
          :key="key"
          :href="headerLinks[key]"
          >{{ $t(key) }}</a
        >
      </div>
      <div
        v-else
        class="links-container-small ufpr-md ufpl-md header-font"
      >
        <span @click="toggleShowLinks()">
          <font-awesome-icon id="linksToggler" icon="bars"  class="fa-lg"  />
        </span>

        <div v-if="showLinks" class="links-drop-down">
          <div
            class="links ufpa-xs"
            v-for="key in Object.keys(headerLinks)"
            :key="key"
          >
            <a :href="headerLinks[key]">{{ key }}</a>
          </div>
        </div>
      </div>
      <div class="action-container ufpl-sm">
        <div v-if="!userAuthenticatedVatient" class="flex-center">
          <!-- use the button componenet when it is ready -->
          <button
            class="header-login-btn ripple text-ibm-plex-sans"
            @click="onLoginClick"
          >
            Login
          </button>
        </div>
        <div v-else class="flex-center">
          <!-- <button class="header-login-btn ripple" @click="onLogoutClick">Logout</button> -->
          <div class="header-select ufpa-xs header-font" @click="toggleActonOptions()">
            <span>{{headerUserName}}</span>
            <div class="ufpl-xs"><font-awesome-icon id="hs-icon" icon="caret-down" class="fa-lg" /></div>
          </div>

          <div v-if="showActonOptions" class="action-options-drop-down">
            <div class="action-options ufpa-xs header-font" style="margin-top:5px"
            v-for="key in Object.keys(dropDownItems)"
            :key="key"
            @click="triggerActionForSelectedOption(key)">
              <span>{{key}}</span>
            </div>
            <hr class="ew-hr" />
            <div class="appearance-section">
              <h5 class="appearance-heading">{{ $t('appearance') }}</h5>
              <div class="action-options ufpa-xs header-font" style="margin-bottom:5px" @click="triggerActionForSelectedOption('light_mode')">
                <div class="theme-list-item">
                  <span>{{ $t('light_mode') }}</span>
                  <font-awesome-icon icon="check" v-if="!darkModeState && !accessibilityModeState"/>
                </div>
              </div>
              <div class="action-options ufpa-xs header-font" style="margin-bottom:5px" @click="triggerActionForSelectedOption('acc_mode')">
                <div class="theme-list-item">
                  <span>{{$t('accessibility_mode')}}</span>
                  <font-awesome-icon icon="check" v-if="accessibilityModeState"/>
                </div>
              </div>
              <div class="action-options ufpa-xs header-font" style="margin-bottom:5px" @click="triggerActionForSelectedOption('dark_mode')">
                <div class="theme-list-item">
                  <span>{{$t('dark_mode')}}</span>
                  <font-awesome-icon icon="check" v-if="darkModeState"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import HeaderSearchBar from './HeaderSearchBar.vue'

import { mapState, mapActions, mapGetters } from 'vuex'
export default {
  name: 'Header',
  components: {
    HeaderSearchBar
  },
  data () {
    return {
      windowWidth: parent.innerWidth,
      minWidth: 1024,
      showLinks: false,
      showActonOptions: false,
      authOperationInProgress: false
    }
  },
  computed: {
    ...mapState('Header', ['userAuthenticatedVatient', 'headerLinks', 'dropDownItems', 'headerUserName']),
    ...mapState(['userName']),
    ...mapGetters(['darkModeState', 'accessibilityModeState']),
    headerIconImage () {
      return this.darkModeState ? require(process.env.VUE_APP_HEADER_DARK_ICON_IMAGE) : require(process.env.VUE_APP_HEADER_ICON_IMAGE)
    }
  },
  methods: {
    ...mapActions('Header', ['getHeaderDetails']),
    ...mapActions(['logout', 'login', 'toggleDarkMode', 'toggleAccessibilityMode']),
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    toggleShowLinks () {
      this.showLinks = !this.showLinks
    },
    toggleActonOptions () {
      this.showActonOptions = !this.showActonOptions
    },
    async onLoginClick () {
      const url = await this.login()
      window.location.href = url
    },
    async onLogoutClick () {
      const url = await this.logout()
      window.location.replace(url)
    },
    async triggerActionForSelectedOption (actionValue) {
      switch (actionValue) {
        case 'Logout':
          await this.onLogoutClick()
          break
        case 'dark_mode':
          this.toggleDarkMode(true)
          break
        case 'light_mode':
          this.toggleDarkMode(false)
          break
        case 'acc_mode':
          this.toggleAccessibilityMode(true)
          break
        default:
          break
      }
      this.toggleActonOptions()
    }
  },
  async mounted () {
    await this.getHeaderDetails()
  },
  created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss" scoped>

.theme-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.appearance-heading {
  margin-left: 10px;
  color: var(--text-primary-color);
  margin-bottom: 10px;
  font-size: 12px;
}
.ew-hr {
  margin: 10px 0 10px 0;
  padding: 0;
  height: 0.5px;
  border:none;
  background-color: var(--hr-color);
}

.flex-center {
  display:flex;
  align-items:center;
}

.header-font {
  font-family: "Segoe UI", "IBM Plex Sans", sans-serif !important;
  font-size: 12px;
}
.header-items{
  display:  flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}
.header-bar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
  background-color: var(--background-color-primary-light-dark);
  box-shadow: 0px 1px 1px var(--very-light-shadow);

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  z-index: 99;
}
.icon {
  display: flex;
  align-items: center;
}
.icon > img{
    height: 40px;
    min-width: 80px;
    margin-left: -10px;
}
.search-bar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rest {
  display: flex;
  align-items: center;
}
.links-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: var(--table-row-color);
}
.links-container-small {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
}
.links-drop-down {
  position: absolute;
  right: 0;
  top: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 15rem;
  background: $white;
}
.links {
  color: var(--table-row-color);
  border: 1px solid $label;
}
.links:hover {
  font-weight: 700;
}
#linksToggler:hover {
  cursor: pointer;
}
a {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
}
a:hover {
  color: inherit;
  font-weight: 600;
  border-bottom: 1px solid var(--table-row-color);
  font-size: 13px;
}
.header-login-btn {
  padding: 0.5rem 1rem;
  border: 2px solid var(--primary-theme);
  color: var(--primary-theme);
  border-radius: 5px;
  font-weight: 700;
  text-transform: uppercase;
  box-shadow: 0px 2px 2px var(--very-light-shadow);
  font-size: 14px;
}
.header-login-btn:hover {
  background: var(--primary-theme);
  color: $white;
}

.header-login-btn:active {
  background-color: var(--primary-theme);
  transform: translateY(4px);
}

.header-select{
  min-width: 130px;
font-family: 'Segoe UI';
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
cursor: pointer;
background: var(--header-selector);
border: 1px solid var(--input-border);
box-sizing: border-box;
border-radius: 4px;
color:var(--table-row-color);
display: flex;
flex-direction: row;
flex-wrap: nowrap;
justify-content: space-between;
}
#hs-icon{
  color: $label;
}
.action-options-drop-down{
  position: absolute;
  right: 1rem;
  // overflow: hidden;
  background: var(--input-bg);
  width:220px;
  top:50px;
  right: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border:1px solid var(--very-light-shadow);
}
.action-options{
font-family: 'Segoe UI';
font-style: normal;
font-weight: 400;
line-height: 16px;
letter-spacing: 0em;
font-size: 14px;
text-align: left;
color: var(--text-primary-color);
cursor: pointer;

&:hover{
  background-color: var(--light-hover-color-with-dark-hover);
  transition: 0.2s;
}

span {
  padding: 5px;
  display: block;
}

}
</style>
