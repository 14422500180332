import Vue from 'vue'
import Vuex, { mapState } from 'vuex'
import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { ToastType, defaultToast } from '../../../../../utils/toastUtils.js'
import { isNullOrUndefined } from '../../../../shared/utils/Objects'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    patientIdsString: '',
    patientNameDictionary: {},
    current: null,
    movies: [],
    loading: false,
    reviewed: false,
    refreshingToken: false,
    timeZone: '',
    timeZoneForMoment: '',
    patientVotDetail: null
  },
  mutations: {
    UPDATE_PATIENT_IDS (state, data) {
      state.patientIdsString = data
    },
    UPDATE_PATIENT_DICT (state, data) {
      state.patientNameDictionary = data
      // console.log(data)
    },
    UPDATE_MOMENT_TZ (state, data) {
      state.timeZoneForMoment = data
    },
    UPDATE_TIMEZONE (state, data) {
      state.timeZone = data
    },
    UPDATE_CURRENT_MOVIE (state, data) {
      state.current = data
    },
    UPDATE_MOVIES (state, data) {
      state.movies = data
    },
    UPDATE_LOADING (state, data) {
      state.loading = data
    },
    UPDATE_REVIEWED (state, data) {
      state.reviewed = data
    },
    START_REFRESH_TOKEN (state) {
      state.refreshingToken = true
    },
    STOP_REFRESH_TOKEN (state) {
      state.refreshingToken = false
    },
    UPDATE_PATIENT_VOT_DATA (state, data) {
      state.patientVotDetail = data
    }
  },
  computed: {
    ...mapState(['patientId'])
  },
  actions: {
    updatePatientIds ({ commit }, patientIds) {
      commit('UPDATE_PATIENT_IDS', patientIds)
    },
    updatePatientDictionary ({ commit }, patientDictionary) {
      commit('UPDATE_PATIENT_DICT', patientDictionary)
    },
    updateCurrentMovie ({ commit }, movie) {
      commit('UPDATE_CURRENT_MOVIE', movie)
    },
    updateMovieList ({ commit, state, dispatch }, movies) {
      commit('UPDATE_MOVIES', movies)
    },
    updateReviewed ({ commit }, reviewed) {
      commit('UPDATE_REVIEWED', reviewed)
      commit('UPDATE_CURRENT_MOVIE', null)
    },
    updatePatientVotData ({ commit }, data) {
      commit('UPDATE_PATIENT_VOT_DATA', data)
    },
    async loadPatientsAndVideosFromServer ({ commit, dispatch }) {
      var url = '/v1/vot/episodes'
      var response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.success) {
        commit('UPDATE_PATIENT_IDS', response.data.episodeIdList)
        commit('UPDATE_PATIENT_DICT', JSON.parse(response.data.episodeIdToEpisodeNameMap))
        commit('UPDATE_TIMEZONE', response.data.timeZoneAbbreviation)
        commit('UPDATE_MOMENT_TZ', response.data.timeZoneDetails)
      }
      dispatch('loadVideosFromServer')
    },
    updateServerStatus ({ state }, newStatus) {
      var url = '/v1/vot/video-status'
      var newStatusString = ''
      if (newStatus === '1') {
        newStatusString = 'TAKEN'
      } else if (newStatus === '2') {
        newStatusString = 'MISSED'
      }

      // Build req body from Video ID, review status from the form and the Nikshay ID
      const reqBody = { data: { videoId: state.current.id, videoReviewStatus: newStatusString, entityId: state.current.unique_id } }

      RegistryServerClient.put(url, reqBody.data, registryErrorCallback)
    },
    async loadPatientVotData ({ commit, state, dispatch }, patientId) {
      var url = '/v1/vot/episode-details'
      var fullUrl = url + '?entityId=' + patientId
      var response = await RegistryServerClient.get(fullUrl, null, registryErrorCallback)
      commit('UPDATE_TIMEZONE', response.data.timeZoneAbb)
      commit('UPDATE_MOMENT_TZ', response.data.timeZoneDetails)
      dispatch('updatePatientVotData', response.data.episodeDetails)
    },
    async updateVideoStatus ({ commit, state, dispatch }, newStatus) {
      var url = '/v1/vot/status'
      var data = {
        new_status: newStatus,
        videoId: state.current.id
      }
      var response = await RegistryServerClient.put(url, data, function (error) {
        window.alert('failed: ' + error)
      })
      if (response.data === 'Update successful.') {
        defaultToast(ToastType.Success, response.msg, 3000)

        setTimeout(function () {
          var newMovies = state.movies
          if (!state.reviewed) {
            newMovies = state.movies.delete(state.current.id)
          } else {
            var movie = newMovies.get(state.current.id)
            var intStatus = parseInt(newStatus)
            movie.status = intStatus
            newMovies.set(state.current.id, movie)
          }
          dispatch('updateMovieList', newMovies)
        }, 3000)
        var votTabHash = '#vot'
        window.location.href = window.location.origin + window.location.pathname + votTabHash
        location.reload()
      }
    },
    async loadVideosFromServer ({ commit, state, dispatch }) {
      if (state.patientIdsString.length === 0) {
        return
      }
      var url = 'v1/vot/videos/'
      var data = {
        patient_ids: state.patientIdsString,
        unique_id_type: process.env.VUE_APP_CLIENT,
        type: state.reviewed ? 'reviewed' : 'unreviewed'
      }

      commit('UPDATE_LOADING', true)
      var newMovies = await RegistryServerClient.post(url, data, function (error) {
        defaultToast(ToastType.Error, error, 3000)
      })
      if (newMovies != null && newMovies.data != null) {
        newMovies.data.forEach(function (movie) {
          // Set empty values for videos with incomplete data;
          // TODO filter from the backend API
          if (movie.metadata == null) {
            movie.metadata = {
              recorded_string: ''
            }
          }
          // Convert the epoch time stamp to a human readable datetime
          // TODO - convert time to timezone's time if
          // country_code is available
          if (movie.metadata !== null && movie.metadata.recorded !== undefined) {
            const options = { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true, timeZone: state.timeZoneForMoment }
            // eslint-disable-next-line no-undef
            var languageCode = 'en' // getCookie('languageCode')
            var dtString = ''
            var videoUploadedDateTime = movie.uploaded_date
            var dtUploadedString = ''
            movie.videoDateTime = movie.metadata.recorded
            var videoDateTime = new Date(movie.metadata.recorded * 1000).toLocaleDateString(languageCode, options)
            dtString = `${videoDateTime} ${state.timeZone}`
            if (videoUploadedDateTime != null && videoUploadedDateTime !== undefined) {
              videoUploadedDateTime = new Date(videoUploadedDateTime * 1000).toLocaleDateString(languageCode, options)
              dtUploadedString = `${videoUploadedDateTime} ${state.timeZone}`
            }
            movie.metadata.recorded_string = dtString
            movie.recorded_string = dtString
            movie.uploaded_string = dtUploadedString === '' ? (movie.uploaded ? 'olduploadedvideo' : 'uploading') : dtUploadedString
          } else {
            movie.metadata.recorded_string = ''
            movie.uploaded_string = ''
          }
          if (movie.metadata !== null && movie.metadata.duration !== undefined) {
            var date = new Date(null)
            date.setSeconds(movie.metadata.duration)
            movie.duration = date.toISOString().substr(14, 5)
          }
          // Get the read path for each video or set to null
          if (movie.ams !== null && !isNullOrUndefined(movie.ams.read_path)) {
            movie.video_url = movie.ams.read_path.replace('//', 'https://')
          } else {
            movie.video_url = ''
          }
          // Assign the name to the movie object in the table
          var patientDetail = state.patientNameDictionary[movie.unique_id] + ' (' + movie.unique_id + ')'
          movie.patientDetail = patientDetail
        })
        // Set 'loaded' to true so the front-end shows the
        // video list instead of the loading placeholder.
        dispatch('updateMovieList', newMovies.data)
        commit('UPDATE_LOADING', false)
      } else {
        commit('UPDATE_LOADING', false)
      }
    }
  },
  modules: {
  }
}
