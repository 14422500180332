<template>
  <div class = "table-form-part">
    <div  class = "v-card__title">
      <h4 id = "table-heading" class="title text-ibm-plex-sans dark-text">{{ $t(headingText) }}</h4>
    </div>
    <hr data-v-761ae283="" class="ew-hr mlr">
    <div >
      <div class = "no-data-container dark-text" v-if='!isEditing && fieldRows != null && fieldRows.length < 1'>{{ $t("empty_table_data") }}</div>
      <table class = "table-container" v-else data-show-columns='true'>
        <thead class="table-header">
          <tr >
            <th class = "table-header-single" v-for='field in fieldRows[0]' v-bind:key='field.Name'>{{ $t(field.LabelKey) }}</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for='(row, index) in fieldRows' v-bind:key='index'>
            <td class="table-data" v-for='(field, field_index) in row' v-bind:key='field.Name'>
              <a class ="table-link ew-field-group" id="openRow" v-if='allowRowOpen && field_index == 0' @click='openRow(index)'>{{localValue[rowDataName][index][field.Name]}}</a>
              <component
                v-else-if="field.IsVisible"
                :ref="field.Name"
                :id="field.Name"
                :type="field.Type"
                :addOn="field.AddOn"
                :key="field.Key"
                :is="field.Component"
                :name="field.Name"
                :allOptions="field.OptionsWithKeyValue"
                :optionsWithKeyValue="field.OptionsWithKeyValue"
                :labelKey="field.LabelKey"
                :isDisabled="field.IsDisabled"
                :required="field.IsRequired"
                :hasError="field.hasError"
                :errorMessage="field.errorMessage"
                :andValidations="getValidations(field.Key, 'And')"
                :orValidations="getValidations(field.Key, 'Or')"
                :remoteUrl="field.RemoteUrl"
                :optionsWithLabel="field.OptionsWithLabel"
                :optionDisplayKeys="field.OptionDisplayKeys"
                :optionValueKeys="field.OptionValueKeys"
                :responseDataPath="field.ResponseDataPath"
                :placeholder="field.Placeholder"
                :loadImmediately="field.LoadImmediately"
                :to="getDateConfigKey(field,'To')"
                :from="getDateConfigKey(field,'From')"
                :days="getDateConfigKey(field,'Days')"
                :daysOfMonth="getDateConfigKey(field,'DaysOfMonth')"
                :dates="getDateConfigKey(field,'Dates')"
                :ranges="getDateConfigKey(field,'Ranges')"
                :remoteUpdateConfig= field.RemoteUpdateConfig
                :isMultiSelect= "field.Component === 'app-multiselect'"
                :labelAlign="'left'"
                :showLabel="false"
                :fieldWidth="'col-sm-12'"
                :isEditing="isEditing"
                :formPart="field.PartName"
                :errorClass="'col-sm-12'"
                :keyIndex="index"
                :orientation="'v'"
                @change="onDataChange"
                v-model="localValue[rowDataName][index][field.Name]"
                v-bind="field"
                :hasInfo="field.HasInfo"
                :infoMessage="field.InfoText"
              ></component>
            </td>
            <td class="align-to-top">
              <button id="removeRow" v-if='isEditing && fieldRows.length > 1' @click="removeRow(index)"> <span class="delete-icon"></span></button>
              <button :disabled="isDisabled"  id="addRow"  v-if='isEditing && (index === lastRowIndex)' @click="addRow(index)"> <span class="add-icon"></span></button>
            </td>
            <td>
              <Button id="deleteRow" v-if='allowRowDelete' @click="returnRow(index)" :label="$t(`Return`)"> </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import { FormPartMixin, FieldComponentMap } from '../mixins/FormPartMixin.js'
import { List } from 'immutable'
import { isNotNullNorEmpty, isNullOrUndefined } from '../../../utils/utils.js'
import EventBus from '@/app/shared/event-bus.js'
import { mapActions } from 'vuex'
import Button from '../components/Button.vue'

export default {
  name: 'TableFormPart',
  components: {
    Button
  },
  mixins: [FormPartMixin, FieldComponentMap],
  props: {
    headingText: {
      type: String
    }
  },
  mounted () {
    EventBus.$emit('TABLE_ROW_LENGTH', this.fieldRows?.length)
  },
  created () {
    EventBus.$on('DELETED_ROW_AT', this.handleRowDeleted)
  },
  computed: {
    isDisabled () {
      let isRequiredFieldDataPresent = true
      var fieldList = this.allFields.filter(af => af.PartName === this.name)
      const rowLocalValue = isNullOrUndefined(this.localValue) ? null : this.localValue[this.rowDataName][this.lastRowIndex]
      const partFields = this.fieldsMappedByFormPartName.get(this.name)
      if (!isNullOrUndefined(rowLocalValue)) {
        for (const f of fieldList) {
          const field = partFields.find(pf => pf.Key === `${f.PartName}${f.Name}${this.lastRowIndex}`)
          if (!isNullOrUndefined(field) && field.IsRequired === true && field.IsVisible === true && !isNotNullNorEmpty(rowLocalValue[field.Name])) {
            isRequiredFieldDataPresent = false
            break
          }
        }
      }
      return !isRequiredFieldDataPresent
    },
    fields () {
      var configFields = this.allFields.filter(af => af.PartName === this.name)
      var visibleFields = []
      if (isNullOrUndefined(this.fieldsMappedByFormPartName) || isNullOrUndefined(this.fieldsMappedByFormPartName.get(this.name))) {
        return visibleFields
      }
      var partFields = this.fieldsMappedByFormPartName.get(this.name)
      configFields.forEach((f, index) => {
        var isVisible = false
        partFields.filter(pf => pf.Key.startsWith(f.PartName + f.Name)).forEach(pf => {
          if (pf.IsVisible) {
            isVisible = true
          }
        })
        if (isVisible) {
          visibleFields.push(f)
        }
      })
      visibleFields = List(visibleFields).sortBy(vf => vf.ColumnNumber).toArray()
      return visibleFields
    },
    fieldRows () {
      if (isNullOrUndefined(this.fieldsMappedByFormPartName) || isNullOrUndefined(this.fieldsMappedByFormPartName.get(this.name))) {
        return []
      }
      var numRows = 0
      if (this.localValue[this.rowDataName]) {
        numRows = this.localValue[this.rowDataName]?.length
      }

      const allTableFields = this.fieldsMappedByFormPartName.get(this.name).toArray()
      var rows = []
      for (var i = 0; i < numRows; i++) {
        var fields = allTableFields.filter(atf => atf.Key.endsWith(i))
        fields = fields.filter(f => {
          var firstNumberRegEx = /\d+$/
          var firstNumberIndex = f.Key.search(firstNumberRegEx)
          var fieldKey = f.Key.substring(firstNumberIndex, f.Key.length)
          return i === parseInt(fieldKey)
        })
        const ordered = List(fields)
          .filter(f => this.fields.map(k => k.Name).includes(f.Name))
          .sortBy(f => f.ColumnNumber)
          .toArray()
        rows.push(ordered)
        for (const f of fields) {
          if (!isNullOrUndefined(f.DefaultValue) && !isNotNullNorEmpty(this.localValue[this.rowDataName][i][f.Name])) {
            this.changeLocal(i, f.Name, f.DefaultValue)
          }
          if (!isNullOrUndefined(f.Value)) {
            this.changeLocal(i, f.Name, f.Value)
          }
        }
      }
      return rows
    },
    lastRowIndex () {
      return this.localValue[this.rowDataName]?.length - 1
    }
  },
  methods: {
    changeLocal (index, field, value) {
      this.localValue[this.rowDataName][index][field] = value
    },
    addRow (index) {
      var newRow = {}
      var newFields = []
      var fieldList = this.allFields.filter(af => af.PartName === this.name)
      for (const f of fieldList) {
        if (!isNullOrUndefined(f.DefaultValue)) {
          newRow[f.Name] = f.DefaultValue
        }
        var newf = JSON.parse(JSON.stringify(f))
        newf.Key = f.Key.substring(0, f.Key?.length - 1) + this.fieldRows?.length
        newFields.push(newf)
      }
      this.localValue[this.rowDataName].push(newRow)
      this.addFields(newFields)
      for (const f of newFields) {
        if (!isNullOrUndefined(f.RemoteUpdateConfig)) {
          f.RemoteUpdateConfig.forEach(config => {
            EventBus.$emit('CENTRAL_REMOTE_DATA_UPDATE', config, index + 1)
          })
        }
      }
      this.onRemoteLoad()
      EventBus.$emit('TABLE_ROW_ADDED')
    },
    removeRow (index) {
      this.localValue[this.rowDataName].splice(index, 1)
      const allTableFields = this.fieldsMappedByFormPartName.get(this.name).toArray()
      var removeFields = allTableFields.filter(atf => atf.Key.endsWith(index))
      this.removeFields({ fields: removeFields, formPart: this.name, index: index })
      EventBus.$emit('TABLE_ROW_REMOVED')
    },
    deleteRow (index) {
      EventBus.$emit('TABLE_ROW_DELETED', { listName: this.name, rowData: this.localValue[this.rowDataName][index], index: index })
    },
    returnRow (index) {
      EventBus.$emit('TABLE_ROW_RETURN', { listName: this.name, rowData: this.localValue[this.rowDataName][index], index: index })
    },
    openRow (index) {
      this.setExistingData({ rowsName: this.rowDataName, index: index })
      EventBus.$emit('TABLE_ROW_OPENED', this.name)
    },
    handleRowDeleted (data) {
      if (data.listName === this.name) {
        this.localValue[this.rowDataName].splice(data.index, 1)
      }
    },
    ...mapActions('Form', ['setExistingData', 'addFields', 'removeFields', 'onRemoteDataUpdate'])
  }
}
</script>
<style lang="scss" scoped>
.table-form-part {
  position: relative;
  margin: 15px 0px 12px 0px;
  background-color: var(--background-color-primary-light-dark);
  border-radius: 12px;
  box-sizing: unset;
}
.no-data-container {
   margin: 16px;
   padding: 16px;
   font-size: 18px;
}
.table-container {
  border-collapse: collapse;
  margin-top: 16px;
  box-sizing: unset;
  table-layout: fixed;
  width: 120%;
}
.table-header{
  background-color: var(--ghost-white) !important;
  color: var(--text-primary-color);
}
.table-header-single {
  padding: 0px 16px 0px 16px !important;
  text-align: left !important;
  font-size: 13px !important;
}
.table-body::before{
  content: '';
  display: block;
  height: 15px;
}
.table-data {
  padding: 0px 16px 0px 16px !important;
  overflow-x: visible;
  vertical-align: top !important;
}
.table-link {
  color: var(--primary-theme);
  font-weight: bold;
}
.table-link:hover {
  color: $borderGray;
}
span.add-icon {
  background: url("~@/assets/icons8-plus_+.svg") no-repeat;
  float: left;
  height: 50px;
  width: 40px;
}
span.delete-icon {
  background: url("~@/assets/icons8-minus.svg") no-repeat;
  float: left;
  height: 50px;
  width: 40px;
  margin-top: 0px !important;
}

button:disabled {
  filter: grayscale(70) contrast(1) invert(1);
}
.align-to-top {
  vertical-align: top !important;
}

</style>
