import { RegistryServerClient, registryErrorCallback } from '../../../store/RegistryApi.js'
import { toastError } from '@/utils/utils'

export default {
  namespaced: true,
  state: {
    isSliderEnabled: null,
    isStatsEnabled: null,
    isChartsEnabled: null,
    stats: [],
    charts: [],
    sliderImages: [],
    typesOfStats: []
  },
  mutations: {
    SET_STATS (state, data) {
      state.stats = data
    },
    SET_CHARTS_TO_BE_DISPLAYED (state, data) {
      state.charts = data
    },
    SET_SLIDER_IMAGES (state, data) {
      if (data?.length > 0) {
        state.sliderImages = data.sort()
      } else {
        state.isSliderEnabled = false
      }
    },
    UPDATE_OVERVIEW_CONFIG (state, data) {
      state.isSliderEnabled = data.overviewConfig.slider
      state.isStatsEnabled = data.overviewConfig.overallstats
      state.isChartsEnabled = data.overviewConfig.charts
      state.typesOfStats = data.overviewConfigTypes.typesofstats
    }
  },
  actions: {
    async updateOverviewConfig ({ commit }) {
      var url = '/v1/overview/config'
      try {
        const response = await RegistryServerClient.get(url, null, registryErrorCallback)
        if (response.success) {
          return commit('UPDATE_OVERVIEW_CONFIG', response.data)
        } else {
          // eslint-disable-next-line no-undef
          toastError('Unable to load Overview config')
        }
      } catch (e) {
        registryErrorCallback(e)
      }
    },
    async loadOverviewStats ({ commit, state }, typeOfStat) {
      var url = '/v1/overview/' + typeOfStat
      try {
        const response = await RegistryServerClient.get(url, null, registryErrorCallback)
        if (response.success) {
          return response.data
        } else {
          // eslint-disable-next-line no-undef
          toastError('Unable to load Stats')
        }
      } catch (e) {
        registryErrorCallback(e)
      }
    },
    async loadChart ({ commit, state }, url) {
      if (state.isChartsEnabled) {
        try {
          const response = await RegistryServerClient.get(url, null, registryErrorCallback)
          if (response.success) {
            return response.data
          } else {
            // eslint-disable-next-line no-undef
            toastError('Unable to load Chart Data')
          }
        } catch (e) {
          registryErrorCallback(e)
        }
      }
    },
    async loadChartsToBeDisplayed ({ commit, state }) {
      if (state.isChartsEnabled) {
        const url = '/v1/overview/charts/config'
        try {
          const response = await RegistryServerClient.get(url, null, registryErrorCallback)
          if (response.success) {
            commit('SET_CHARTS_TO_BE_DISPLAYED', response.data)
          } else {
            // eslint-disable-next-line no-undef
            toastError('Unable to load Charts')
          }
        } catch (e) {
          registryErrorCallback(e)
        }
      }
    },
    async loadSliderImages ({ commit, state }) {
      if (state.isSliderEnabled) {
        const url = '/v1/overview/slider-images'
        try {
          const response = await RegistryServerClient.get(url, null, registryErrorCallback)
          if (response.success) {
            return commit('SET_SLIDER_IMAGES', response.data)
          } else {
            // eslint-disable-next-line no-undef
            toastError('Unable to load Slider images')
          }
        } catch (e) {
          registryErrorCallback(e)
        }
      }
    }
  }
}
