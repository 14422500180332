<template>
        <div id="patientSearchBar" class="header-font">
            <div id="patientSearchCriteria" class="ufpl-xs ufpr-xs">
                <div id="displayPatientSearchCriteria" class="searchCriteriaBtn" @click="togglePatientSearchCriteriaDropdown">
                    <span class="ufpr-xs dark-text">{{selectedSearchCriteriaDisplayValue}}</span>
                    <span>
                        <font-awesome-icon icon="caret-down" class="fa-lg" />
                    </span>
                </div>
                <div v-if="showPatientSearchCriteriaDropdown" id="patientSearchCriteriaDropdown">
                  <div :class="['radio-container','ufpa-xs']">
                        <RadioGroup class="ufpl-xs"
                        :label="''"
                         v-model="selectedSearchCriteria"
                         :allOptions="searchOptions"
                         :name="'searchOptions'"
                         :onClick="togglePatientSearchCriteriaDropdown"/>
                  </div>
                </div>
            </div>

            <div id="search">
                <div id="displaySearch">
                    <input id="patientSearchBarInput" type="text" :placeholder="$t(`header_search_patients`)" v-model="searchValue">
                    <span class="searchBtn ufpa-xs" @click="findPatients">
                        <font-awesome-icon icon="search" class="fa-lg"/>
                    </span>
                </div>
                <v-scroll-y-transition>
                  <div v-if="showSearchPatientDropdown" id="searchPatientDropdown">
                    <div v-if="searchPatientsList.length != 0">
                      <ul class="searchDropDownList">
                        <li v-for="patient in searchPatientsList"
                            :key="patient.name"
                            class="searchPatient"
                            @click="routeToPatient(patient.id)" >
                          <div class="searchPatientName">
                            {{ patient.stageData.firstName }} {{patient.stageData.lastName}}
                          </div>
                          <div class="searchPatientId1">
                            {{$t("Patient Id")}} -
                            <p4 class="searchPatientId2">
                              {{patient.id}}
                            </p4>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div v-else class="searchDropdownNoResult">
                      <font-awesome-icon icon="search" class="fa-2x"/>
                      <div class="searchPatientDropNoPatient1"> {{$t("no_results_found")}} </div>
                      <div class="searchPatientDropNoPatient2">  {{$t("recheck_header_search_text")}} </div>
                    </div>
                  </div>
                </v-scroll-y-transition>
            </div>

        </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RadioGroup from '../RadioGroup.vue'
export default {
  name: 'HeaderSearchBar',
  components: {
    RadioGroup
  },
  data () {
    return {
      selectedSearchCriteria: '',
      showPatientSearchCriteriaDropdown: false,
      showSearchPatientDropdown: false,
      searchOptions: [],
      searchValue: ''
    }
  },
  computed: {
    ...mapState('Header', ['searchCriteria', 'searchPatientsList']),
    selectedSearchCriteriaDisplayValue () {
      return this.$t(this.searchCriteria[this.selectedSearchCriteria])
    }
  },
  methods: {
    ...mapActions('Header', ['searchPatients']),
    togglePatientSearchCriteriaDropdown (e) {
      this.showPatientSearchCriteriaDropdown = !this.showPatientSearchCriteriaDropdown
      this.showSearchPatientDropdown = false
    },
    toggleSearchPatientDropdown () {
      this.showSearchPatientDropdown = !this.showSearchPatientDropdown
      this.showPatientSearchCriteriaDropdown = false
    },
    async findPatients () {
      if (this.searchValue.trim() !== '') {
        const data = { searchKey: this.selectedSearchCriteria, searchValue: this.searchValue }
        await this.searchPatients(data)
        this.showSearchPatientDropdown = true
        this.showPatientSearchCriteriaDropdown = false
        // TODO : Custom error handling
      }
    },
    setSearchCritera (key) {
      this.selectedSearchCriteria = key
    },
    routeToPatient (patientId) {
      const url = '/dashboard/patient/' + patientId
      window.location.href = url
    }
  },
  async mounted () {
    const key = Object.keys(this.searchCriteria)[0]
    this.setSearchCritera(key)
    const val = Object.keys(this.searchCriteria).reduce((acc, key) => {
      const obj = {}
      obj.Value = this.$t(this.searchCriteria[key])
      obj.Key = key
      return [...acc, { ...obj }]
    }, [])
    this.searchOptions = val
  }
}
</script>

<style lang="scss" scoped>
#patientSearchBarInput{
  position: relative;
  left: 10px;
  caret-color: var(--day-select-color);
  color: var(--day-select-color);
}
#patientSearchBarInput::placeholder{
  font-weight: 500;
  color: var(--place-holder-text);
}
#patientSearchBar{
  display: flex;
  flex-direction: row;
  border: 1px solid var(--input-border);
  border-radius: 4px;
}
#displayPatientSearchCriteria{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-width: 5rem;
  font-weight: 400;
}
#displaySearch{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}
#search{
  position: relative;
}
#patientSearchCriteria{
  position: relative;
  padding: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background: var(--input-disabled-bg);
  border-radius:3px;
}
input{
  border: none;
  height: 24px;
}
input:focus{
  outline: none;
}

#searchPatientDropdown{
  background: var(--background-color-primary-light-dark);
  position: absolute;
  top: 38px;
  left: -98px;
  width: 155%;
  overflow: hidden;
  border: 2px solid var(--very-light-shadow);
  border-radius: 4px;
  word-wrap: break-word;
}
#patientSearchCriteriaDropdown{
  position: absolute;
  left: -2px;
  top:118%;
  min-height: 5rem;
  width: 10rem;
  overflow: hidden;
  border: 1px solid var(--very-light-shadow);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.searchCriteriaBtn{
  cursor: pointer;
  color: $label;
}
.searchBtn{
  cursor: pointer;
  margin-left: 10px;
}
.radio-container{
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: var(--background-color-primary-light-dark);
}
.searchDropdownNoResult {
  padding-top: 10px;
  width: 200px;
  margin: auto;
  text-align: center;
}
.searchPatientDropNoPatient1 {
  padding-top: 5px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.004em;
  color: $label;
}
.searchPatientDropNoPatient2 {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.004em;
  color: $placeholderText;
}
.searchPatient {
  padding:5px 10px 5px 10px;
  cursor: pointer;
  border-bottom: 0.4px solid $placeholderText;
}

.searchPatient:last-child {
  border-bottom: none;
}

.searchPatient:hover {
  background-color: var(--input-disabled-bg);
  transition: 0.1s linear;
}

.searchDropDownList {
  list-style-type: none;
}
.searchPatientName {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.005em;
  color: var(--primary-theme);
}
.searchPatientId1 {
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.015em;
  padding-bottom: 2px;
  font-weight: 500;
  color: $placeholderText;
}
.searchPatientId2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.004em;
  color: var(--text-primary-color);
}
.fa-lg {
  color: var(--text-table-data);
}
</style>
