<template>
  <div :class="['ew-field-group', 'ain', {'ew-field-group-vertical': orientation === 'v'}]">
    <label v-if="isShowLabel()" for="c-input" :class="[
      'ew-field-label', 'text-align-right', 'flex1', 'label-weight', 'ew-label-weight',
      {'text-align-left': orientation === 'v'},
      {'error': hasError}]">{{ $t(label) }}</label>
      <div :class="['flex1', {'flex': orientation === 'v'}]">
        <div v-if="isEditing" >
          <textarea
            :name=name
            :class=getInputClass()
            :placeholder=placeholderText
            :label=label
            @change="onDataChange"
            @keyup="onDataChange"
            @click="onDataChange"
            :disabled="isDisabled"
            v-model="localValue"
            :style="{height: placeholderHeight}">
          </textarea>
        </div>
        <div v-else-if="localValue">
          <label  :class="[ 'ew-field-label', 'text-align-right', 'flex1', 'label-weight', {'text-align-left': orientation === 'v'}]">{{ localValue }}</label>
        </div>
        <div v-if="checkHasError" id="textAreaError" class="error-container"><p class="error-message">{{ $t(showErrorMessage) }}</p></div>
        <div v-if="hasInfo && !checkHasError" id="textAreaInfo" class="text-info">{{ $t(infoMessage) }}</div>
      </div>
  </div>
</template>

<script>
import { ComponentMixin } from '@/app/shared/mixins/ComponentMixin'
export default {
  mixins: [ComponentMixin],
  data () {
    return {
      localValue: '',
      isRequiredErrorMsg: 'error_field_required'
    }
  },
  name: 'Textarea',
  props: {
    name: {
      type: String
    },
    value: {
      type: String
    },
    label: {
      type: String,
      default: 'Label'
    },
    // isDisabled: {
    //   type: Boolean,
    //   default: false
    // },
    placeholderText: {
      type: String
    },
    // isRequired: {
    //   type: Boolean,
    //   default: false
    // },
    placeholderWidth: {
      type: String,
      default: '320px'
    },
    placeholderHeight: {
      type: String,
      default: '100px'
    },
    orientation: {
      type: String,
      default: 'h'
    }
  },
  mounted () {
    this.localValue = this.value
  },
  computed: {
    checkHasError: {
      get: function () {
        if (this.hasError || (this.isRequired && !this.localValue)) {
          return true
        }
        return false
      },
      set: function (value) {
        return value
      }
    },
    showErrorMessage: {
      get: function () {
        if (this.hasError) {
          return this.errorMessage
        }
        let errorMessage = ''
        if (this.isRequired && !this.localValue) {
          errorMessage = this.isRequiredErrorMsg
        }
        return errorMessage
      },
      set: function (value) {
        return value
      }
    }
  },
  watch: {
    // localValue () {
    //   if (!this.localValue && this.isRequired) {
    //     this.hasError = true
    //     this.errorMessage = this.isRequiredErrorMsg
    //   } else {
    //     this.hasError = false
    //   }
    // }
  },
  methods: {
    getInputClass () {
      return {
        'c-input': true,
        'error-input': this.hasError
      }
    },
    onDataChange () {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
      this.$emit('click', this.localValue)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
textarea {
  resize: none;
}

.flex{
  justify-content: inherit;
  flex-direction: inherit;
  align-items: inherit;
}

.c-input {
  padding: 11px 16px;
  background: var(--input-bg);
  border: 1px solid $borderGray;
  line-height: 26px;
  border-radius: 8px;
  color: var(--text-primary-color);
}

.c-input::placeholder {
  color: $placeholderText;
}

.c-input:hover {
  border : none;
  border: 0.6px solid $info;
}

.c-input:focus {
  border: none;
  outline: none;
  border: 1px solid $info;
}

.c-input-label{
  display: flex;
  text-align: initial;
  width: 300px;
}

.c-input:disabled {
  background: var(--input-disabled-bg);
  border: none;
}

.label-weight{
  height: 22px;
  font-weight: 400;
}

.c-input:disabled + .c-input-label {
  color: $placeholderText;
}

.error-container{
  margin-top: 2px;
}

.text-info{
  margin-top: 2px;
  color: #0070D9;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
}

.error-message{
  margin: 0px;
  color: $error;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
}

.error-input{
  border: 1px solid $error !important;
  color: $error;
}

.c-input {
  min-width: 234px;
  width: calc(100% - 32px) !important;
}

</style>
