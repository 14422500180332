<template>
  <div :class="['ew-field-group', 'ain', {'ew-field-group-vertical': orientation === 'v'}]">
    <label v-if="label !== ''" :class="[
      'ew-field-label', 'text-align-right', 'flex1', 'ew-label-weight',
      {'text-align-left': orientation === 'v'},
      {'error': hasError}]">{{ $t(label) }}:</label>
    <div :class="['flex1', {'flex': orientation === 'v'}, {'options': orientation === 'v'}]">
      <div
      v-for="option in allOptions"
      :key="option.Key"
      class="text-ibm-plex-sans-14"
      >
          <input
              type="radio"
              class="c-radio-button"
              :id="'cRadio' + name + option.Value"
              :required="required"
              :name="name"
              :value="option.Key"
              :disabled="isDisabled"
              v-model="localValue"
              @change="onDataChange"
          />
          <label class="text-ibm-plex-sans-14 option-label-weight" :for="'cRadio' + name + option.Value">
              {{ $t(option.Value) }}
          </label>
      <br>
      <div class="ufmt-vxs text-ibm-plex-sans-12 ufpb-xs  dark-text" v-if="multiLineLabel">{{option.LabelExtraInfo}}</div>
      </div>
      <div v-if="hasError" id="radioGroupError" class="error-container"><p class="error-message">{{ $t(errorMessage) }}</p></div>
      <div v-if="hasInfo && !hasError" id="radioGroupInfo" class="text-info">{{ $t(infoMessage) }}</div>
    </div>
  </div>
</template>

<script>
import { ComponentMixin } from '@/app/shared/mixins/ComponentMixin'
export default {
  mixins: [ComponentMixin],
  data () {
    return {
      localValue: null,
      labelDefault: 'Label'
    }
  },
  props: {
    // value: {
    //   default: null
    // },
    allOptions: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Label'
    },
    // isDisabled: {
    //   type: Boolean,
    //   default: false
    // },
    name: {
      type: String,
      default: 'Name'
    },
    // isRequired: {
    //   type: Boolean,
    //   default: false
    // },
    onClick: {
      type: Function,
      default: () => {},
      isRequired: false
    },
    // required: {
    //   type: Boolean,
    //   default: false
    // },
    orientation: {
      type: String,
      default: 'h'
    },
    multiLineLabel: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.localValue = this.value
    this.applyValidation()
  },
  watch: {
    value (val) {
      this.localValue = val
    }
  },
  methods: {
    onDataChange (e) {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
      this.onClick(e)
    }
  }
}
</script>

<style lang="scss" scoped>

#label-div {
  width: 100%;
}

.label-weight {
  font-weight: 600;
}

.option-label-weight {
  font-weight: 600;
  color: var(--text-primary-color) !important;
}

.text-ibm-plex-sans-14 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
}

.text-info{
  margin-top: 2px;
  color: #0070D9;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.error-container{
  margin-top: 2px;
}

.error-message{
  margin: 0px;
  color: #EF485C;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.flex{
    display: flex;
    width: 100%;
    justify-content: inherit;
    flex-direction: inherit;
    align-items: inherit;
}
.options{
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: inherit;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
label{
    margin-right: 28px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 22px;
    display: inline-block;
    color: $text;
}
[type="radio"]:disabled + label
{
    color: $placeholderText;
    cursor:default;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: 14px;
    height: 14px;
    border: 2px solid var(--primary-theme);
    border-radius: 100%;
    background: none;
    box-sizing: unset;
}
[type="radio"]:not(:checked) + label:before {
    border: 2.5px solid $placeholderText;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 9px;
    height: 9px;
    background: var(--primary-theme);
    position: absolute;
    top: 6.5px;
    left: 6.5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after{
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

[type="radio"]:hover + label:after {
    background: var(--primary-theme);
    box-shadow: 0px 0px 6px var(--primary-theme);
}
[type="radio"]:hover + label:before {
    border: 2px solid var(--primary-theme);
    box-shadow: 0px 0px 6px var(--primary-theme);
}
[type="radio"]:disabled + label:before
{
  border: 2px solid var(--input-border);
  background: var(--input-disabled-bg);
  box-shadow: none;
  cursor: not-allowed;
}
</style>
