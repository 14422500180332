<template>
  <div v-if="type=='donut'" class="d-flex flex-column align-items-center loading-container">
    <div class="circle loader mb-40 mt-20"></div>
    <div class="rect loader mb-15"></div>
    <div class="rect loader"></div>
  </div>
  <div v-else-if="type=='area'" class="loading-container">
    <div class="full-area loader"></div>
  </div>
</template>

<script>
export default {
  name: 'loading',
  props: ['type']
}
</script>
<style scoped>
.loading-container {
  width: 100% !important;
  height: inherit;
}

.mt-20 {
  margin-top: 20px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}
.mb-15 {
  margin-bottom: 20px !important;
}

.circle {
  width: 172px !important;
  height: 172px !important;
  border-radius: 50% !important;
}

.rect {
  height: 22px !important;
  width: 100% !important;
}

.full-area {
  height: inherit !important;
  width: 100% !important;
}

.loader {
  background: linear-gradient(90deg, #DDDDDD, #f8f9fa, #DDDDDD, #f8f9fa) !important;
  background-size: 400% 400% !important;
  -webkit-animation: Gradient 1.5s ease infinite !important;
  -moz-animation: Gradient 1.5s ease infinite !important;
  animation: Gradient 1.5s ease infinite !important;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 100% 0%
  }
  50% {
    background-position: 50% 100%
  }
  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 100% 0%
  }
  50% {
    background-position: 50% 100%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes Gradient {
  0% {
    background-position: 100% 0%
  }
  50% {
    background-position: 50% 100%
  }
  100% {
    background-position: 0% 50%
  }
}
</style>
