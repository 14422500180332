import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { defaultToast, ToastType } from '../../../../../utils/toastUtils'

export default {
  namespaced: true,
  state: {
    staffDetailsLoading: true,
    currentHierarchyId: '',
    currentHierarchyName: '',
    currentHierarchyType: '',
    currentHierarchyDisplayType: '',
    currentHierarchyHaveFieldStaff: '',
    subHierarchyType: '',
    subHierarchyDisplayTypePlural: '',
    typeSpecificMessage: '',
    isViewOnly: false,
    canHierarchyHaveStaffPopulated: false,
    breadcrumbs: [],
    staffColumnsData: [],
    staffColumns: [],
    hierarchyColumnsData: [],
    hierarchyColumns: []
  },
  mutations: {
    SET_STAFF_DETAILS_LOADING_STATE (state, status) {
      state.staffDetailsLoading = status
    },
    SET_CURRENT_HIERARCHY_ID (state, id) {
      state.currentHierarchyId = id
    },
    SET_CURRENT_HIERARCHY_NAME (state, name) {
      state.currentHierarchyName = name
    },
    SET_CURRENT_HIERARCHY_TYPE (state, type) {
      state.currentHierarchyType = type
    },
    SET_CURRENT_HIERARCHY_DISPLAY_TYPE (state, value) {
      state.currentHierarchyDisplayType = value
    },
    SET_SUB_HIERARCHY_TYPE (state, name) {
      state.subHierarchyType = name
    },
    SET_SUB_HIERARCHY_DISPLAY_TYPE_PLURAL (state, type) {
      state.subHierarchyDisplayTypePlural = type
    },
    SET_TYPE_SPECIFIC_MESSAGE (state, value) {
      state.typeSpecificMessage = value
    },
    SET_IS_VIEW_ONLY (state, value) {
      state.isViewOnly = value
    },
    SET_CAN_HAVE_STAFF_POPULATED (state, value) {
      state.canHierarchyHaveStaffPopulated = value
    },
    SET_BREADCRUMBS (state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs
    },
    SET_STAFF_COLUMNS (state, value) {
      state.staffColumns = value
    },
    SET_STAFF_COLUMNS_DATA (state, value) {
      state.staffColumnsData = value
    },
    SET_HIERARCHY_COLUMNS (state, value) {
      state.hierarchyColumns = value
    },
    SET_HIERARCHY_COLUMNS_DATA (state, value) {
      state.hierarchyColumnsData = value
    }
  },
  actions: {
    async loadViewParams ({ commit, state }) {
      try {
        const pageUrl = window.location.href
        let urlPattern = /(.*)\/dashboard\/StaffDetails(\/)?(\d+)?/

        if (pageUrl.match(urlPattern)) {
          const matches = pageUrl.match(urlPattern)
          if (matches.length === 4) {
            state.currentHierarchyId = matches[3]
          }
        } else {
          urlPattern = /(.*)\/dashboard\/AddStaff(\/)?(\d+)?/
          const matches = pageUrl.match(urlPattern)
          if (matches.length === 4) {
            state.currentHierarchyId = matches[3]
          }
        }
      } catch (e) {
      }
      let url = '/v1/staff/unified/view'
      if (state.currentHierarchyId) {
        url = url + '?hierarchyId=' + state.currentHierarchyId
      }
      commit('SET_STAFF_DETAILS_LOADING_STATE', true)
      try {
        const viewParams = await RegistryServerClient.get(url, null, registryErrorCallback)
        const breadcrumbs = viewParams.data.breadcrumbs.map(key => ({
          label: key.second, link: '/dashboard/StaffDetails/' + key.first, class: state.currentHierarchyId === '' + key.first ? 'active' : ''
        }))
        commit('SET_CURRENT_HIERARCHY_ID', viewParams.data.currentHierarchyId)
        commit('SET_CURRENT_HIERARCHY_NAME', viewParams.data.currentHierarchyName)
        commit('SET_CURRENT_HIERARCHY_TYPE', viewParams.data.currentHierarchyType)
        commit('SET_CURRENT_HIERARCHY_DISPLAY_TYPE', viewParams.data.currentHierarchyDisplayType)
        commit('SET_SUB_HIERARCHY_TYPE', viewParams.data.childHierarchyType)
        commit('SET_SUB_HIERARCHY_DISPLAY_TYPE_PLURAL', viewParams.data.childHierarchyDisplayTypePlural)
        commit('SET_TYPE_SPECIFIC_MESSAGE', viewParams.data.typeSpecificMessage)
        commit('SET_IS_VIEW_ONLY', viewParams.data.userPermissions.viewOnly)
        commit('SET_CAN_HAVE_STAFF_POPULATED', viewParams.data.fieldStaffSupported)
        commit('SET_BREADCRUMBS', breadcrumbs)
        if (viewParams.data.staffDisplayTable) {
          commit('SET_STAFF_COLUMNS', viewParams.data.staffDisplayTable.headers)
          commit('SET_STAFF_COLUMNS_DATA', viewParams.data.staffDisplayTable.dataRows)
        }
        if (viewParams.data.hierarchyDisplayTable) {
          commit('SET_HIERARCHY_COLUMNS', viewParams.data.hierarchyDisplayTable.headers)
          commit('SET_HIERARCHY_COLUMNS_DATA', viewParams.data.hierarchyDisplayTable.dataRows)
        }
        commit('SET_STAFF_DETAILS_LOADING_STATE', false)
      } catch (e) {
        defaultToast(ToastType.Error, 'Something went wrong. Please try again later.')
      }
    }
  }
}
