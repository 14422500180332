<template>
  <div class="ovpWrapper">
    <OverviewPage v-if="loaded" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import OverviewPage from '@/app/Pages/dashboard/Overview/components/OverviewPage'
export default {
  name: 'Overview',
  metaInfo: {
    title: 'Overview'
  },
  components: {
    OverviewPage
  },
  data () {
    return {
      loaded: false
    }
  },
  async mounted () {
    await this.updateOverviewConfig()
    this.loaded = true
  },
  methods: {
    ...mapActions('Overview', ['updateOverviewConfig'])
  }
}
</script>
<style lang="scss" scoped>
.ovpWrapper {
  background: var(--background-bg-color);
  min-width: 50%;
  padding: 31px 80px 31px 80px !important;
  margin-top:0px !important;
  width: 100%!important;
}
.ovpWrapper::v-deep {
  @import "~bootstrap/dist/css/bootstrap.min";
}
.toast-error {
  background-color: rgb(218, 53, 53) !important;
  font-size: 14px !important;
}
</style>
