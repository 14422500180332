import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { defaultToast, ToastType } from '../../../../../utils/toastUtils'

export default {
  namespaced: true,
  actions: {
    async getSupportActionTabViewDetails ({ commit, state }, patientId) {
      const url = 'v1/forms/parent-config/?formName=SupportActions&idName=PATIENT&id=' + patientId
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.Success) {
        return response
      } else {
        defaultToast(ToastType.Error, 'Unable to load  Details')
      }
    }
  }
}
