import { RegistryServerClient } from '../../RegistryApi'
const HeaderVariants = {
  LoggedIn: true,
  LoggedOut: false
}

export default {
  namespaced: true,
  state: {
    userAuthenticatedVatient: HeaderVariants.LoggedOut,
    headerLinks: {},
    searchCriteria: {},
    dropDownItems: {},
    searchPatientsList: [],
    headerUserName: ''
  },
  mutations: {
    UPDATE_HEADER_TYPE (state, payload) {
      state.userAuthenticatedVatient = payload
    },
    SET_HEADER_LINKS (state, payload) {
      state.headerLinks = payload
    },
    SET_SEARCH_CRITERIA (state, payload) {
      state.searchCriteria = payload
    },
    SET_DROP_DOWN_ITEMS (state, payload) {
      state.dropDownItems = payload
    },
    SET_SEARCH_PATIENTS (state, payload) {
      state.searchPatientsList = payload
    },
    SET_HEADER_USERNAME (state, payload) {
      state.headerUserName = payload
    }
  },
  actions: {
    async getHeaderDetails ({ dispatch, commit }) {
      const url = '/v1/user/header'
      try {
        const response = await RegistryServerClient.get(url)
        // eslint-disable-next-line eqeqeq
        if (response.success == true) {
          const linksObjectUnorderd = response.data.HeaderLinks
          const linksObjectOrdered = Object.keys(linksObjectUnorderd).sort()
            .reduce((obj, key) => {
              obj[linksObjectUnorderd[key].Key] = linksObjectUnorderd[key].Value
              return obj
            }, {})
          commit('SET_HEADER_LINKS', linksObjectOrdered)
          if (response.data.userLoggedIn) {
            commit('UPDATE_HEADER_TYPE', HeaderVariants.LoggedIn)
            const unorderedSearchCriteria = response.data.SearchCriteria
            const orderedSearchCriteria = Object.keys(unorderedSearchCriteria).sort()
              .reduce((obj, key) => {
                obj[unorderedSearchCriteria[key].Key] = unorderedSearchCriteria[key].Value
                return obj
              }, {})
            commit('SET_SEARCH_CRITERIA', orderedSearchCriteria)

            const unorderedDropDownItems = response.data.DropDownItems
            const orderedDropDownItems = Object.keys(unorderedDropDownItems).sort()
              .reduce((obj, key) => {
                obj[unorderedDropDownItems[key].Key] = unorderedDropDownItems[key].Value
                return obj
              }, {})
            commit('SET_DROP_DOWN_ITEMS', orderedDropDownItems)
          } else {
            commit('UPDATE_HEADER_TYPE', HeaderVariants.LoggedOut)
          }
        }
      } catch (ex) {
        // add toast here when ready
        if (ex.code === 429) {
          await dispatch('getHeaderDetails')
        }
      }
    },
    async searchPatients ({ dispatch, commit }, data) {
      const url = '/v1/user/episodes'
      try {
        const response = await RegistryServerClient.post(url, data)
        // eslint-disable-next-line eqeqeq
        if (response.success) {
          const patientsList = response.data.episodeIndexList
          commit('SET_SEARCH_PATIENTS', patientsList)
          return true
        } else {
          return false
        }
      } catch (ex) {
        if (ex.code === 429) {
          await dispatch('getSearchPatients')
        }
      }
    }
  }
}
