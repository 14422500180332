<template>
  <button
    :class="['c-button', 'text-ibm-plex-sans', color, type]"
    :disabled="disabled"
    @click="onClick">
    {{ $t(label) }}
  </button>
</template>

// available type = solid/outlined/link/text

<script>
export default {
  name: 'Button',
  props: {
    label: {
      type: String,
      default: 'Button'
    },
    type: {
      type: String,
      default: 'solid'
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>

// common style

.c-button {
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
}

// type specific styles

.solid {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.solid, .outlined {
    padding: 15px 20px 15px 20px;
    border: none;
    border-radius: 6px;
}

.outlined {
    border: 1px solid;
    box-sizing: border-box;
    background: transparent;
}

.link, .text {
    border: none;
    background: none;
}

.link {
    text-decoration: underline;
}

// hover

.solid:hover {
    box-shadow: none;
}

// active

.solid:active {
    box-shadow: inset 0px 1px 1px;
}

// background

.primary.solid {
  background-color: var(--primary-theme);
}
.secondary.solid {
  background-color: $secondary;
}
.white.solid {
  background-color: $white;
}
.error.solid {
  background-color: $error;
}

// text

.primary.solid {
  color: $white;
}
.primary.outlined, .primary.link, .primary.text {
  color: var(--primary-theme);
}
.secondary.solid {
  color: $white;
}
.secondary.outlined, .secondary.link, .secondary.text  {
  color: $secondary;
}
.white.solid {
  color: $dark
}
.white.outlined, .white.link, .white.text {
  color: $white;
}
.dark.text {
  color: $dark
}

// hover

.secondary.solid:hover {
    background-color: $secondaryDark;
}
.primary.solid:hover {
    background-color: var(--primary-theme);
}
.primary.outlined:hover {
    background-color: var(--primary-light);
}
.secondary.outlined:hover {
    background-color: $secondaryLight;
}

// active

.secondary.solid:active {
    background-color: $secondaryDark;
}
.primary.solid:active {
    background-color: var(--primary-theme);
}
.primary.outlined:active {
    background-color: $primaryLight;
}
.secondary.outlined:active {
    background-color: $secondaryLight;
}

// disabled

.solid:disabled, .outlined:disabled,
.solid:disabled:active, .outlined:disabled:active,
.solid:disabled:hover, .outlined:disabled:hover {
    background: var(--btn-disabled-bg);
}

button:disabled {
    box-shadow: none;
    color: $buttonDisabledColor !important;
}

button:disabled:hover {
    box-shadow: none;
}

button:disabled:active {
    box-shadow: none;
}

</style>
