import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'

export default {
  namespaced: true,
  actions: {
    async getPatientNotes ({ commit, state }, patientId) {
      const url = 'v1/tags-and-notes?episodeId=' + patientId
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.success) {
        return response
      } else {
        // toastError('Unable to load  Details')
      }
    },

    async addTagsAndNote ({ commit, state }, data) {
      const url = 'v1/tags-and-notes'
      const response = await RegistryServerClient.post(url, data.payload, registryErrorCallback, null)
      if (response.success) {
        return true
      }
    }
  }
}
