<template>
  <div class = "text-container">
    <div class = "text-style">
        <span class="text-styling-one" v-if="isShowLabel()">{{ $t(label) }}</span>
        <span :class=getTextClass()> {{ localValue }} </span>
    </div>
  </div>
</template>
<script>
import { ComponentMixin } from '@/app/shared/mixins/ComponentMixin'

export default {
  mixins: [ComponentMixin],
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      localValue: ''
    }
  },
  mounted () {
    this.localValue = this.value
  },
  methods: {
    getTextClass () {
      return {
        'text-styling-one': true,
        'text-styling-two': this.isShowLabel()
      }
    }
  }
}

</script>
<style lang="scss" scoped>
.text-container {
    text-align: center;
    width: 100%
}
.text-style {
    padding: 10px
}
.text-styling-two {
    font-weight: 600;
}
.text-styling-one {
    padding: 2px;
    margin-right: 10px;
}

</style>
