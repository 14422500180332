import { ApiServerClient, errorCallback } from '../../Api'
import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { upperCamelCaseToLowerCase } from '@/utils/utils'

export default {
  namespaced: true,
  state: {
    taskListsDetails: [],
    allcolumns: [],
    taskListsDetailsLoading: false,
    taskListsDetailsLoaded: false,
    hierarchyId: 0,
    patientsLoading: false,
    patientsData: {},
    pageSize: 20,
    totalPatients: 0,
    taskListIdInputDataMap: {},
    taskListItemMappedByTasklistId: {},
    inputData: {},
    selectedTaskListItem: {},
    // Table specific data
    columnDetailsMappedByValue: {}
  },
  mutations: {
    UPDATE_HIERARCHY_ID (state, data) {
      state.hierarchyId = data
    },

    START_LOADING_TASK_LIST_DETAILS (state) {
      state.taskListsDetails = []
      state.taskListsDetailsLoading = true
      state.taskListsDetailsLoaded = false
    },

    END_LOADING_TASK_LIST_DETAILS (state) {
      state.taskListsDetailsLoading = false
      state.taskListsDetailsLoaded = true
    },

    START_PATIENTS_LOADING (state) {
      state.patientsLoading = true
    },

    END_PATIENTS_LOADING (state) {
      state.patientsLoading = false
    },

    SET_COLUMNS (state, data) {
      state.allcolumns = data.columns
      state.columnDetailsMappedByValue = data.columnDetailsMappedByValue
    },

    UPDATE_PATIENTS_DATA (state, data) {
      state.patientsData = data
    },

    UPDATE_TOTAL_PATIENTS (state, data) {
      state.totalPatients = data
    },

    SET_TASK_LIST_DETAILS (state, data) {
      state.taskListsDetails = data
    },
    SET_INPUT_DATA (state, data) {
      state.inputData = data
    },
    SET_INPUT_MAP (state, data) {
      state.taskListIdInputDataMap = data
    },
    SET_TASKLIST_MAP (state, data) {
      state.taskListItemMappedByTasklistId = data
    },
    SET_SELECTED_TASKLIST_ITEM (state, data) {
      state.selectedTaskListItem = data
    }

  },
  actions: {
    updateHirarchyId ({ commit }, hierarchyId) {
      commit('UPDATE_HIERARCHY_ID', hierarchyId)
    },

    async getLoggedInUserHierarchyId ({ commit }) {
      const url = process.env.VUE_APP_THEME === 'hub' ? '/api/Hierarchy/GetHierarchyChildren' : '/api/Hierarchy/GetUsersHierarchyWithChildren'
      try {
        const response = await ApiServerClient.get(url, null, errorCallback)
        if (response) {
          if (process.env.VUE_APP_THEME === 'hub') { commit('UPDATE_HIERARCHY_ID', response.Data[0].Key) } else {
            commit('UPDATE_HIERARCHY_ID', response.Data.Current.Id)
          }
        }
        // // console.log(response.Data[0].Key)
      } catch (ex) {
        // console.log(ex)
      }
    },

    async setInputData ({ commit }, inputData) {
      // console.log(inputData)
      commit('SET_INPUT_DATA', inputData)
    },

    async setSelectedTasklistItem ({ commit }, item) {
      commit('SET_SELECTED_TASKLIST_ITEM', item)
    },

    setColumnsToDisplay ({ commit }, columnsToDisplay) {
      commit('UPDATE_COLUMNS_TO_DISPLAY', columnsToDisplay)
    },

    async loadTaskListDetails ({ commit, state }, taskListId) {
      if (state.taskListsDetailsLoaded) return

      commit('START_LOADING_TASK_LIST_DETAILS')

      const url = '/v1/user/tasklist/details'
      const taskListsDetailsData = await RegistryServerClient.get(url, null)
      const tasklistDetails = taskListsDetailsData.data.map(item => {
        const newItem = item
        newItem.filters = item.filters || []
        newItem.filters.tId = item.id
        newItem.filters.push({
          filterName: 'tId',
          value: item.id
        })
        return newItem
      })
      commit('SET_TASK_LIST_DETAILS', tasklistDetails)
      // eslint-disable-next-line prefer-const
      let inputMap = tasklistDetails.reduce((acc, item) => {
        acc[item.id] = item.filters.reduce((object, current) => {
          object[current.filterName] = current.value
          return object
        }, {})
        return acc
      }, {})
      // eslint-disable-next-line prefer-const
      let tasklistMap = tasklistDetails.reduce((acc, item) => {
        if (item.extraData) {
          try {
            item.extraData = JSON.parse(item.extraData)
          } catch (error) {
            console.error(
              `Error parsing JSON for item with id ${item.id}:`,
              error
            )
            item.extraData = {}
          }
        }
        acc[item.id] = item
        return acc
      }, {})
      commit('SET_INPUT_MAP', inputMap)
      commit('SET_TASKLIST_MAP', tasklistMap)
      if (taskListId) {
        commit('SET_SELECTED_TASKLIST_ITEM', tasklistMap[taskListId])
        commit('SET_INPUT_DATA', inputMap[taskListId])
      }
      commit('END_LOADING_TASK_LIST_DETAILS')
    },
    async loadTaskListDetailsForced ({ commit, state, dispatch }) {
      commit('START_LOADING_TASK_LIST_DETAILS')
      await dispatch('loadTaskListDetails')
    },
    async loadTaskListFunction ({ commit, state }, inputData) {
      commit('START_PATIENTS_LOADING')
      commit('UPDATE_PATIENTS_DATA', {})
      commit('SET_COLUMNS', { columns: [], columnDetailsMappedByValue: {} })

      const tasklistId = inputData.input.tId
      const queryParams = {
        taskListId: tasklistId,
        page: inputData.page,
        pageSize: state.pageSize,
        sortBy: inputData.sortBy,
        sortDesc: inputData.sortDesc
      }
      const body = {
        ...inputData.uiFilterPayload
      }
      let url = '/v1/user/tasklist/data'

      const extraData = state.taskListItemMappedByTasklistId[tasklistId].extraData

      url = extraData?.tasklistDataUrl ?? url

      const data = {
        body, queryParams
      }

      try {
        let response
        if (extraData.useDynamicFilters) {
          response = await RegistryServerClient.post(url, data, registryErrorCallback)
        } else {
          response = await RegistryServerClient.get(url, queryParams, registryErrorCallback)
        }

        const { columns: columnsObj } = response.data

        const columns = columnsObj.map(c => { return { id: c.name, value: c.label, sortable: c.sortable } })

        const columnDetailsMappedByValue = columns.reduce((acc, item) => {
          acc[item.id] = item
          return acc
        }, {})
        commit('SET_COLUMNS', { columns, columnDetailsMappedByValue })

        var patientsList = []

        response.data.detailedList.forEach(function (patient) {
          var patientObj = {}
          columnsObj.forEach(function (column) {
            const formattedName = upperCamelCaseToLowerCase(column.name)
            patientObj[column.name] = patient[formattedName] ?? patient[column.name]
          })
          patientsList.push(patientObj)
        })

        commit('UPDATE_PATIENTS_DATA', {
          patients: patientsList
        })
        commit('UPDATE_TOTAL_PATIENTS', response.data.totalCount)
      } catch (ex) {
        console.error('Error fetching tasklist data: ', ex)
        commit('UPDATE_TOTAL_PATIENTS', 0)
      }

      commit('END_PATIENTS_LOADING')
    }

  }

}
