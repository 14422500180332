import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { toastError } from '@/utils/utils'
import { List } from 'immutable'

export default {
  namespaced: true,
  state: {
    isReportsLoading: false,
    reportsObject: List(),
    isFormActive: false,
    activeReportTitle: {},
    isReportDownloading: false
  },
  mutations: {
    START_REPORTS_LOADING (state) {
      state.isReportsLoading = true
    },
    END_REPORTS_LOADING (state) {
      state.isReportsLoading = false
    },
    SET_ACTIVE_REPORT (state, data) {
      if (data === null) {
        state.activeReportTitle = null
        state.isFormActive = false
      } else {
        state.activeReportTitle = data
        state.isFormActive = true
      }
    },
    END_REPORT_ACTIVE (state) {
      state.isFormActive = false
    },
    SET_REPORT_TITLES (state, data) {
      state.reportsObject = data
    },
    START_REPORT_DOWNLOAD (state) {
      state.isReportDownloading = true
    },
    END_REPORT_DOWNLOAD (state) {
      state.isReportDownloading = false
    }
  },
  actions: {
    async getReportData ({ commit }) {
      commit('START_REPORTS_LOADING')
      try {
        const url = '/v1/reports/config'
        const formData = await RegistryServerClient.get(url, null, registryErrorCallback)
        if (formData.success) {
          commit('SET_REPORT_TITLES', formData.data.reportOptions)
        } else {
          toastError(formData.Error.Message, { duration: 2000 })
        }
      } catch (ex) {
        registryErrorCallback(ex)
      }
      commit('END_REPORTS_LOADING')
    },
    setActiveReport ({ commit }, data) {
      commit('SET_ACTIVE_REPORT', data)
    },
    endReportActive ({ commit }) {
      commit('END_REPORT_ACTIVE')
    }
  }
}
