export const DatePickerMixin = {
  props: {
    to: {
      type: Date
      // default: moment()
      //   .subtract(180, "days")
      //   .calendar()
    }, // Disable all dates up to specific date
    from: {
      type: Date
      // default: moment()
      //   .add(180, "days")
      //   .calendar()
    }, // Disable all dates after specific date
    days: {
      type: Array,
      default: () => []
    },
    daysOfMonth: {
      type: Array,
      default: () => []
    },
    ranges: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    disabledDates () {
      return {
        to: this.to,
        from: this.from,
        days: this.days,
        daysOfMonth: this.daysOfMonth,
        ranges: this.ranges
      }
    }
  },
  watch: {
    to (newVal) {
      this.disabledDates.to = newVal
    },
    from (newVal) {
      this.disabledDates.from = newVal
    }
  }
}
