export const constants = {
  HIERARCHY_LEVEL: '?level=',
  HIERARCHY_PARENT: '&parentId=',
  HIERARCHY_TYPES: '&types=',
  NAME: 'name'
}

// Date format yyyy-mm-dd
export const formsWithDifferentDateFormats = [
  '_add_patient',
  '_edit_basic_details',
  '_edit_merm',
  '_close_case'
]

export const configNameMapping = new Map([
  ['HasMerm', 'Merm'],
  ['HasVot', 'VOT'],
  ['HasNone', 'None'],
  ['Has99Dots', 'NNDots'],
  ['Has99DotsLite', 'NNDotsLite']
])
