import Vue from 'vue'
import Vuex from 'vuex'

import Form from './shared/store/modules/form'
import Default from '../app/shared/store/index.js'
import Header from '../app/shared/store/modules/Header/index.js'
import HierarchySelectionField from '../app/shared/store/modules/HierarchySelectionField/index'
import Adherence from './Pages/dashboard/patient/Tabs/Adherence/index'
import UnifiedPatient from '../app/shared/store/modules/UnifiedPatient/index'
import Notes from '@/app/shared/store/modules/TagsAndNotes/index.js'
import Sidebar from '@/app/shared/store/modules/Sidebar/index.js'
import SupportActions from '@/app/shared/store/modules/SupportActions/index.js'
import StaffMapping from '@/app/shared/store/modules/StaffMapping/index.js'
import Patient from '../app/shared/store/modules/Patient/index'
import ReviewVot from '../app/shared/store/modules/ReviewVot/index.js'
import CallLogs from '@/app/shared/store/modules/CallLogs/index.js'
import Evrimed from '@/app/shared/store/modules/Evrimed/index.js'
import Overview from '../app/shared/store/modules/Overview/index.js'
import Dispensation from '@/app/shared/store/modules/Dispensation/index.js'
import Tasklist from '@/app/shared/store/modules/tasklist/index.js'
import StaffDetails from '@/app/shared/store/modules/StaffDetails/index.js'
import AddStaff from '@/app/shared/store/modules/AddStaff/index.js'
import HiearachyManagement from '@/app/shared/store/modules/HiearachyManagement/index.js'
import Reports from '@/app/shared/store/modules/Reports/index.js'
import Signup from '@/app/shared/store/modules/Signup/index.js'
import SupersetDashboard from '@/app/shared/store/modules/SupersetDashboard/index.js'
import Invoices from '@/app/shared/store/modules/Invoices/index.js'
import Diagnostics from './shared/store/modules/Diagnostics/index.js'
import ContractManagement from './shared/store/modules/ContractManagement/index.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    Form,
    Header,
    HierarchySelectionField,
    Adherence,
    UnifiedPatient,
    Sidebar,
    Patient,
    ReviewVot,
    Notes,
    SupportActions,
    StaffMapping,
    CallLogs,
    Evrimed,
    Overview,
    Dispensation,
    Tasklist,
    StaffDetails,
    AddStaff,
    HiearachyManagement,
    Reports,
    Signup,
    SupersetDashboard,
    Invoices,
    Diagnostics,
    ContractManagement
  },
  ...Default
})
export default store
