var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['ew-field-group', 'ew-field-group-horizontal', {'ew-field-group-vertical': _vm.orientation === 'v'}]},[(_vm.isShowLabel())?_c('label',{class:[
      'ew-field-label', 'text-align-right', 'flex1', 'ew-label-weight',
      {'text-align-left': _vm.orientation === 'v'},
      {'error': _vm.hasError}]},[_vm._v(" "+_vm._s(_vm.$t(_vm.label))+" ")]):_vm._e(),(_vm.isEditing)?_c('div',{staticClass:"flex1"},[_c('input',{class:_vm.getInputClass(),attrs:{"name":_vm.name,"placeholder":_vm.placeholderText,"label":_vm.label,"disabled":_vm.isDisabled,"readonly":""},domProps:{"value":_vm.$t(_vm.displayValue)},on:{"click":_vm.toggleDropdown}}),_c('div',{staticClass:"dropdown"},[_c('div',{class:_vm.getDropdownClass()},[(_vm.allOptionsOriginal && _vm.allOptionsOriginal.length > 10)?_c('div',[_c('img',{attrs:{"role":"img","src":require("../../../../public/search.svg")}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localSearchValue),expression:"localSearchValue"}],staticClass:"select-search-input",attrs:{"name":_vm.name,"placeholderText":"Search Options","autocomplete":"off"},domProps:{"value":(_vm.localSearchValue)},on:{"input":function($event){if($event.target.composing)return;_vm.localSearchValue=$event.target.value}}})]):_vm._e(),_c('ul',_vm._l((_vm.filteredOptionsToShow),function(option){return _c('li',{key:option.Key,class:['dropdown-item', {'sidebar-select-dropdown-textcolor': _vm.sidebarSelect,
             'single-select-li': !_vm.isMultiSelect
            }],attrs:{"data-key":option.Key,"data-value":option.Value},on:{"click":function($event){return _vm.handleSelect($event, option)}}},[(_vm.isMultiSelect)?_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.isOptionSelected(option.Key)}}):_vm._e(),_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.sidebarSelect? option.Value : _vm.$t(option.Value))+" ")])])}),0),(_vm.isMultiSelect & _vm.filteredOptionsToShow.length > 0)?_c('ul',{staticClass:"dropdown-item"},[_c('hr',{staticClass:"line-break"}),_c('li',{class:['dropdown-item', {'sidebar-select-dropdown-textcolor': _vm.sidebarSelect}, 'select-all-input']},[_c('div',{attrs:{"data-testid":"select-all-input"},on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectAll()}}},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.selectAllOptions}}),_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.$t(`select_all`))+" ")])]),_c('button',{staticClass:"error-message clear-selection-btn",class:{
                'show-clear-selection-btn': this.localValue && this.localValue.length > 0
              },attrs:{"data-testid":"clear-selection"},on:{"click":function($event){$event.stopPropagation();return _vm.handleClearSelection()}}},[_c('u',[_vm._v(" Clear Selection ")])])])]):_vm._e()]),(_vm.displayError)?_c('div',{staticClass:"error-container",attrs:{"id":"selectError"}},[_c('p',{staticClass:"error-message"},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorMessage))+" ")])]):_vm._e(),(_vm.hasInfo && !_vm.hasError)?_c('div',{staticClass:"text-info",attrs:{"id":"selectInfo1"}},[_vm._v(_vm._s(_vm.$t(_vm.infoMessage)))]):_vm._e()])]):_c('div',{staticClass:"flex1"},[_c('label',{class:[ 'ew-field-label', 'text-align-right', 'option-label-weight', 'flex1',{'text-align-left': _vm.orientation === 'v'}]},[_vm._v(_vm._s(_vm.$t(_vm.displayValue)))]),(_vm.hasInfo && !_vm.hasError)?_c('div',{staticClass:"text-info",attrs:{"id":"selectInfo2"}},[_vm._v(_vm._s(_vm.$t(_vm.infoMessage)))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }