const Home = () => import('./Home.vue')
const Login = () => import('./components/Login.vue')
const Signup = () => import('./Signup.vue')

const homeRoutes = [
  {
    path: '/home',
    component: Home,
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login
      },
      {
        path: 'signup',
        name: 'signup',
        component: Signup
      },
      {
        path: '',
        redirect: { name: 'login' }
      }
    ]
  }
]

export default homeRoutes
