import Vue from 'vue'
import Router from 'vue-router'
import { homeRoutes } from './Pages/home'
import { dashboardRoutes } from './Pages/dashboard'
import store from './app-store'
import Overview from './Pages/dashboard/Overview/Overview'
const Input = () => import('./shared/components/Input.vue')

Vue.use(Router)

const appRoutes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/dashboard/overview',
    name: 'Overview',
    component: Overview
  },
  {
    path: '*',
    name: 'page-not-found',
    component: Input
  }
]

const routes = [...homeRoutes, ...dashboardRoutes, ...appRoutes]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('getUserAuthStatus')
  if (to.name !== 'login' && to.name !== 'signup' && !store.state.userAuthenticated) {
    next({ name: 'login' })
  } else if (to.name === 'login' && store.state.userAuthenticated) {
    next({ name: 'Overview' })
  } else if (to.name === 'patient') { // hack to use adherence tab as default for patient page
    next(to.path + '/ADHERENCE')
  } else {
    next()
  }
})

export default router
