import { Map } from 'immutable'

export const IsNullOrUndefinedOrEmptyString = (val) => {
  return !val
}

export const createMapOf = (key, array) => {
  return array.reduce((acc, item) => {
    return acc.set(item[key], { ...item })
  }, new Map())
}

export const isNotNullNorEmpty = object =>
  object !== null &&
  object !== undefined &&
  (
    (Array.isArray(object) && object.length !== 0) ||
    (typeof (object) === 'object' && Object.keys(object).length !== 0) ||
    (typeof (object) === 'string' && object !== '') ||
    (typeof (object) === 'number' && object !== '') ||
    (object instanceof Date) ||
    (typeof (object) === 'boolean')
  )

export const isNullOrUndefined = object =>
  object === null ||
  object === undefined

// export const getCookie = (name) => {
//   const nameEQ = name + '='
//   const ca = document.cookie.split(';')
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i]
//     while (c.charAt(0) === ' ') c = c.substring(1, c.length)
//     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
//   }
//   return null
// }
// not sure of this function..what is toastr
export const toastError = (message) => {
  if (!message) message = 'Something went wrong'
  /* eslint-disable */
  if ('undefined' !== typeof toastr) {
    toastr.error(message, null, {
      'closeButton': true,
      'timeOut': '5000',
      'extendedTimeOut': '5000',
      'positionClass': 'toast-bottom-left'
    })
  }
    /* eslint-enable */
}

export const getFormattedDate = (input) => {
  const date = new Date(input)
  return padZeroes(date.getDate(), 2) + '-' + padZeroes(date.getMonth() + 1, 2) + '-' + date.getFullYear()
}

export const getFormattedDateForRegistry = (input) => {
  const date = new Date(input)
  return date.getFullYear() + '-' + padZeroes(date.getMonth() + 1, 2) + '-' + padZeroes(date.getDate(), 2)
}

export const getFormattedDateForReports = (input) => {
  const date = new Date(input)
  return padZeroes(date.getMonth() + 1, 2) + '-' + padZeroes(date.getDate(), 2) + '-' + date.getFullYear()
}

export const padZeroes = (input, finalLength) => {
  return ('0' + input).slice(-1 * finalLength)
}

export const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key
    return { [newKey]: obj[key] }
  })
  return Object.assign({}, ...keyValues)
}

export const isObjectValueDefined = (object) => {
  if (typeof (object) === 'object' && !isNullOrUndefined(object)) {
    if (Object.keys(object).length > 0) {
      return !Object.values(object).every(x => x === null || x === '' || x === undefined || Number.isNaN(x))
    } else {
      return false
    }
  }
  return false
}

export const upperCamelCaseToLowerCase = value => {
  let formattedValue = value

  if (value) {
    formattedValue = value.charAt(0).toLowerCase() + value.slice(1)
  }

  return formattedValue
}

export const parseConfigs = (configs) => {
  return configs.reduce((currentValues, record) => {
    const value = record.multiMapped ? record.values : record.value
    currentValues[record.configName] = value
    return currentValues
  }, {})
}

export const showHyphenIfNull = (value) => {
  return (value !== null && value !== undefined) ? value : '-'
}

export const openDocInNewTab = (blobUrl) => {
  return blobUrl
}
