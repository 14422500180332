<template>
  <div id="tab_section">
    <h1 class="page-header ufpb-xs ufmb-lg text-ibm-plex-sans dark-text">{{$t('_task_list')}}</h1>
    <v-expand-transition>
      <div v-if="dataPresent">
        <div class="custom-select" ref="select">
          <div class="selected" @click="toggleDropdown">
          <font-awesome-icon v-if="selectedTaskList && selectedTaskList.webIcon" :icon="icons[selectedTaskList.webIcon]" class="fa-lg icon" />
          <span>{{ selectedTaskList ? selectedTaskList.value : 'Select an item' }}</span>
          </div>
          <div v-if="dropdownVisible" class="dropdown">
          <div v-for="(item, index) in tasklistOptions" :key="item.key" class="dropdown-item-wrapper" :class="{ 'last-item': index === tasklistOptions.length - 1 }">
            <div class="dropdown-item" @click="selectItem(item)">
              <font-awesome-icon v-if="item.webIcon !== ''" :icon="icons[item.webIcon]" class="fa-lg icon" />
              <div class="dropdown-text">
                <span class="dropdown-value">{{ item.value }}</span>
                <span class="dropdown-description">{{ item.description }}</span>
              </div>
            </div>
          </div>
          </div>
        </div>
       <router-view/>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EventBus from '@/app/shared/event-bus.js'
import { FontAwsoneV5IconName } from '@/compatibilityHelpers/fontAwsomeCompatibility.js'

export default {
  name: 'TassklistDetails',
  data: function () {
    return {
      tasklistOptions: [],
      dataPresent: false,
      selectedTaskList: null,
      dropdownVisible: false,
      tasklistId: null
    }
  },
  computed: {
    ...mapState('Tasklist', [
      'taskListsDetails', 'taskListIdInputDataMap', 'taskListItemMappedByTasklistId'
    ])
  },
  methods: {
    ...mapActions('Tasklist', ['getLoggedInUserHierarchyId', 'loadTaskListDetails', 'setInputData', 'setSelectedTasklistItem', 'loadTaskListDetailsForced']),
    getTaskListOptions () {
      return this.taskListsDetails.map((val) => {
        return { key: `${val.id}`, value: this.$t(val.name.toLowerCase()), webIcon: val.webIcon, description: this.$t(val.description.toLowerCase()) }
      })
    },
    populateTabs () {
      const taskListItems = this.getTaskListOptions()
      this.dataPresent = true
      this.tasklistOptions = taskListItems
      const tasklistItem = this.taskListItemMappedByTasklistId[this.tasklistId]
      this.selectedTaskList = { key: `${this.tasklistId}`, value: this.$t(tasklistItem.name.toLowerCase()), webIcon: tasklistItem.webIcon, description: this.$t(tasklistItem.description.toLowerCase()) }
    },
    async loadAndPopulateTabs () {
      await this.getLoggedInUserHierarchyId()
      await this.loadTaskListDetails()
      await this.setInputData(this.taskListIdInputDataMap[this.tasklistId])
      const tasklistItem = this.taskListItemMappedByTasklistId[this.tasklistId]
      await this.setSelectedTasklistItem(tasklistItem)
      this.populateTabs()
    },
    async forceReloadTabs () {
      await this.loadTaskListDetailsForced()
      this.populateTabs()
    },
    toggleDropdown () {
      this.dropdownVisible = !this.dropdownVisible
    },
    selectItem (item) {
      this.selectedTaskList = item
      if (this.$route.params.pathMatch !== item.key) {
        this.$router.push(item.key)
      }
      this.dropdownVisible = false
    },
    handleClickOutside (event) {
      const selectElement = this.$refs.select
      if (selectElement && !selectElement.contains(event.target)) {
        this.dropdownVisible = false
      }
    }
  },
  async beforeMount () {
    this.icons = FontAwsoneV5IconName
  },
  async mounted () {
    EventBus.$on('LANGUAGE_CHANGE', this.forceReloadTabs)
    this.tasklistId = this.$route.params.pathMatch
    if (this.taskListsDetails.length !== 0) {
      this.populateTabs()
    } else {
      await this.loadAndPopulateTabs()
    }
    document.addEventListener('click', this.handleClickOutside)
  }
}
</script>

<style scoped>
.left-container {
  display: flex;
  align-items: center;
  width: fit-content;
}

.tab-content > .tab-pane {
  display: none;
}
.show {
  display: block !important;
}
.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-select {
  position: relative;
  max-width: 800px;
  border-radius: 8px;
}
.selected:hover {
  border:1px solid var(--primary-theme);
}

.selected {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid var(--input-border);
  color: var(--text-primary-color);
  padding: 12px;
  font-size: 16px;
  border-radius: 8px;
  background: var(--input-bg) url("../../../../../public/select.svg") no-repeat 95% 1.15rem;

}
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1px;
  color: var(--text-label-color);
}
.dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  line-height: 26px;

}
.dropdown-item:hover {
  background-color: var(--background-bg-color);
}
.icon {
  width: 20px;
  height: 20px;
  margin-right: 18px;
  color: var(--text-label-color);

}
.dropdown-value {
  font-weight: 500;
  color: var(--text-primary-color);
  font-size: 16px;
}

.dropdown-description {
  display: block;
  color: #666;
  font-size: 13px;
}

.custom-select .dropdown-item-wrapper {
  border-bottom: 1px solid #e0e0e0;
  border-bottom-width: 60%;
}

.custom-select .dropdown-item-wrapper.last-item {
  border-bottom: none;
}

.custom-select .dropdown-item {
  display: flex;
  align-items: center;
}

.custom-select .dropdown-text {
  display: flex;
  flex-direction: column;
}

</style>
