import TasklistOverview from '../app/Pages/dashboard/tasklist/TasklistOverview.vue'
import TassklistDetails from '../app/Pages/dashboard/tasklist/TassklistDetails.vue'
import TasklistTabsSection from '../app/Pages/dashboard/tasklist/componenets/TasklistTabsSection.vue'

export const defaultEarliestEnrollment = new Date('2017-05-10')

export const defaultPageSize = 20

export const RouteConstants = {
  TasklistOverview: {
    path: 'tasklists'
  },
  TaskListDetails: {
    path: 'tasklistdetails'
  }
}
export const tasklistChildren = [
  {
    path: '/',
    component: TasklistOverview
  },
  {
    path: RouteConstants.TaskListDetails.path,
    name: RouteConstants.TaskListDetails.path,
    component: TassklistDetails,
    children: [
      {
        path: '*',
        component: TasklistTabsSection
      }
    ]
  }
]

export const adherenceCodeOrder = {
  ENROLLMENT: 1,
  NO_INFO: 2,
  RECEIVED: 3,
  UNVALIDATED: 4,
  TFN_REPEAT_RECEIVED: 5,
  MANUAL: 6,
  PATIENT_MANUAL: 7,
  MISSED: 8,
  PATIENT_MISSED: 9,
  RECEIVED_UNSCHEDULED: 10,
  RECEIVED_UNSURE: 11,
  TFN_REPEAT_RECEIVED_UNSURE: 12
}

export const weeklyDosageEnabledDeployments = [
  'TPTUGA'
]

export const weeklyDosageEnabledAdherenceTechs = [
  '99dots'
]
