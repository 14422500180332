import { RegistryServerClient, registryErrorCallback } from '../../RegistryApi'
import { List, Map } from 'immutable'
import { defaultEarliestEnrollment, defaultPageSize } from '../../../../../constants/index'
import { getCookie } from '@/utils/cookieUtils'

export default {
  namespaced: true,
  state: {
    client: null,
    deploymentCode: 'DEFAULT',
    isOptionsLoading: true,
    isPatientsLoading: false,
    isCalendar: false,
    firstHierarchies: [],
    secondHierarchies: [],
    thirdHierarchies: [],
    fourthHierarchies: [],
    fifthHierarchies: [],
    PHIPatientTypeOptions: List(),
    PHIPatientType: '',
    stageOptions: List(),
    patientTypes: List(),
    caseTypes: List(),
    techOptions: List(),
    dateOptions: List(),
    patients: Map(),
    columns: List(),
    calendarModel: Map(),
    pageSize: defaultPageSize,
    selectedPage: 1,
    stage: '',
    numberPages: 0,
    totalPatients: 0,
    currentMonth: new Date().getMonth(),
    currentYear: new Date().getFullYear(),
    filters: null,
    earliestEnrollment: defaultEarliestEnrollment,
    todayInfo: {}
  },
  mutations: {
    UPDATE_CLIENT (state, data) {
      state.client = data
    },
    UPDATE_DEPLOYMENT_CODE (state, data) {
      state.deploymentCode = data
    },
    SET_IS_CALENDAR_VIEW (state, data) {
      state.isCalendar = data
    },
    START_OPTIONS_LOADING (state) {
      state.isOptionsLoading = true
    },
    END_OPTIONS_LOADING (state) {
      state.isOptionsLoading = false
    },
    START_PATIENTS_LOADING (state) {
      state.isPatientsLoading = true
    },
    END_PATIENTS_LOADING (state) {
      state.isPatientsLoading = false
    },
    SET_FIRST_HIERARCHIES (state, data) {
      state.firstHierarchies = data
    },
    SET_SECOND_HIERARCHIES (state, data) {
      state.secondHierarchies = data
    },
    SET_THIRD_HIERARCHIES (state, data) {
      state.thirdHierarchies = data
    },
    SET_FOURTH_HIERARCHIES (state, data) {
      state.fourthHierarchies = data
    },
    SET_FIFTH_HIERARCHIES (state, data) {
      state.fifthHierarchies = data
    },
    SET_STAGE_OPTIONS (state, data) {
      state.stageOptions = data
    },
    SET_PATIENT_TYPES (state, data) {
      state.patientTypes = data
    },
    SET_CASE_TYPES (state, data) {
      state.caseTypes = data
    },
    SET_TECH_OPTIONS (state, data) {
      state.techOptions = data
    },
    SET_DATE_OPTIONS (state, data) {
      state.dateOptions = data
    },
    SET_PATIENTS (state, data) {
      state.patients = data
    },
    SET_COLUMNS (state, data) {
      state.columns = data
    },
    SET_CAL_MODEL (state, data) {
      state.calendarModel = data
    },
    SET_NUMBER_PAGES (state, data) {
      state.numberPages = data
    },
    SET_SELECTED_STAGE (state, data) {
      state.stage = data
    },
    SET_TOTAL_PATIENTS (state, data) {
      state.totalPatients = data
    },
    SET_CURRENT_MONTH (state, data) {
      state.currentMonth = data
    },
    SET_CURRENT_YEAR (state, data) {
      state.currentYear = data
    },
    SET_CURRENT_PAGE (state, data) {
      state.selectedPage = data
    },
    SET_SELECTED_FILTERS (state, data) {
      state.filters = data
    },
    SET_EARLIEST_ENROLLMENT (state, data) {
      state.earliestEnrollment = data
    },
    SET_HIERARCHY_PATIENT_TYPE_OPTIONS (state, data) {
      state.PHIPatientTypeOptions = data
    },
    SET_HIERARCHY_PATIENT_TYPE (state, data) {
      state.PHIPatientType = data
    },
    SET_TODAY_INFO (state, data) {
      state.todayInfo = data
    }
  },
  actions: {
    updateClientName ({ commit }, clientName) {
      commit('UPDATE_CLIENT', clientName)
    },
    updateDeploymentCode ({ commit }, deploymentCode) {
      commit('UPDATE_DEPLOYMENT_CODE', deploymentCode)
    },
    setCalendarView ({ commit }, isCalendar) {
      commit('SET_IS_CALENDAR_VIEW', isCalendar)
    },
    setCurrentMonth ({ commit }, newMonth) {
      commit('SET_CURRENT_MONTH', newMonth)
    },
    setCurrentYear ({ commit }, newYear) {
      commit('SET_CURRENT_YEAR', newYear)
    },
    setCurrentYearMonth ({ commit }, dateParts) {
      commit('SET_CURRENT_MONTH', dateParts.newMonth)
      commit('SET_CURRENT_YEAR', dateParts.newYear)
    },
    setCurrentPage ({ commit }, newPage) {
      commit('SET_CURRENT_PAGE', newPage)
    },
    setStage ({ commit }, stage) {
      commit('SET_SELECTED_STAGE', stage)
    },
    setSelectedFilters ({ commit }, filters) {
      commit('SET_SELECTED_FILTERS', filters)
    },
    async loadOptions ({ commit }) {
      const url = '/v1/user/upp-filters'
      commit('START_OPTIONS_LOADING')
      try {
        const optionsData = (await RegistryServerClient.get(url, null, registryErrorCallback)).data
        for (var i = 0; i < optionsData.hierarchyOptions.length; i++) {
          switch (i) {
            case 0:
              commit('SET_FIRST_HIERARCHIES', optionsData.hierarchyOptions[0])
              break
            case 1:
              commit('SET_SECOND_HIERARCHIES', optionsData.hierarchyOptions[1])
              break
            case 2:
              commit('SET_THIRD_HIERARCHIES', optionsData.hierarchyOptions[2])
              break
            case 3:
              if (this.client === 'NNDOTS') {
                commit('SET_FOURTH_HIERARCHIES', optionsData.hierarchyOptions[3])
              } else {
                commit('SET_FOURTH_HIERARCHIES', optionsData.hierarchyOptions[3].filter(h => h.type !== 'ART'))
              }
              break
            case 4:
              commit('SET_FIFTH_HIERARCHIES', optionsData.hierarchyOptions[4])
              break
          }
        }
        commit('SET_STAGE_OPTIONS', optionsData.stageOptions)
        commit('SET_PATIENT_TYPES', optionsData.PatientTypeOptions)
        commit('SET_CASE_TYPES', optionsData.CaseOptions)
        commit('SET_TECH_OPTIONS', optionsData.technologyOptions)
        commit('SET_DATE_OPTIONS', optionsData.DateOptions)
        commit('SET_EARLIEST_ENROLLMENT', new Date(optionsData.EarliestEnrollment))
        commit('SET_HIERARCHY_PATIENT_TYPE_OPTIONS', optionsData.PHIPatientTypeOptions)
        commit('SET_HIERARCHY_PATIENT_TYPE', optionsData.PHIPatientType)
      } catch (ex) {
        registryErrorCallback(ex)
      }
      commit('END_OPTIONS_LOADING')
    },
    async getPatients ({ commit, state }) {
      if (state.filters === null) {
        return
      }

      const url = '/v1/upp/patients?page=' + (state.selectedPage - 1) + '&pageSize=' + state.pageSize + '&month=' + (state.currentMonth + 1) + '&year=' + state.currentYear + '&languageCode=' + getCookie('languageCode') || 'en'
      commit('START_PATIENTS_LOADING')
      try {
        const data = (await RegistryServerClient.post(url, state.filters, registryErrorCallback)).data
        var columnList = Object.keys(data.columns).map(function (key) {
          return { Key: key, Value: data.columns[key], Sorting: true }
        })
        commit('SET_PATIENTS', data.patients)
        commit('SET_COLUMNS', columnList)
        commit('SET_NUMBER_PAGES', Math.ceil(data.totalPatients / state.pageSize))
        commit('SET_TOTAL_PATIENTS', data.totalPatients)
        commit('SET_EARLIEST_ENROLLMENT', new Date(data.earliestEnrollment))
        commit('SET_TODAY_INFO', data.todayInfo)
      } catch (ex) {
        registryErrorCallback(ex)
      }
      commit('END_PATIENTS_LOADING')
    }
  },
  getters: {
    uppPatientData (state) {
      return state.patients
    },
    todaysInfo (state) {
      return state.todayInfo
    },
    uppEarliestPatient (state) {
      return state.earliestEnrollment
    }
  }
}
