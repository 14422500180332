<template>
    <div class="stats-card">
        <div class="card-body custom-card-padding">
            <p class="stats-text dark-text">{{ $t(statsText) }}</p>
          <h5 class="stats-value">{{ statsValue==0 ? 0 : $t(statsValue) }} </h5>
        </div>
    </div>
</template>

<script>
export default {
  name: 'StatsCard',
  props: ['statsText', 'statsValue']
}
</script>
<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
    .stats-text {
        font-family: 'IBM Plex Sans', sans-serif !important;
        font-weight: 400 !important;
        font-size: 12px !important;
    }
    .stats-value {
        font-family: 'IBM Plex Sans', sans-serif !important;
        font-weight: 700 !important;
        font-size: 20px !important;
    }
    .custom-card-padding {
        padding: 0 !important;
        padding: 12px !important;
    }
    .stats-card {
        border: 1px solid var(--very-light-input-border) !important;
        border-radius: 4px !important;
    }
    .card-body{
        background: var(--input-bg);
    }
</style>
