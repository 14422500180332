import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './app/App.vue'
import store from './app/app-store'
import router from './app/app-routes'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons/faAngleDoubleLeft'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faCalendarMinus } from '@fortawesome/free-solid-svg-icons/faCalendarMinus'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { faRupeeSign } from '@fortawesome/free-solid-svg-icons/faRupeeSign'
import { faBatteryQuarter } from '@fortawesome/free-solid-svg-icons/faBatteryQuarter'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faBed } from '@fortawesome/free-solid-svg-icons/faBed'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask'
import { faUserSlash } from '@fortawesome/free-solid-svg-icons/faUserSlash'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import { initializeMatomo } from './utils/matomoTracking'
import { BFormSelect } from 'bootstrap-vue/esm/components/form-select'
import { BTable } from 'bootstrap-vue/esm/components/table'
import { BCollapse } from 'bootstrap-vue/esm/components/collapse'
import { VBToggle } from 'bootstrap-vue/esm/directives/toggle'
import VueApexCharts from 'vue-apexcharts'
import i18n from './plugins/i18n'

Vue.config.productionTip = false
library.add(faSpinner)
library.add(faEllipsisH)
library.add(faInfoCircle)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faCaretDown)
library.add(faCaretUp)
library.add(faSearch)
library.add(faAngleDoubleLeft)
library.add(faAngleDoubleRight)
library.add(faAngleLeft)
library.add(faAngleRight)
library.add(faClock)
library.add(faPhoneAlt)
library.add(faExclamationCircle)
library.add(faCalendarMinus)
library.add(faCalendar)
library.add(faRupeeSign)
library.add(faBatteryQuarter)
library.add(faExclamationTriangle)
library.add(faBed)
library.add(faCircle)
library.add(faExclamation)
library.add(faCheck)
library.add(faTimes)
library.add(faTrash)
library.add(faHome)
library.add(faFlask)
library.add(faUserSlash)
Vue.component('b-table', BTable)
Vue.component('b-form-select', BFormSelect)
Vue.component('b-collapse', BCollapse)
Vue.directive('b-toggle', VBToggle)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueMeta)

window.EventBus = new Vue()

initializeMatomo(router)
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
