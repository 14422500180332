import mem from 'mem'
import { RegistryServerClient } from '../store/RegistryApi.js'

const refreshTokenFn = async () => {
  try {
    const response = await RegistryServerClient.get('/v1/client')
    const accessToken = response.data.authToken
    localStorage.setItem('auth-token', accessToken)
    return accessToken
  } catch (error) {
    localStorage.removeItem('auth-token')
  }
}

const maxAge = 10000

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge
})
