import Vue from 'vue'
import VueMatomo from 'vue-matomo'

export const initMatomo = (host, siteId, router) => {
  Vue.use(VueMatomo, {
    host: host,
    siteId: siteId,
    trackerFileName: 'matomo',
    enableLinkTracking: true,
    router: router,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: []
  })
}

export const setUser = (userId) => {
  var _paq = window._paq || []
  _paq.push(['setUserId', userId])
}

export const resetUser = () => {
  var _paq = window._paq || []
  _paq.push(['resetUserId'])
  _paq.push(['appendToTrackingUrl', 'new_visit=1'])
  _paq.push(['trackPageView'])
}

export const trackEvent = (eventCategory, eventAction, eventName) => {
  var _paq = window._paq || []
  _paq.push(['trackEvent', eventCategory, eventAction, eventName])
}

export const initializeMatomo = (router) => {
  initMatomo(process.env.VUE_APP_MATOMO_URL, process.env.VUE_APP_MATOMO_SITE_ID, router)
}

export default {
  name: 'MatomoTracking',
  initializeMatomo,
  trackEvent,
  setUser,
  resetUser
}
