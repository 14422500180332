import { isNotNullNorEmpty } from '@/utils/utils'
import { mapActions } from 'vuex'
import {
  isPhoneNumber,
  isPhoneNumberNotStartingWithZero,
  isPhoneNumberStartingWithZero,
  isPhoneNumberBetweenSevenAndEleven,
  isPhoneNumberLengthEleven,
  isFourAlphaNumericCharacters,
  makeBackendRequest,
  isOnlyAlphabetAndSpaces,
  isEmailId,
  validRequiredInput,
  isValidLength,
  isOnlyNumber,
  isTBNumberFormat,
  isTBNumberFormatForMYA,
  isValueSameAs,
  isThreeDigitNumber,
  isAgeBetween18and99,
  // isPhoneNumberLength9without0or10with0,
  isAlphaNumeric,
  isPhoneNumberMustStartWithZero,
  isPhoneNumberLengthSevenToNine,
  isPhoneNumberLengthNine,
  isPhoneNumberLengthNinePlus,
  isPhoneNumberLengthTen,
  isPhoneNumberMaxLengthEleven,
  isBetweenZeroAndHundred,
  isBetweenTenAndHundred,
  isNumberLengthFive,
  isTBNumberFormatForUKRandUGAVOT,
  isMinLengthGreaterThan2,
  isGreaterThanZero,
  isPhoneNumberLengthNineWithZero,
  isPhoneNumberLengthEightPlusWithZero,
  isPhoneNumberLengthSevenPlusWithoutZero,
  isPhoneNumberLengthNineWithFirstDigitEightOrNine,
  isSevenAlphaNumericCharacters,
  isPhoneNumberLengthTenStartsWithNine,
  isStrongPassword,
  isPhoneNumberLengthEightToTenStartsWithNine
} from '@/utils/FieldValidation'
import { Seq } from 'immutable'
import EventBus from '@/app/shared/event-bus.js'
export const ComponentMixin = {
  props: {
    remoteUpdateConfig: {
      default: null
    },
    hasInfo: {
      type: Boolean,
      default: false
    },
    infoMessage: {
      type: String,
      default: null
    },
    value: {
      default: null
    },
    visible: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    isEditing: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: null
    },
    andValidations: {
      type: Array,
      default: () => []
    },
    orValidations: {
      type: Array,
      default: () => []
    },
    remoteUpdateEventName: {
      type: String,
      default: 'CENTRAL_REMOTE_DATA_UPDATE'
    },
    keyIndex: {
      type: Number,
      defautl: null
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    this.applyValidation()
    EventBus.$on('LANGUAGE_CHANGED', this.applyValidation)
    EventBus.$on(`UPDATE_REMOTE_DATA_${this.name}`, this.onUpdateRemoteData)
    // this.updateLocalValue(this.value)
    // this.localValue = this.value
  },
  computed: {
    disabled () {
      return this.isEditing ? this.isDisabled : true
    }
  },
  data: self => ({
    localAndValidations: self.andValidations,
    localValue: self.value,
    currentError: null,
    formGroupStyleClass: ['form-group'],
    hasRequiredValidation: false,
    validationMapper: {
      Required: validRequiredInput, //
      Email: isEmailId, //
      OnlyAlphabetAndSpaceAllowed: isOnlyAlphabetAndSpaces, // something is wrong with this regex
      PhoneNumberNotStartingWithZero: isPhoneNumberNotStartingWithZero, // something wrong
      PhoneNumberStartingWithZero: isPhoneNumberStartingWithZero, // not working not showing in andValidation list
      PhoneNumberMustStartWithZero: isPhoneNumberMustStartWithZero, //
      PhoneNumber: isPhoneNumber, //
      PhoneNumberBetweenSevenAndEleven: isPhoneNumberBetweenSevenAndEleven, //
      PhoneNumberLengthSevenToNine: isPhoneNumberLengthSevenToNine, //
      PhoneNumberLengthEleven: isPhoneNumberLengthEleven, // something wrong with regex
      // PhoneNumberLength9without0or10with0: isPhoneNumberLength9without0or10with0, // something wrong with regex
      PhoneNumberLengthNine: isPhoneNumberLengthNine, //
      PhoneNumberLengthNinePlus: isPhoneNumberLengthNinePlus, //
      PhoneNumberLengthTen: isPhoneNumberLengthTen, //
      PhoneNumberMaxLengthEleven: isPhoneNumberMaxLengthEleven, //
      FourAlphaNumericCharacters: isFourAlphaNumericCharacters, //
      BackendValidation: makeBackendRequest, // still need to do
      Length: isValidLength, // don't think this will work so not testing
      MatchValue: isValueSameAs, // don't think this will work
      OnlyNumbersAllowed: isOnlyNumber, //
      TBNumberFormat: isTBNumberFormat, // not coming in andValidation list
      TBNumberFormatForMYA: isTBNumberFormatForMYA, //
      Age: isBetweenZeroAndHundred, //
      AlphaNumeric: isAlphaNumeric, //
      AgeAboveTen: isBetweenTenAndHundred, //
      FiveCharacterNumber: isNumberLengthFive, //
      ThreeDigitsAllowed: isThreeDigitNumber, // err message is null
      AgeBetween18and99: isAgeBetween18and99, //
      TBNumberFormatForUKRandUGAVOT: isTBNumberFormatForUKRandUGAVOT, //
      MinLengthGreaterThan2: isMinLengthGreaterThan2,
      GreaterThanZero: isGreaterThanZero,
      PhoneNumberLengthNineWithZero: isPhoneNumberLengthNineWithZero,
      PhoneNumberLengthEightPlusWithZero: isPhoneNumberLengthEightPlusWithZero,
      PhoneNumberLengthSevenPlusWithoutZero: isPhoneNumberLengthSevenPlusWithoutZero,
      PhoneNumberLengthNineWithFirstDigitEightOrNine: isPhoneNumberLengthNineWithFirstDigitEightOrNine,
      SevenAlphaNumericCharacters: isSevenAlphaNumericCharacters,
      PhoneNumberLengthTenStartsWithNine: isPhoneNumberLengthTenStartsWithNine,
      StrongPassword: isStrongPassword,
      PhoneNumberLengthEightToTenStartsWithNine: isPhoneNumberLengthEightToTenStartsWithNine
    }
  }),
  methods: {
    ...mapActions('Form', ['setFieldError', 'unsetFieldError', 'updateField']),
    applyValidation () {
      if (!this.isEditing) {
        return
      }
      if (isNotNullNorEmpty(this.localAndValidations)) {
        this.applyAndValidations(this.localAndValidations)
      }
    },
    async applyAndValidations (andValidations) {
      this.hasRequiredValidation =
        Seq(andValidations)
          .filter(v => v.Type === 'Required')
          .count() > 0

      let validated = true
      for (const v of andValidations) {
        if (
          !this.hasRequiredValidation &&
          (this.localValue === '' || this.localValue === null || !this.localValue)
        ) {
          continue
        }
        if (!v.IsBackendValidation) {
          if (this.validationMapper[v.Type] !== undefined) {
            validated = validated && this.validationMapper[v.Type](this.localValue)
          }
          if (
            validated &&
            v.Type === 'OnlyNumbersAllowed' &&
            v.Max > 0 &&
            v.Min > -1
          ) {
            const numericVal = parseInt(this.localValue)
            validated = validated && (v.Min <= numericVal && v.Max > numericVal)
          }
        } else {
          validated =
            validated &&
            (await this.validationMapper[v.Type](
              v.ValidationUrl,
              this[v.ValidationParams[0]],
              this[v.ValidationParams[1]]
            ))
        }
        if (!validated) {
          this.currentError = v.Type
          this.setFieldError({
            // Update to key
            key: this.$vnode.key,
            data: this.$t(`${v.ErrorMessage}`)
          })
          break
        }
      }

      if (validated) {
        this.unsetFieldError({
          key: this.$vnode.key
        })
      }
    },
    updateLocalValue (val) {
      if (this.$options.name === 'DatePicker') {
        const date = new Date(val)
        this.localValue = {
          date: date.getDate(),
          month: date.getMonth(),
          year: date.getFullYear()
        }
      } else {
        this.localValue = val
      }
    },
    checkAndUpdateRemoteDataForRelatedFields () {
      if (this.remoteUpdateConfig !== null && this.remoteUpdateConfig !== undefined) {
        this.remoteUpdateConfig.forEach(config => {
          EventBus.$emit('CENTRAL_REMOTE_DATA_UPDATE', config, this.keyIndex)
        })
      }
    },
    async onChange () {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
      const self = this
      setTimeout(() => self.checkAndUpdateRemoteDataForRelatedFields(), 300)
    },
    isShowLabel () {
      if (isNotNullNorEmpty(this.label)) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    andValidations (newVal) {
      this.localAndValidations = newVal
      this.applyValidation()
    },
    value (val) {
      this.updateLocalValue(val)
      this.applyValidation()
    }
  }
}
