import { RegistryServerClient, registryErrorCallback } from '../../../../../shared/store/RegistryApi'
import { toastError } from '@/utils/utils'

export default {
  namespaced: true,
  state: {
    adherenceData: {},
    codeConfig: [],
    codeToConfigMap: {},
    tagsConfig: [],
    accessibilityCodeToColorMap: {
      1: '#009E73',
      2: '#D55E00',
      3: '#0072B2',
      4: '#0072B2',
      5: '#F0E442',
      6: '#CC79A7',
      7: 'transparent',
      8: '#009E73',
      9: '#56B4E9',
      A: '#0072B2',
      B: '#F0E442',
      C: '#E69F00',
      D: '#56B4E9',
      E: '#D55E00'
    }
  },
  mutations: {
    updateCodeConfig (state, config) {
      state.codeConfig = config
    },
    updateAdherenceData (state, data) {
      state.adherenceData = data
    },
    updateTagsConfig (state, data) {
      state.tagsConfig = data
    }
  },
  actions: {
    async getTagsConfig ({ state, commit }) {
      const url = 'v1/user/episode/tags-config'
      if (state.tagsConfig.length > 0) return state.tagsConfig
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.success) {
        commit('updateTagsConfig', response.data)
        return response.data
      } else {
        toastError('Unable to fetch Tags Config')
      }
    },
    async getPatientMonthwiseAdherence ({ commit, state, dispatch }, patientId) {
      const url = 'v1/user/episode/adherence/month?episodeId=' + patientId
      if (state.codeConfig.length === 0) {
        const codeConfigResponse = await dispatch('getAdherenceCodeConfig')
        commit('updateCodeConfig', codeConfigResponse)
      }
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.success) {
        commit('updateAdherenceData', response.data)
        return response.data
      } else {
        toastError('Unable to fetch Adherence Data')
      }
    },
    async getAdherenceCodeConfig ({ state, commit }) {
      const url = 'v1/user/episode/adherence-config'
      if (state.codeConfig.length > 0) return state.codeConfig
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.success) {
        commit('updateCodeConfig', response.data)
        return response.data
      } else {
        toastError('Unable to fetch Adherence Config Data')
      }
    },
    async markDoses ({ dispatch }, data) {
      const url = 'v1/user/episode/edit-doses'
      const response = await RegistryServerClient.put(url, data.payload, registryErrorCallback, null)
      if (response.success) {
        await dispatch('getPatientMonthwiseAdherence', data.patientId)
      } else {
        toastError('Unable to edit Missed Doses')
      }
    },
    async addTags ({ dispatch }, data) {
      const url = 'v1/tags-and-notes'
      const response = await RegistryServerClient.post(url, data.payload, registryErrorCallback, null)
      if (response.success) {
        await dispatch('getPatientMonthwiseAdherence', data.payload.episodeId)
      } else {
        toastError('Unable to Add Tags')
      }
    },
    async removeTags ({ dispatch }, data) {
      const url = 'v1/tags'
      const response = await RegistryServerClient.delete(url, data.payload, registryErrorCallback, null)
      if (response.success) {
        await dispatch('getPatientMonthwiseAdherence', data.payload.episodeId)
      } else {
        toastError('Unable to Remove Tags')
      }
    },
    async getEpisodeTags ({ commit }, data) {
      const url = 'v1/user/episode-tags?episodeId=' + data.episodeId + '&dateString=' + data.dateTime
      const response = await RegistryServerClient.get(url, null, registryErrorCallback)
      if (response.success) {
        return response.data
      } else {
        toastError('Unable to load Tags')
      }
    }
  },
  getters: {
    adherenceCodeConfig (state) {
      return state.codeConfig
    },
    adherenceData (state) {
      return state.adherenceData
    },
    getCodeToConfigMap (state) {
      for (let i = 0; i < state.codeConfig.length; i++) {
        state.codeToConfigMap[state.codeConfig[i].code] = state.codeConfig[i]
      }
      return state.codeToConfigMap
    },
    tagsConfig (state) {
      return state.tagsConfig
    },
    accessibilityCodeToColorMap (state) {
      return state.accessibilityCodeToColorMap
    }
  }
}
