import AppInputField from '../components/Input.vue'
import AppRadio from '../components/RadioGroup.vue'
import AppCheckboxGroup from '../components/CheckboxGroup'
import AppSelect from '../components/Select.vue'
import AppTextarea from '../components/TextArea.vue'
import AppDatepicker from '../components/DatePicker.vue'
import AppText from '../components/Text.vue'
import AppHierarchySelectionField from '../components/HierarchySelectionField.vue'
import { mapState, mapActions } from 'vuex'
import { isNullOrUndefined, isNotNullNorEmpty } from '../utils/Objects'
import { RegistryServerClient } from '../store/RegistryApi'

export const FormPartMixin = {
  components: {
    AppInputField,
    AppSelect,
    AppDatepicker,
    AppRadio,
    AppCheckboxGroup,
    AppTextarea,
    AppText,
    AppHierarchySelectionField
  },
  props: {
    isEditing: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: null
    },
    rowDataName: {
      type: String,
      default: 'rows'
    },
    allowRowOpen: {
      type: Boolean,
      default: false
    },
    allowRowDelete: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  mounted () {
    this.onRemoteLoad()
  },
  computed: {
    ...mapState('Form', [
      'fieldsMappedByFormPartName',
      'fieldsMappedByName',
      'existingVModel',
      'hierarchyConfigs',
      'allFields',
      'remoteUpdateConfigsMappedByField'
    ])
  },
  data: self => ({
    localValue: { ...self.value }
  }),
  watch: {
    fieldsMappedByName () {
      this.localValue = this.existingVModel[this.name]
      this.checkValidInput()
    }
  },
  methods: {
    getRemoteUpdateConfig (name) {
      const config = this.remoteUpdateConfigsMappedByField.get(name)
      if (config) return config.toArray()
      return null
    },
    getValidations (name, type) {
      const field = this.fieldsMappedByName.get(name)
      return field.Validations[type]
    },
    getDateConfigKey (field, key) {
      const dateKeys = ['To', 'From']
      if (!isNullOrUndefined(field.DisabledDateConfig)) {
        if (!isNullOrUndefined(field.DisabledDateConfig[key])) {
          if (dateKeys.includes(key) && field.DisabledDateConfig[key]) {
            return new Date(field.DisabledDateConfig[key])
          }
        }
      }
    },
    async onRemoteLoad () {
      const fieldsData = this.fieldsMappedByFormPartName.get(this.name)
      if (!isNullOrUndefined(fieldsData)) {
        fieldsData.forEach(async field => {
          const url = field.RemoteUrl
          if (!isNullOrUndefined(url) && isNotNullNorEmpty(url)) {
            const resp = await RegistryServerClient.get(url)
            const data = resp.Data ? resp.Data : resp.data

            this.updateField({ key: field.Key, attribute: 'OptionsWithKeyValue', data: data })
            this.updateField({ key: field.Key, attribute: 'AllOptionsWithLabel', data: data })
          }
        })
      }
    },
    onDataChange () {
      this.$emit('input', this.localValue)
      this.$emit('change', this.localValue)
    },
    formattedWidth (width) {
      let formattedWidth = 1
      if (width) {
        formattedWidth = width
      }
      return formattedWidth
    },
    changeLocal (field, value) {
      this.localValue[field] = value
    },
    ...mapActions('Form', ['updateField', 'checkValidInput', 'checkValidInput'])
  }
}

export const FieldComponentMap = {
  components: {
    AppInputFieldGroup: AppInputField,
    AppMultiselectSelect: AppSelect,
    AppSearchSelect: AppSelect,
    AppCheckbox: AppCheckboxGroup,
    AppMultiselect: AppSelect
  }
}
